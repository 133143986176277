import { authHeader } from "../helpers/auth";
import axios from "axios";
import history from "../routers/history";
import env from "react-dotenv";

const url = env ? env.REACT_APP_API_URL : "https://hidra-api.iratu.net/api";

console.log("apiurl", url);
//realiza un pedido al back
export const makeRequest = (path, options = {}) => {
  return axios({
    url: url + path,
    ...options,
  });
};
//realiza la solicitud en el back
export const makeAuthorizedRequest = (path, options = {}) => {
  let authHeaders = authHeader();
  axios.interceptors.response.use(handleSuccess, handleError);
  
  return axios({
    url: url + path,
    ...options,
    ...authHeaders,
  });
};

const handleSuccess = (response) => {
  return { data: response.data };
};

const handleError = (error) => {
  if (error.message === "Network Error") {
    return Promise.reject(error);
  }

  if (error.response) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("user");
        history.push("/login");
        break;
      default:
        break;
    }
  }

  return Promise.reject(error);
};
