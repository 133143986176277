export function authHeader() {
    // return authorization header with jwt token
    let user = getUser();
    if (user && user.token) {
        return { 
            headers: {
                'Authorization': user.token,
                'Accept': 'application/json'
            }
        };
    } else {
        return { 
            headers: {'Accept': 'application/json'}
        };
    }
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const setUser = (auth) => {
    localStorage.setItem('user', JSON.stringify({
        ...auth.user, 
        token: auth.token
    }));
};

export const removeUser = () => localStorage.removeItem('user');

export const isAuthenticated = () => !!localStorage.getItem('user');

export const hasRoles = (roles) => {
    const user = getUser();
    if(!user) return false;
    const filtered = roles.filter(role => role === user.role.name);
    return filtered.length > 0;
};
