import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import PanelViewerConsole from "./PanelViewerConsole";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { hasRoles } from "../../helpers/auth";
import DialogComponent from "../dialog/Dialog";


const PanelViewer = (props) => {
  const { history, panel } = props;

  const onBackClicked = () => {
    history.push("/panels");
  };
  const onEditClicked = () => {
    history.push(`/panels/build/${panel.id}`);
  };

  if (!panel) {
    props.history.push("/panels");
    return <div></div>;
  }

  const [dialogOpen, setDialogOpen] = useState(false);
    
  const handleOpenDialog = () => {
      
      setDialogOpen(true);
  };

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };
  return (
    <div style={{ height: "100%" }}>
      <div className="page-header">
        <Typography variant="h6" noWrap className="pull-left">
          {panel.name}
        </Typography>
       

        
       
        <div className="pull-right">
          {hasRoles(["admin", "owner", "editor"]) ? (
            <Button
              id="back"
              onClick={onEditClicked}
              color="primary"
              variant="contained"
              disabled={!hasRoles([ "owner", "editor"])}
            >
              Editar
            </Button>
          ) : (
            ""
          )}
          
          <Button
            id="back"
            onClick={onBackClicked}
            color="default"
            variant="contained"
          >
            Cerrar
          </Button>
          
        </div>
        <div className="clearfix"></div>
        {panel.boards.length > 0 &&             
          <Button color="primary" variant="contained" onClick={handleOpenDialog}>
            Última conexión
          </Button>
        }
      </div>

      <DialogComponent
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        title="Última conexión"                
        panel={panel}
      />
      
      <PanelViewerConsole panel={props.panel} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const panel = state.panels.data.find(
    (panel) => panel.id === parseInt(props.match.params.id)
  );
  return {
    panel,
  };
};

export default connect(mapStateToProps, null)(PanelViewer);
