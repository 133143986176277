const text =
  "fillColor=none;strokeColor=none;resizable=0;selectable=0;connectable=0;editable=0;movable=0;align=center";
const image =
  "foldable=0;resizable=0;shape=image;align=center;verticalAlign=top;clickable=0;strokeWidth=0";
const textLabel ="foldable=0;resizable=0;shape=image;align=center;verticalAlign=top;clickable=0;strokeWidth=0;fontSize=17;align=left;fontColor=#000;";  

export default {
  tank: {
    styles: `${image};image=/images/tank-default.png;`,
    width: 100,
    height: 150,
  },
  chronometer: {
    styles: `${image};image=/images/a-tachometer-default.png;`,
    width: 140,
    height: 140,
  },
  dchronometer: {
    styles: `${image};image=/images/d-tachometer-default.png;`, 
    width: 140,
    height: 140,
  },
  pump: {
    styles: `${image};image=/images/pump-default.png;`,
    width: 140,
    height: 140,
  },
  selectortowposition: {
    styles: `${image};image=/images/selectorTow-default.png;`,
    width: 140,
    height: 140,
  },
  selectorthreeposition: {
    styles: `${image};image=/images/selectorThree-default.png;`,
    width: 140,
    height: 140,
  },
  generic: {
    styles: `${image};image=/images/generic-default.png;`,
    width: 140,
    height: 140,
  },

  chronometerValue: {
    styles: `${text};fontFamily=Orbitron;fontSize=10;fontColor=#FFFFFF;`,
    width: 140,
    height: 20,
  },
  dchronometerValue: {
    styles: `${text};fontFamily=Orbitron;spacingLeft=-3;fontSize=19;fontColor=#54ABD7;`, //"fillColor=none;strokeColor=none;resizable=0;selectable=0;connectable=0;editable=0;movable=0;fontFamily=Orbitron;spacingLeft=-3;fontSize=14;fontColor=#54ABD7;",
    width: 100,
    height: 20,
  },
  dchronometerUnit: {
    styles:
      "fillColor=none;strokeColor=none;resizable=0;selectable=0;connectable=0;editable=0;movable=0;fontFamily=Orbitron;spacingLeft=-3;fontSize=14;fontColor=#54ABD7;",
    width: 100,
    height: 20,
  },
  value: {
    styles: `${text};fontSize=12`,
    width: 100,
    height: 20,
  },
  label: {
    styles: `${text};fontSize=12`,
    width: 100,
    height: 20,
  },
  alertIcon: {
    styles: `${image};strokeColor=none;resizable=0;selectable=0;connectable=0;editable=0;movable=0;image=/images/alert-default.png;`,
    width: 20,
    height: 20,
  },
  textLabel:{
    styles: `${textLabel}`,
    width:100,      
    height:20
  },
  switch: {
    styles: `${image};image=/images/switch-default.png;`,
    width: 140,
    height: 140,    
  },
  loader:{
    styles: `"foldable=0;resizable=0;shape=image;align=center;verticalAlign=top;clickable=0;strokeWidth=0";strokeColor=none;resizable=0;selectable=0;connectable=0;editable=0;movable=0;image=/images/loading.gif;`
  },
  switchKey:{
    styles:`${image};image=/images/switchKey-default.png`,
    width:120,
    height:180
  }
};
