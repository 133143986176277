import { getFormData } from '../../helpers/utils';
import { makeAuthorizedRequest } from '../../services/apiConnector';

export const ADD_COMPANY_PENDING = 'ADD_COMPANY_PENDING';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_ERROR = 'ADD_COMPANY_ERROR';

export const addCompany = (company) => (dispatch) => {
    dispatch(addCompanyPending());
    
    const data = getFormData(company);

    return makeAuthorizedRequest(`/companies`,{method: 'post', data})
    .then(response => {
        dispatch(addCompanySuccess(response.data.result));                    
        return response.data.result;
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido'
        dispatch(addCompanyError(message));
    });
};

const addCompanyPending = () => ({
    type: ADD_COMPANY_PENDING
});

const addCompanySuccess = (company) => ({
    type: ADD_COMPANY_SUCCESS,
    company
});

const addCompanyError = (error) => ({
    type: ADD_COMPANY_ERROR,
    error
});