import { getFormData } from "../../helpers/utils";
import { makeAuthorizedRequest } from "../../services/apiConnector";

export const ADD_BOARD_PENDING = "ADD_BOARD_PENDING";
export const ADD_BOARD_SUCCESS = "ADD_BOARD_SUCCESS";
export const ADD_BOARD_ERROR = "ADD_BOARD_ERROR";

export const addBoardModel = (boardModel) => (dispatch) => {
  dispatch(addBoardPending());
  const data = boardModel;
  // console.log(data);

  return makeAuthorizedRequest(`/models`, { method: "post", data })
    .then((response) => {
      dispatch(addBoardSuccess(response.data.result));
      return response.data.result.id;
    })
    .catch((error) => {
      const message = error.response? error.response.data.message: "Error desconocido";
      dispatch(addBoardError(message));
    });
};

const addBoardPending = () => ({
  type: ADD_BOARD_PENDING,
});

const addBoardSuccess = (board) => ({
  type: ADD_BOARD_SUCCESS,
  board,
});

const addBoardError = (error) => ({
  type: ADD_BOARD_ERROR,
  error,
});
