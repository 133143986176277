import { makeAuthorizedRequest } from "../../services/apiConnector";

export const EDIT_COMPONENT_PENDING = "EDIT_COMPONENT_PENDING";
export const EDIT_COMPONENT_SUCCESS = "EDIT_COMPONENT_SUCCESS";
export const EDIT_COMPONENT_ERROR = "EDIT_COMPONENT_ERROR";

export const editComponent = (id, updates) => (dispatch) => {
    dispatch(editComponentPending());

    const data = updates;
    delete data["alerts"];

    return makeAuthorizedRequest(`/components/${id}`, { method: "PATCH", data })
        .then((response) => {
            dispatch(editComponentSuccess(id, updates));
            return response.data.result.id;
        })
        .catch((error) => {
            const message = error.response
                ? error.response.data.message
                : "Error desconocido";
            dispatch(editComponentError(message));
        });
};

const editComponentPending = () => ({
    type: EDIT_COMPONENT_PENDING,
});

const editComponentSuccess = (id, updates) => ({
    type: EDIT_COMPONENT_SUCCESS,
    id,
    updates,
});

const editComponentError = (error) => ({
    type: EDIT_COMPONENT_ERROR,
    error,
});
