import * as React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import TablePagination from '@material-ui/core/TablePagination';
import { fetchModels } from '../../actions/boardModels/fetchAction';
import { fetchModelBoards } from '../../actions/boardModels/fetchAction';
 
import LoadingSpinner from '../LoadingSpinner';

import FlashMessage from '../FlashMessage';
import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import { ACTION_FETCH } from '../../actions/types';
import { hasRoles } from '../../helpers/auth';
import ModelListTable from './ModelListTable';

export class ModelList extends React.Component {
    
    onCreateClicked = () => this.props.history.push('/model/create');

    onLinkClicked = () => this.props.history.push('/model/link');

    onEditClicked = (id) => this.props.history.push(`/model/edit/${id}`);

    componentDidMount = () => {
        const {perPage, current} = this.props.pagination;
        this.props.fetchModels({currentPage:current, perPage})
        this.props.fetchModelBoards({currentPage: (current), perPage});
    }

    handleChangePage = (event, currentPage) => {
        const { perPage } = this.props.pagination;
        this.props.fetchModelBoards({currentPage: (currentPage), perPage});
    }
    
    handleChangeRowsPerPage = (event) => {
        let { fetchModelBoards } = this.props;
        const perPage = event.target.value;
        fetchModelBoards({currentPage: 0, perPage});
    };
    
    render = () => {
        const { status, models, message, action } = this.props;
        const { currentPage, perPage, total } = this.props.pagination;
        console.log("board models", currentPage, perPage, total);

        return (
            <div>
                <div className="page-header">
                    <Typography variant="h6" noWrap className="pull-left">
                        Modelo de placas
                    </Typography>
                    {hasRoles(['admin']) && (
                        <div className="pull-right">
                            <Button 
                            id="create-board" 
                            onClick={this.onCreateClicked} 
                            color="primary" 
                            variant="contained"
                            endIcon={<SendIcon />}
                            >
                            Nuevo modelo
                            </Button>
                        </div>                        
                    )}
                    {hasRoles(['owner']) && (
                        <div className="pull-right">
                            <Button 
                            id="link-board" 
                            onClick={this.onLinkClicked} 
                            color="primary" 
                            variant="contained"
                            endIcon={<SendIcon />}
                            >
                            Agregar nuevo modelo
                            </Button>
                        </div>                        
                    )}
                    <div className="clearfix"></div>
                </div>
                
                <div>
                {status === PENDING_STATUS && <LoadingSpinner/>}

                {action === ACTION_FETCH && status === ERROR_STATUS && (
                    <FlashMessage type={status} message={message}/>
                )}

                {(status === SUCCESS_STATUS || status === ERROR_STATUS) && models.length === 0 && (
                    <p>No hay registros para mostrar.</p>
                )}

                { models.length > 0 && (
                   
                    <div>
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <ModelListTable 
                            onRemoveClicked={this.onRemoveClicked} 
                            onEditClicked={this.onEditClicked} 
                            models={models}
                            viewCreditDialog={this.viewCreditDialog}
                        />
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                   
                ) }
                        
                </div>
    
                
            </div>
        );
    }
};



const mapStateToProps = (state) => ({
    models: state.models.data,
    action: state.models.action,
    status: state.models.status,
    pagination: state.models.pagination
});

const fetchModelsAction = (params) => fetchModels(params);
const fetchModelBoardsAction = (params) => fetchModelBoards(params);

const mapDispatchToProps = {
    fetchModels: fetchModelsAction,
    fetchModelBoards: fetchModelBoardsAction
};


export default connect(mapStateToProps, mapDispatchToProps)(ModelList);