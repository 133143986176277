import { makeAuthorizedRequest } from "../../services/apiConnector";

export const REMOVE_USER_PENDING = 'REMOVE_USER_PENDING';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';

export const removeUser = (id) => (dispatch) => {
    dispatch(removeUserPending(id));
    return makeAuthorizedRequest(`/users/${id}`,{method: 'DELETE'})
    .then(response => {
        dispatch(removeUserSuccess(parseInt(response.data.result.id)));            
        return id;
    
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido'
        dispatch(removeUserError(message));
    });
};

const removeUserPending = (id) => ({
    type: REMOVE_USER_PENDING,
    id
});

const removeUserSuccess = (id) => ({
    type: REMOVE_USER_SUCCESS,
    id
});

const removeUserError = (error) => ({
    type: REMOVE_USER_ERROR,
    error
});