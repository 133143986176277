import {
  FETCH_ALERTS_ERROR,
  FETCH_ALERTS_PENDING,
  FETCH_ALERTS_SUCCESS,
} from "../actions/alerts/fetchActions";
import { ACTION_FETCH } from "../actions/types";

import {
  PENDING_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../actions/status";

const defaultState = {
  status: null,
  message: null,
  action: null,
  list: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALERTS_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_FETCH,
      };
    case FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        list: action.data,
      };
    case FETCH_ALERTS_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };
    default:
      return state;
  }
};
