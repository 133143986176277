import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_BOARDS_PENDING = 'FETCH_BOARDS_PENDING';
export const FETCH_BOARDS_SUCCESS = 'FETCH_BOARDS_SUCCESS';
export const FETCH_BOARDS_ERROR = 'FETCH_BOARDS_ERROR';

export const LIST_BOARDS_PENDING = 'LIST_BOARDS_PENDING';
export const LIST_BOARDS_SUCCESS = 'LIST_BOARDS_SUCCESS';
export const LIST_BOARDS_ERROR = 'LIST_BOARDS_ERROR';

export const fetchBoard = (boardid) => {
    return (dispatch) => {
        let url = `/boards?&id=${boardid}&populate=pins`;
        return makeAuthorizedRequest(url)
        .then(response => {
            // console.log(response.data.result[0]);
            return response.data.result[0];
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        });
    }
}

export const fetchPines = (boardid, type) => {
    return (dispatch) => {
        let url = `/pins?&board=${boardid}&type=${type}`;
        // console.log(boardid, type)
        // return makeAuthorizedRequest(url)
        // .then(response => {
        //     return response.data.result[0];
        // })
        // .catch(error => {
        //     const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        // });
    }
}




export const fetchBoards = ({currentPage, perPage, component = null}) => {
    return (dispatch) => {
        dispatch(fetchBoardsPending({currentPage, perPage}));
        const skip = parseInt(currentPage) * parseInt(perPage);
        return makeAuthorizedRequest(`/boards?skip=${skip}&limit=${perPage}`)
        .then(response => {
            dispatch(fetchBoardsSuccess(response.data, currentPage, perPage));
            // console.log(response.data.result);
            return response.data.result;
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
            dispatch(fetchBoardsError(message));
        });
    }
}

export const listBoards = () => (dispatch) => {
    dispatch(listBoardsPending());
    return makeAuthorizedRequest('/boards?select=id,imei,description&populate=pins')
    .then(response => {
        dispatch(listBoardsSuccess(response.data));
        // console.log(response.data.result);
        return response.data.result;
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(listBoardsError(message));
    });    
};

const fetchBoardsPending = ({currentPage, perPage}) => ({
    type: FETCH_BOARDS_PENDING,
    currentPage,
    perPage
});

const fetchBoardsSuccess = (data, currentPage, perPage) => ({
    type: FETCH_BOARDS_SUCCESS,
    data: data.result,
    currentPage: currentPage,
    perPage: perPage,
    total: data.total
});

const fetchBoardsError = (error) => ({
    type: FETCH_BOARDS_ERROR,
    error
});

const listBoardsPending = () => ({
    type: LIST_BOARDS_PENDING
});

const listBoardsSuccess = (data) => ({
    type: LIST_BOARDS_SUCCESS,
    data: data.result
});

const listBoardsError = (error) => ({
    type: LIST_BOARDS_ERROR,
    error
});
