import {
  FETCH_MODELS_ERROR,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_PENDING,
  LIST_MODELS_ERROR,
  LIST_MODELS_PENDING,
  LIST_MODELS_SUCCESS,
} from "../actions/boardModels/fetchAction";

import {
  ACTION_ADD,
  ACTION_EDIT,
  ACTION_FETCH,
  ACTION_REMOVE,
} from "../actions/types";
import {
  PENDING_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../actions/status";
const successMessage = "Los datos se guardaron con éxito.";

const defaultState = {
  status: null,
  message: null,
  action: null,
  data: [],
  list: [],
  pagination: {
    current: 0,
    perPage: 10,
    total: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MODELS_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_FETCH,
        pagination: {
          ...state.pagination,
          perPage: action.perPage,
          currentPage: action.currentPage,
        },
      };
    case FETCH_MODELS_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        data: action.data,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
          perPage: action.perPage,
          total: action.total,
        },
      };
    case FETCH_MODELS_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };

    case LIST_MODELS_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_FETCH,
      };
    case LIST_MODELS_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        list: action.data,
      };
    case LIST_MODELS_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };    
    default:
      return state;
  }
};
