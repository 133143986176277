import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const fetchUsers = ({currentPage = 0, perPage}) => {
    return (dispatch) => {
        dispatch(fetchUsersPending({currentPage, perPage}));
        const skip = parseInt(currentPage) * parseInt(perPage);
        makeAuthorizedRequest(`/users?skip=${skip}&limit=${perPage}&populate=role,company`)
        .then(response => {
            dispatch(fetchUsersSuccess(response.data, currentPage, perPage));
            return response.data.result;
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido'
            dispatch(fetchUsersError(message));
        })
    }
}

const fetchUsersPending = ({currentPage, perPage}) => ({
    type: FETCH_USERS_PENDING,
    currentPage,
    perPage
});

const fetchUsersSuccess = (data, currentPage, perPage) => ({
    type: FETCH_USERS_SUCCESS,
    data: data.result,
    currentPage: currentPage,
    perPage: perPage,
    total: data.total
});

const fetchUsersError = (error) => ({
    type: FETCH_USERS_ERROR,
    error
});