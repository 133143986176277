import shapes from "../helpers/shapes";
import AnalogComponentModel from "./AnalogComponentModel";

class AlertIconModel extends AnalogComponentModel {
  constructor(props) {
    super(props);
    this.type = "alertIcon";
    this.label = "";
    this.selectable = false;
  }

  getChildConfig = (parent) => {
    const shape = shapes[this.type];
    const width = shape.width;
    return {
      parent,
      object: this,
      shape: { ...shape, width },
      children: [],
    };
  };

  getConfig = (parent = null) => {
    const shape = { ...shapes[this.type] };
    shape.styles = shape.styles.replace("default", this.value.severity);
    return {
      parent,
      object: this,
      shape: shape,
      children: [],
    };
  };

  toString = () => {
    return "";
  };
}

export const getAlertIcon = (alert, xPosition, yPosition) => {
  return new AlertIconModel({
    value: alert,
    xPosition,
    yPosition,
  });
};

export default AlertIconModel;
