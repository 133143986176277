import React from 'react';
import Typography from '@material-ui/core/Typography';
// import BoardForm from './BoardForm';
import { connect } from 'react-redux';
import { addBoardModel } from '../../actions/boardModels/addActions';
import { hasRoles } from '../../helpers/auth';
import ModelAdminForm from './ModelAdminForm';

const ModelCreator = (props) => {

    const { history, addBoardModel } = props;

    const onSubmit = (board) => {
        console.log(board);
        addBoardModel(board).then(newBoardModel => {
            
            if (newBoardModel) {
                history.push(`/boardModels`);
            }
        });
    };

    const onCancel = () => {
        goBack();
    }

    const goBack = () => {
        history.push('/boardModels');
    };

    return (
        <div style={{ paddingBottom: '20px' }}>
            <div className="page-header">
                <Typography variant="h6" noWrap className="pull-left">
                    Nuevo Modelo
                </Typography>
            </div>
            <div className="clearfix"></div>

            {hasRoles(['admin']) ? (
                <ModelAdminForm onSubmit={onSubmit} onCancel={onCancel} />                
            ) : (
                // <BoardForm onSubmit={onSubmit} onCancel={onCancel} />
                "form"
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addBoardModel: board => dispatch(addBoardModel(board))
});

export default connect(null, mapDispatchToProps)(ModelCreator);