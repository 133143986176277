import React from "react";

import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import Validator from "../../services/validator";

import FlashMessage from "../FlashMessage";

import { ACTION_EDIT, ACTION_REMOVE, ACTION_ADD } from "../../actions/types";
import { PENDING_STATUS, SUCCESS_STATUS } from "../../actions/status";

import { hasErrors, errors, clean } from "../../helpers/utils";

import FormTextField from "../FormTextField";
import FormButtons from "../FormButtons";
import { hasRoles } from "../../helpers/auth";
import { listCompanies } from "../../actions/companies/fetchActions";
import { MenuItem } from "@material-ui/core";

import { fetchCategories } from "../../actions/categories/fetchActions";

class PanelForm extends React.Component {
  panel = null;
  editMode = false;

  mapIsActive = false;
  CoordsIsActive = false;

  constructor(props) {
    super(props);

    this.validator = new Validator({
      name: { required: true },
      latitude: { numeric: true },
      longitude: { numeric: true },
    });

    this.panel = this.props.panel || {
      name: "",
      description: "",
      latitude: "",
      longitude: "",
      company: null,
      panelCategory: "",
      urlGoogleMaps: "",
    };

    if (hasRoles(["admin"])) {
      this.validator.addRule("company", { required: true });
    }

    this.editMode = !!this.panel.id;

    this.state = {
      panel: this.panel,
      errors: this.validator._errors,
    };
  }

  componentDidMount = () => {
    if (hasRoles(["admin"])) {
      this.props.listCompanies();
    }
    const perPage = 0,
      current = 10;

    this.props.fetchCategories({ currentPage: current, perPage });
    
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      company: panelCompany,
      description,
      latitude,
      longitude,
      category,
    } = this.state.panel;

    const company = panelCompany ? panelCompany.id : null;

    let form = { name, description, latitude, longitude, company, category };

    if (!this.validator.validate(form)) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }

    form = clean(form);
    
    this.props.onSubmit(form);
  };

  onTextChange = (e) => {
    e.preventDefault()
    if (e.target.id == "urlGoogleMaps") {
      this.formatUrlGoogleMaps(e.target.value);
    }
    this.panel[e.target.id] = e.target.value;

    this.setState(() => ({ panel: this.panel }));
    // this.controllUrlMaps();
  };

  controllUrlMaps = () => {
    if (this.panel.latitude || this.panel.longitude) {
      this.mapIsActive = true;
    } else {
      this.mapIsActive = false;
    }

    if (this.panel.urlGoogleMaps) {
      this.CoordsIsActive = true;
    } else {
      this.CoordsIsActive = false;
    }
  };

  formatUrlGoogleMaps = (urlGoogleMaps) => {
    var step1 = urlGoogleMaps.split("/@");
    var step2 = step1[1].split("z/");
    var coords = step2[0].split(",");    

    this.panel["latitude"] = coords[0];
    this.panel["longitude"] = coords[1];
    this.setState(() => ({ panel: this.panel }));
  };

  selectPanelCategory = (e) => {
    this.panel["category"] = e.target.value;
    this.setState(() => ({ panel: this.panel }));
  };
  onDelete = (id) => {
    const { status, action, onCancel } = this.props;
    const deleting = status === SUCCESS_STATUS && action === ACTION_REMOVE;
    
    this.props.onDelete(id);
  };

  onCompaniesSelectChange = (e) => {
    const { companies } = this.props;
    const selected = companies.find((company) => company.id === e.target.value);
    this.panel.company = selected;
    this.setState(() => ({ panel: this.panel }));
  };

  render() {
    const { status, action, message, companies , categories} = this.props;
    
    const done =
      (action === ACTION_EDIT || action === ACTION_ADD) &&
      status !== PENDING_STATUS;
    const deleting = action === ACTION_REMOVE && status !== PENDING_STATUS;
    return (
      <div>
        {done && <FlashMessage message={message} type={status} />}
        {deleting && <FlashMessage message={message} type={status} />}
        <form id="panel-form" onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Nombre"
                id="name"
                value={this.panel.name}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "name")}
              />
              {errors(this.state, "name")}
            </Grid>
            {hasRoles(["admin"]) && companies.length > 0 && (
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="Empresa"
                  id="company"
                  select
                  value={this.panel.company ? this.panel.company.id : ""}
                  onChange={this.onCompaniesSelectChange}
                  error={hasErrors(this.state, "company")}
                >
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </FormTextField>
                {errors(this.state, "company")}
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Descripción"
                id="description"
                value={this.panel.description ? this.panel.description : ""}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "description")}
              />
              {errors(this.state, "description")}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                
                label="Latitud"
                id="latitude"
                value={this.panel.latitude ? this.panel.latitude : ""}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "latitude")}
              />
              {errors(this.state, "latitude")}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                
                label="Longitud"
                id="longitude"
                value={this.panel.longitude ? this.panel.longitude : ""}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "longitude")}
              />
              {errors(this.state, "longitude")}
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField                
                label="UrlGoogleMaps"
                id="urlGoogleMaps"
                value={this.panel.urlGoogleMaps ? this.panel.urlGoogleMaps : ""}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "urlGoogleMaps")}
              />
              {errors(this.state, "urlGoogleMaps")}
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField
                label="Categoria"
                id="category"
                select
                value={this.panel.category ? this.panel.category : ""}
                onChange={this.selectPanelCategory}
                error={hasErrors(this.state, "category")}
              >
                {categories.map((data) => (
                  <MenuItem key={data.id} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>
          </Grid>
          <FormButtons
            onDelete={this.editMode ? this.onDelete : null}
            object={this.state.panel}
            onCancel={this.props.onCancel}
            status={status}
            action={action}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.panels.status,
  action: state.panels.action,
  message: state.panels.message,
  companies: state.companies.list,
  categories: state.categories.data,
});

const mapDispatchToProps = (dispatch) => ({
  listCompanies: () => dispatch(listCompanies()),
  fetchCategories: (params) => dispatch(fetchCategories(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelForm);
