import { getFormData } from '../../helpers/utils';
import { makeRequest } from '../../services/apiConnector';
import { setUser } from '../../helpers/auth';

export const SIGNUP_PENDING = 'SIGNUP_PENDING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const signup = (params) => (dispatch) => {
    dispatch(signupPending());
    
    const data = getFormData(params);

    return makeRequest('/auth/signup', { method: 'POST', data }).then(response => {
        const auth = response.data.result;
        setUser(auth);
        dispatch(signupSuccess(auth.user, auth.token));  
        return auth.token;
    })
    .catch(error => {
        const message = error.response.data.message ? error.response.data.message : 'Error desconocido'
        dispatch(signupError(message));
    });
};

const signupPending = () => ({
    type: SIGNUP_PENDING,
    action: 'signup'
});

const signupSuccess = (user, token) => {
    let message = 'Registro exitoso. Usted será redirigido en unos segundos...';
    return {
        type: SIGNUP_SUCCESS,
        user,
        token,
        message
    }
};

const signupError = (error) => ({
    type: SIGNUP_ERROR,
    error
});