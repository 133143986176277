import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Paper, CircularProgress } from '@material-ui/core';
import FlashMessage from '../FlashMessage';

import { forgotPassword } from '../../actions/password/forgotActions';

import { ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import FormTextField from '../FormTextField';
import { NavLink } from 'react-router-dom';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };
    }

    componentDidMount() {
        if(this.props.authenticated) {
            this.props.history.push('/')
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(() => ({ [name]: value }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ submitted: true });

        const { email } = this.state;

        if (email) {
            this.props.forgotPassword(email);
        }
    }

    render() {

        const { email } = this.state;
        const { action, error, message, done, doing } = this.props;

        return (
            <form id="login-form" onSubmit={this.handleSubmit}>
                {action === 'forgot' && done && error && (
                    <FlashMessage message={error} type={ERROR_STATUS}/>
                )}            
                {action === 'forgot' && done && message && (
                    <FlashMessage message={message} type={SUCCESS_STATUS}/>
                )}            
                <Grid container align="center" justify="center" direction="row">
                    <Grid item xs></Grid>
                    <Grid item container xs={12} md={4}>
                        <Paper style={{width:'100%', padding:'40px'}}>
                            <img 
                                alt="logo" 
                                className="logo" 
                                title="logo" 
                                src="/images/logo.png"
                                style={{width:'80%',maxWidth:'240px'}}
                            />
                            <Grid item xs={12} md={12}>
                                <FormTextField
                                    label="Email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{ marginTop: '2.1rem', textAlign: 'left' }}>
                                    {doing ? (
                                        <Button color="primary" variant="contained">
                                            <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px'}} />
                                            Restableciendo...
                                        </Button>
                                    ) : (
                                        <Button color="primary" type="submit" variant="contained">
                                            Restablecer contraseña
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{ marginTop: '1.7rem', textAlign: 'left' }}>
                                    <NavLink to="/login">Iniciar Sesión</NavLink>
                                </div>                                            
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { action, error, message, done, doing } = state.password;
    const { authenticated } = state.auth;
    return {
        action,
        error, 
        message,
        done,
        doing,
        authenticated
    };
};

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);