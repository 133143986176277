import React from 'react';
import Typography from '@material-ui/core/Typography';
import BoardForm from './BoardForm';
import { connect } from 'react-redux';
import { addBoard } from '../../actions/boards/addActions';
import { hasRoles } from '../../helpers/auth';
import BoardAdminForm from './BoardAdminForm';

const BoardCreator = (props) => {

    const { history, addBoard } = props;

    const onSubmit = (board) => {
        addBoard(board).then(newBoard => {
            if (newBoard) {
                history.push(`/boards/edit/${newBoard.id}`);
            }
        });
    };

    const onCancel = () => {
        goBack();
    }

    const goBack = () => {
        history.push('/boards');
    };

    return (
        <div style={{ paddingBottom: '20px' }}>
            <div className="page-header">
                <Typography variant="h6" noWrap className="pull-left">
                    Nueva Placa
                </Typography>
            </div>
            <div className="clearfix"></div>

            {hasRoles(['admin']) ? (
                <BoardAdminForm onSubmit={onSubmit} onCancel={onCancel} />
            ) : (
                <BoardForm onSubmit={onSubmit} onCancel={onCancel} />
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addBoard: board => dispatch(addBoard(board))
});

export default connect(null, mapDispatchToProps)(BoardCreator);