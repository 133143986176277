import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Validator from '../../services/validator';
import FlashMessage from '../FlashMessage';
import FormTextField from "../FormTextField";
import { errors, hasErrors } from '../../helpers/utils';
import FormButtons from '../FormButtons';
import BoardPins from './BoardPins';
import { ACTION_EDIT } from '../../actions/types';
import { PENDING_STATUS } from '../../actions/status';

class BoardForm extends React.Component {

    board = null;
    editMode = false;

    constructor(props) {
       
        super(props);

        this.validator = new Validator({
            description: { required: true },
            phone: { required: true, numeric: true },
            
        });

        this.board = this.props.board; 

        this.editMode = true; 

        this.state = {
            board: this.board,
            errors: this.validator._errors,
            pinsQuantity: this.board.pins.length,
        };
    } 

    onSubmit = (e) => {
        e.preventDefault();
        
        const { description, phone, simCredit } = this.state.board;
        
        let form = { description, phone, simCredit };
        
        if (!this.validator.validate(form)) {
            const errors = this.validator._errors;
            this.setState(() => ({ errors }));
            return;
        }

        this.props.onSubmit(form);
    };

    onTextChange = (e) => {
        this.board[e.target.id] = e.target.value;
        this.setState(() => ({ board: this.board }));
    };

    render() {
        
        const { status, action, message, pinTypes } = this.props;
        const { board } = this.state;
        const done = action === ACTION_EDIT && status !== PENDING_STATUS;
  
        return (
            <div>
                
                { done && <FlashMessage message={message} type={status} /> }

                <form id="board-form" onSubmit={this.onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="IMEI"
                                id="imei"
                                value={this.board.imei}
                                disabled={true}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'imei')}
                            />
                            {errors(this.state, 'imei')}
                        </Grid>                        
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Descripción"
                                id="description"                                
                                value={this.board.description}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'description')}
                            />
                            {errors(this.state, 'description')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Teléfono"
                                id="phone"
                                value={this.board.phone}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'phone')}
                            />
                            {errors(this.state, 'phone')}
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Crédito en SIM"
                                id="simCredit"
                                value={this.board.simCredit}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'simCredit')}
                                disabled= {true}
                            />
                            {errors(this.state, 'simCredit')}
                        </Grid>  */}
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Pines"
                                id="pineQuantity"
                                value={this.state.pinsQuantity}
                                disabled={true}
                                error={hasErrors(this.state, 'pinsQuantity')}
                            />
                            {errors(this.state, 'pinsQuantity')}
                        </Grid>                            
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Tiempo de espera"
                                id="timeWait"
                                value="2 minutos"
                                disabled={true}                                
                            />                            
                        </Grid>                            
                    </Grid>
                    <BoardPins
                        board={board}
                        onPineTypeChange={null}
                        pinTypes={pinTypes}
                    />                    
                    <FormButtons
                        onDelete={null}
                        object={this.state.board}
                        onCancel={this.props.onCancel}
                        status={status}
                        action={action}
                    />
                </form>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    status: state.boards.status,
    action: state.boards.action,
    message: state.boards.message,
    companies: state.companies.list,
    pinTypes: state.boards.pinTypes,
});

const mapDispatchToProps = (dispatch) => ({
    listCompanies: () => dispatch(listCompanies())
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardForm);