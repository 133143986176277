import { makeAuthorizedRequest } from "../../services/apiConnector";

export const REMOVE_COMPONENT_PENDING = 'REMOVE_COMPONENT_PENDING';
export const REMOVE_COMPONENT_SUCCESS = 'REMOVE_COMPONENT_SUCCESS';
export const REMOVE_COMPONENT_ERROR = 'REMOVE_COMPONENT_ERROR';

export const removeComponent = (id) => (dispatch) => {
//    console.log("borrar ", id);
     dispatch(removeComponentPending(id));
     console.log("remove componente: ",id);
    return makeAuthorizedRequest(`/components/${id}`,{method: 'DELETE'})
    .then(response => {
        dispatch(removeComponentSuccess(parseInt(response.data.result.id)));            
        return response.data.result.id;    
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(removeComponentError(error));
    });
};

const removeComponentPending = (id) => ({
    type: REMOVE_COMPONENT_PENDING,
    id
});

const removeComponentSuccess = (id) => ({
    type: REMOVE_COMPONENT_SUCCESS,
    id
});

const removeComponentError = (error) => ({
    type: REMOVE_COMPONENT_ERROR,
    error
});