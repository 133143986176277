import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FormTextField from "../FormTextField";
import { CircularProgress } from "@material-ui/core";
import Validator from "../../services/validator";
import FlashMessage from "../FlashMessage";
import { ACTION_EDIT, ACTION_REMOVE, ACTION_ADD } from "../../actions/types";
import { ERROR_STATUS, PENDING_STATUS } from "../../actions/status";

import { listCompanies } from "../../actions/companies/fetchActions";
import { hasErrors, errors } from "../../helpers/utils";
import ModelPins from "./ModelPins";

class ModelAdminForm extends React.Component {
  board = null;
  editMode = false;

  constructor(props) {
    super(props);

    this.validator = new Validator({
      name: { required: true },
      pinsQuantity: { required: true, min: 1 },
    });

    this.board = this.props.board || {
      name: "",
      pins: [],
    };

    this.editMode = !!this.board.id;

    this.state = {
      board: this.board,
      errors: this.validator._errors,
      pinsQuantity: this.board.pins.length,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { name, pins } = this.state.board;
    const { pinsQuantity } = this.state;

    let form = { name, pinsQuantity };

    if (!this.validator.validate(form)) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }

    this.props.onSubmit({ name, pins });
  };

  onEmaiChange = (e) => {
    this.board[e.target.id] = e.target.value;
    this.setState(() => ({ board: this.board }));
  };

  onPinsQuantityChange = (e) => {
    const pinsQuantity = e.target.value;
    if (isNaN(pinsQuantity)) return;
    this.board.pins = [];
    for (let i = 0; i < pinsQuantity; i++) {
      this.board.pins.push({ address: i + 1, type: 1 });
    }
    this.setState(() => ({ pinsQuantity, board: this.board }));
  };

  onPineTypeChange = (e) => {
    const { pinTypes } = this.props;
    const name = e.target.name;
    const index = name.split("_")[1] - 1;
    this.board.pins[index].type = e.target.value;
    this.setState(() => ({ board: this.board }));
  };

  onDelete = (id) => {
    const { status, action } = this.props;
    const deleting = status === PENDING_STATUS && action === ACTION_REMOVE;
    if (deleting) return;
    this.props.onDelete(id);
  };

  render() {
    const { status, action, message, companies, pinTypes,error } = this.props;
    const { pinsQuantity, board } = this.state;
    const editing =
      status === PENDING_STATUS &&
      (action === ACTION_EDIT || action === ACTION_ADD);
    const deleting = status === PENDING_STATUS && action === ACTION_REMOVE;

    return (
      <div>
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status == ERROR_STATUS && (
            <FlashMessage message={error} type={status}/>
          )}
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status !== PENDING_STATUS && (
            <FlashMessage message={message} type={status} />
          )}

        <form id="board-form" onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Nombre del modelo"
                id="name"
                value={this.board.name}
                disabled={this.editMode}
                onChange={this.onEmaiChange}
                error={hasErrors(this.state, "name")}
              />
              {errors(this.state, "name")}
            </Grid>
            <Grid item xs={12} md={1}>
              <FormTextField
                label="Pines"
                id="pinsQuantity"
                value={pinsQuantity}
                onChange={this.onPinsQuantityChange}
                error={hasErrors(this.state, "pinsQuantity")}
              />
              {errors(this.state, "pinsQuantity")}
            </Grid>
          </Grid>

          <ModelPins
            board={board}
            onPineTypeChange={this.onPineTypeChange}
            pinTypes={pinTypes}
          />

          <div style={{ marginTop: "3.1rem" }}>
            <Button
              color="primary"
              type={editing ? "button" : "submit"}
              variant="contained"
              startIcon={<SaveIcon />}
            >
              {editing && (
                <CircularProgress
                  style={{ width: "15px", height: "15px", marginRight: "5px" }}
                />
              )}
              {editing ? "Guardando..." : "Guardar"}
            </Button>

            <Button
              style={{ marginLeft: "0.8rem" }}
              variant="contained"
              onClick={this.props.onCancel}
            >
              Volver
            </Button>

            {this.props.onDelete && (
              <Button
                style={{ float: "right" }}
                color="secondary"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => this.onDelete(this.board.id)}
              >
                {deleting && (
                  <CircularProgress
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {deleting ? "Borrando..." : "Borrar"}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.boards.status,
  action: state.boards.action,
  message: state.boards.message,
  pinTypes: state.boards.pinTypes,
  error: state.boards.error,
});

export default connect(mapStateToProps, null)(ModelAdminForm);
