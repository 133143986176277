import { makeAuthorizedRequest } from "../../services/apiConnector";

export const REMOVE_PANEL_PENDING = "REMOVE_PANEL_PENDING";
export const REMOVE_PANEL_SUCCESS = "REMOVE_PANEL_SUCCESS";
export const REMOVE_PANEL_ERROR = "REMOVE_PANEL_ERROR";

export const removePanel = (id) => (dispatch) => {
  dispatch(removePanelPending(id));
  return makeAuthorizedRequest(`/panels/${id}`, { method: "DELETE" })
    .then((response) => {
      dispatch(removePanelSuccess(parseInt(response.data.result.id)));
      return response.data.result.id;
    })
    .catch((error) => {
      const message =
        typeof error.response !== "undefined"
          ? error.response.data.message
          : "Error desconocido";
      dispatch(removePanelError(message));
      return false
    });
};

const removePanelPending = (id) => ({
  type: REMOVE_PANEL_PENDING,
  id,
});

const removePanelSuccess = (id) => ({
  type: REMOVE_PANEL_SUCCESS,
  id,
});

const removePanelError = (error) => ({
  type: REMOVE_PANEL_ERROR,
  error,
});
