import React from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { addUser } from "../../actions/users/addActions";
import UserForm from "./UserForm";

const UserCreator = (props) => {
  const { history, addUser } = props;
  const onSubmit = (user) => {
    addUser(user).then((newUser) => {
      if (newUser) {
        history.push(`/users/edit/${newUser.id}`);
      }
    });
  };

  const onCancel = () => {
    goBack();
  };

  const goBack = () => {
    history.push("/users");
  };

  return (
    <div>
      <div className="page-header">
        <Typography variant="h6" noWrap className="pull-left">
          Nuevo usuario
        </Typography>
      </div>
      <div className="clearfix"></div>
      <UserForm onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (user) => dispatch(addUser(user)),
});

export default connect(null, mapDispatchToProps)(UserCreator);
