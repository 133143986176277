import { FETCH_USERS_ERROR, FETCH_USERS_SUCCESS, FETCH_USERS_PENDING } from '../actions/users/fetchActions';
import { REMOVE_USER_ERROR, REMOVE_USER_SUCCESS, REMOVE_USER_PENDING } from '../actions/users/removeActions';
import { EDIT_USER_ERROR, EDIT_USER_SUCCESS, EDIT_USER_PENDING } from '../actions/users/editActions';
import { ADD_USER_ERROR, ADD_USER_SUCCESS, ADD_USER_PENDING } from '../actions/users/addActions';
import { ACTION_ADD, ACTION_EDIT, ACTION_FETCH, ACTION_REMOVE } from '../actions/types';
import { PENDING_STATUS, SUCCESS_STATUS, ERROR_STATUS } from '../actions/status';

const successMessage = 'Los datos se guardaron con éxito.';

const defaultState = {
  status: null,
  message: null,
  action: null,
  data: [],
  pagination: {
      currentPage: 0,
      perPage: 10,
      total: 0
  }
};

export default (state = defaultState, action) => {
    switch(action.type) {
            // LIST USER    
        case FETCH_USERS_PENDING: 
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_FETCH,
                pagination: {
                    ...state.pagination, 
                    perPage: action.perPage,
                    currentPage: action.currentPage
                }
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,    
                status: SUCCESS_STATUS,
                data: action.data,
                pagination: {
                    ...state.pagination,
                    currentPage: action.currentPage,
                    perPage: action.perPage,
                    total: action.total
                }
            };
        case FETCH_USERS_ERROR:
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
            };      
            // ADD USER 
        case ADD_USER_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_ADD           
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: successMessage,
                data: [
                    ...state.data, 
                    {...action.user}
                ]
            };            
        case ADD_USER_ERROR: 
        return {
            ...state,
            status: ERROR_STATUS,
            error: action.error
          };
            // EDIT USER    
        case EDIT_USER_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_EDIT
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: successMessage,
                data: state.data.map((object) => {                    
                    if(object.id === action.id) {
                      return {
                        ...object,
                        ...action.updates
                      };
                    }
                    else {
                      return object;
                    }
                })                
            };            
        case EDIT_USER_ERROR:  
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
            };          
            
            //REMOVE USER   
        case REMOVE_USER_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_REMOVE
            };
        case REMOVE_USER_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: 'Registro borrado exitosamente.',
                data: state.data.filter(({id}) => id !== action.id)
            };            
        case REMOVE_USER_ERROR:            
            return {
                ...state,
                status: ERROR_STATUS,
                error: action.error
            };              
        default:
            return state;
    };
};