import React from 'react';
import { Grid } from "@material-ui/core";
import ComponentButton from "../components/ComponentButton";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PumpModel from '../../models/PumpModel';
import SelectorTowPositionModel from '../../models/SelectorTowPositionModel';
import SelectorThreePositionModel from '../../models/SelectorThreePositionModel';
import GenericModel from '../../models/GenericModel';

const PanelMenuDigitalInputs = (props) => {
    const { handleChange, expanded } = props;

    const addDigitalPump = () => {
        const object = new PumpModel({ panel: props.panel });
        props.addComponent(object.getConfig());
    };
    
    const addDigitalSelectorTow = ()=>{
        const object = new SelectorTowPositionModel({ panel: props.panel });
        props.addComponent(object.getConfig());
    }

    const addDigitalSelectorThree = ()=>{
        const object = new SelectorThreePositionModel({ panel: props.panel });
        props.addComponent(object.getConfig());
    }

    const addDigitalGeneric = ()=>{
        const object = new GenericModel({ panel: props.panel });
        props.addComponent(object.getConfig());
        // console.log(object.getConfig())
    }

    return (
        <ExpansionPanel className="expansion-item"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="expansion-sumary"
            >
                <Typography className="expansion-header">Entradas Digitales (lecturas)</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <Typography></Typography>
                <Grid container spacing={1} style={{ width: "100%", margin: "auto" }}>
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalPump}
                            text={"Bomba"}
                            image={"/images/pump-default.png"}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalSelectorTow}
                            text={"Selectora 2 Posiciones"}
                            image={"/images/selectorTow-default.png"}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalSelectorThree}
                            text={"Selectora 3 Posiciones"}
                            image={"/images/selectorThree-default.png"}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalGeneric}
                            text={"Generico"}
                            image={"/images/generic-default.png"}
                        />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default PanelMenuDigitalInputs;