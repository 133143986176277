import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { ACTION_REMOVE } from '../../actions/types';

const UserListTable = (props) => {
    
    const { users } = props;

    return (
        <TableContainer className="users" component={Paper}>
            <Table className="table" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre y Apellido</TableCell>
                        <TableCell align="right">E-mail</TableCell>
                        <TableCell align="right">Rol</TableCell>
                        <TableCell align="right">Empresa</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell component="th" scope="row">
                                { user.name }
                            </TableCell>
                            <TableCell align="right">
                                { user.email }
                            </TableCell>
                            <TableCell align="right">
                                { user.role && user.role.displayName }
                            </TableCell>
                            <TableCell align="right">
                                { user.company ? user.company.name : 'N/D' }
                            </TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => props.onEditClicked(user.id)} aria-label="Editar" component="span">
                                    <EditIcon />
                                </IconButton>                                
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UserListTable;