import { getFormData } from "../../helpers/utils";
import { makeAuthorizedRequest } from "../../services/apiConnector";

export const EDIT_BOARD_PENDING = "EDIT_BOARD_PENDING";
export const EDIT_BOARD_SUCCESS = "EDIT_BOARD_SUCCESS";
export const EDIT_BOARD_ERROR = "EDIT_BOARD_ERROR";

export const editBoard = (id, updates) => (dispatch) => {
  dispatch(editBoardPending());

  const data = updates; //getFormData(updates);
  console.log(data);
  return makeAuthorizedRequest(`/boards/${id}`, { method: "PATCH", data })
    .then((response) => {
      dispatch(editBoardSuccess(id, updates));
      return response.data.result.id;
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : "Error desconocido";
      dispatch(editBoardError(message));
    });
};

const editBoardPending = () => ({
  type: EDIT_BOARD_PENDING,
});

const editBoardSuccess = (id, updates) => ({
  type: EDIT_BOARD_SUCCESS,
  id,
  updates,
});

const editBoardError = (error) => ({
  type: EDIT_BOARD_ERROR,
  error,
});
