import { makeAuthorizedRequest } from "../../services/apiConnector";

export const ADD_COMPONENT_PENDING = "ADD_COMPONENT_PENDING";
export const ADD_COMPONENT_SUCCESS = "ADD_COMPONENT_SUCCESS";
export const ADD_COMPONENT_ERROR = "ADD_COMPONENT_ERROR";



export const addComponent = (iocomponent) => (dispatch) => {

    dispatch(addComponentPending());
    
    const data = {
        ...iocomponent,
        panel: iocomponent.panel.id,
        board: iocomponent.board.id,
        pins: iocomponent.pins.map((pin) => pin.id),
        company: iocomponent.company, // add companias in the component
        // showElement: true,
        
    };    
    delete data['cell'];
    delete data['children'];
    delete data['id'];
    delete data['rangeNumbers'];
    delete data["alerts"];    
    // console.log('Add accions /components');
    return makeAuthorizedRequest(`/components`, { method: "post", data })
        .then((response) => {
            dispatch(addComponentSuccess(response.data.result));
            return response.data.result;
        })
        .catch((error) => {
            const message = error.response
                ? error.response.data.message
                : "Error desconocido";
            dispatch(addComponentError(message));
    });
};



const addComponentPending = () => ({
    type: ADD_COMPONENT_PENDING,
});

const addComponentSuccess = (iocomponent) => ({
    type: ADD_COMPONENT_SUCCESS,
    iocomponent,
});

const addComponentError = (error) => ({
    type: ADD_COMPONENT_ERROR,
    error,
});




