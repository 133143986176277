import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//import Icon from "@mui/icons-material/DangerousRounded";

import DangerousRoundedIcon from '@material-ui/core/IconButton';

const UserMenu = (props) => {
  const { anchorEl, profileMenuOpen, onProfileMenuClose, onLogout } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={profileMenuOpen}
      onClose={onProfileMenuClose}
    >
      <MenuItem onClick={onLogout}>Cerrar sesión</MenuItem>
    </Menu>
  );
};

export default UserMenu;
