import TextComponentModel from "./TextComponentModel";
import shapes from "../helpers/shapes";
class TextModel extends TextComponentModel {
  constructor(props){
    super(props);
    this.type = "textLabel";
    this.label = props.label ? props.label : "Etiqueta";
    this.selectable = true;
  }
  getConfig = (parent = null) => {
    const label = this.getLabel(this.label, 0, 170);
    // const alert = this.getAlert(80, 140);

    this.children = [label];

    const shape = { ...shapes[this.type] };
    // shape.styles = this.getImage();

    return {
      parent,
      object: this,
      shape,
      children: this.children,
    };
  };
}


export default  TextModel;
