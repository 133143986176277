//new panel idem yuli
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import { useHistory } from "react-router-dom";

const NewPanelCard = (props) => {

    const { panel } = props;

    const history = useHistory();

    const handleNewClick = () => {
        history.push('/panels/create');
    };

    return (
        <Grid item xs={12} sm={3}>
            <Paper className="new-panel" onClick={handleNewClick}>
                <IconButton className="new-panel__button">
                    <AddIcon />
                </IconButton>
            </Paper>
        </Grid>
    );
}

export default NewPanelCard;