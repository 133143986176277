import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { hasRoles } from "../../helpers/auth";

const ModelListTable = (props) => {
  
  const { models } = props;
  return (
    <TableContainer className="models" component={Paper}>
      <Table className="table" aria-label="customized table">

        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Pines</TableCell>
            <TableCell>Veces usadas</TableCell>            
          </TableRow>
        </TableHead>
      {models.map((model) => (
        
        <TableBody>
          
            <TableRow  key={model.id} >
              <TableCell component="th" scope="row">
                {model.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {model.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {model.config.length}
              </TableCell>
              <TableCell component="th" scope="row">
                {model.used} 
              </TableCell>
              
             
              <TableCell align="right">
                <IconButton                  
                  aria-label="Editar"
                  component="span"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          
        </TableBody>
      ))}
      </Table>
    </TableContainer>
  );
};

export default ModelListTable;
