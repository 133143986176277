import TankModel from "./TankModel";
import ChronometerModel from "./ChronometerModel";
import DChronometerModel from "./DChronometerModel";
import AlertIconModel from "./AlertIconModel";
import PumpModel from "./PumpModel";
import SelectorTowPositionModel from "./SelectorTowPositionModel";
import SelectorThreePositionModel from "./SelectorThreePositionModel";
import GenericModel from "./GenericModel";
import SwitchModel from "./SwitchModel";
import SwitchKeyModel from "./SwitchKeyModel";

export const getModel = (props) => {
  switch (props.type) {
    case "tank":
      return new TankModel(props);
    case "chronometer":
      return new ChronometerModel(props);
    case "dchronometer":
      return new DChronometerModel(props);
    case "pump":
      return new PumpModel(props)
    case "selectortowposition":
      return new SelectorTowPositionModel(props)
    case "selectorthreeposition":
      return new SelectorThreePositionModel(props)
    case "generic":
      return new GenericModel(props)  
    case "switch":
      return new SwitchModel(props)
    case "switchKey":
      return new SwitchKeyModel(props)
    case "alertIcon":
      return new AlertIconModel(props);
    default:
      return new ChronometerModel(props);    
  }
};
