import { makeAuthorizedRequest } from "../../services/apiConnector";


export const outputComponent = ({imei, pin, panel}) => {    
    return (dispatch) => {                                
        return makeAuthorizedRequest(`/data/output?imei=${imei}&panel=${panel}&pins=${pin}`,{method: 'GET'})
        // data/input?imei=1234567890&credit=Saldo AR$10.00&pins={"1":512,"2":512,"3":0,"4":0,"5":0}
        .then(response => {            
            return response.data.result;
            // console.log(response.data.result)
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';            
        });
    }
}