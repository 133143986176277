import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_COMPONENTS_PENDING = 'FETCH_COMPONENTS_PENDING';
export const FETCH_COMPONENTS_SUCCESS = 'FETCH_COMPONENTS_SUCCESS';
export const FETCH_COMPONENTS_ERROR = 'FETCH_COMPONENTS_ERROR';

export const LIST_COMPONENTS_PENDING = 'LIST_COMPONENTS_PENDING';
export const LIST_COMPONENTS_SUCCESS = 'LIST_COMPONENTS_SUCCESS';
export const LIST_COMPONENTS_ERROR = 'LIST_COMPONENTS_ERROR';

export const fetchComponents = ({currentPage, perPage, panel}) => {
    return (dispatch) => {
        dispatch(fetchComponentsPending({currentPage, perPage}));
        const skip = parseInt(currentPage) * parseInt(perPage);
        return makeAuthorizedRequest(`/components?skip=${skip}&limit=${perPage}&panel=${panel}&populate=board,pins,alerts`)
        .then(response => {
            dispatch(fetchComponentsSuccess(response.data, currentPage, perPage));            
            return response.data.result;
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
            dispatch(fetchComponentsError(message));
        });
    }
}

export const listComponents = () => (dispatch) => {
    dispatch(listComponentsPending());
    return makeAuthorizedRequest('/components?select=id,imei')
    .then(response => {
        dispatch(listComponentsSuccess(response.data));
        return response.data.result;
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(listComponentsError(message));
    });    
};

const fetchComponentsPending = ({currentPage, perPage}) => ({
    type: FETCH_COMPONENTS_PENDING,
    currentPage,
    perPage
});

const fetchComponentsSuccess = (data, currentPage, perPage) => ({
    type: FETCH_COMPONENTS_SUCCESS,
    data: data.result,
    currentPage: currentPage,
    perPage: perPage,
    total: data.total
});

const fetchComponentsError = (error) => ({
    type: FETCH_COMPONENTS_ERROR,
    error
});

const listComponentsPending = () => ({
    type: LIST_COMPONENTS_PENDING
});

const listComponentsSuccess = (data) => ({
    type: LIST_COMPONENTS_SUCCESS,
    data: data.result
});

const listComponentsError = (error) => ({
    type: LIST_COMPONENTS_ERROR,
    error
});
