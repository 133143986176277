import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import BoardUserForm from './BoardUserForm';
import { PENDING_STATUS } from '../../actions/status';
import { linkBoard } from "../../actions/boards/linkActions";

export class BoardLink extends React.Component {
    constructor(props) {
      super(props);
      const board = this.props
    }
    
    onSubmit = (updates) => {
      const {linkBoard} = this.props;
      linkBoard(updates);
    };
    
    goBack = () => 
    this.props.history.push("/boards");
  
    onCancel = () => {
      this.goBack();
    };
  
    render = () => {
      const { board } = this.props;
      return (
        <div style={{ paddingBottom:'20px'}}>
          <div 
            className="page-header">
              <Typography variant="h6" noWrap className="pull-left">
                   Agregar una Nueva Placa
              </Typography>
          </div>
          <div className="clearfix"></div>
              <BoardUserForm
                board={board}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
              />
          </div>
        );
     };
   }
  
  const mapStateToProps = (state, props) => {
    const board = state.boards.data.find(
      (board) => board.id === parseInt(props.match.params.id)
    );
    return {
      status: state.boards.status,
      message: state.boards.message,
      action: state.boards.action,
      board,
    };
    
  };
  
  const mapDispatchToProps = (dispatch) => ({
    linkBoard: (updates) => dispatch(linkBoard(updates)),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(BoardLink);