import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CompanyListTable from './CompanyListTable';
import TablePagination from '@material-ui/core/TablePagination';

import { fetchCompanies } from '../../actions/companies/fetchActions';

import LoadingSpinner from '../LoadingSpinner';

import FlashMessage from '../FlashMessage';
import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import { ACTION_FETCH } from '../../actions/types';

export class CompanyList extends React.Component {

    onCreateClicked = () => this.props.history.push('/companies/create');

    onEditClicked = (id) => this.props.history.push(`/companies/edit/${id}`);

    componentDidMount = () => {
        const {perPage, current} = this.props.pagination;
        this.props.fetchCompanies({currentPage: current, perPage});
    }

    handleChangePage = (event, currentPage) => {
        const { perPage } = this.props.pagination;
        this.props.fetchCompanies({currentPage:(currentPage), perPage});
    }
    
    handleChangeRowsPerPage = (event) => {
        let { fetchCompanies } = this.props;
        const perPage = event.target.value;
        fetchCompanies({currentPage: 0, perPage});
    };
    
    render = () => {

        const { status, companies, message, action } = this.props;
        const { currentPage, perPage, total } = this.props.pagination;

        return (
            <div>
                <div className="page-header">
                    <Typography variant="h6" noWrap className="pull-left">
                        Empresas
                    </Typography>
                    <div className="pull-right">
                        <Button 
                        id="create-company" 
                        onClick={this.onCreateClicked} 
                        color="secondary" 
                        variant="contained"
                        >
                            Crear nueva
                        </Button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                
                {status === PENDING_STATUS && <LoadingSpinner/>}

                {action === ACTION_FETCH && status === ERROR_STATUS && (
                    <FlashMessage type={status} message={message}/>
                )}

                {(status === SUCCESS_STATUS || status === ERROR_STATUS) && companies.length === 0 && (
                    <p>No hay registros para mostrar.</p>
                )}
    
                { companies.length > 0 && (
                    <div>
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <CompanyListTable 
                            onRemoveClicked={this.onRemoveClicked} 
                            onEditClicked={this.onEditClicked} 
                            companies={companies}
                        />
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                ) }
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    companies: state.companies.data,
    action: state.companies.action,
    status: state.companies.status,
    pagination: state.companies.pagination
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanies: (params) => dispatch(fetchCompanies(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);