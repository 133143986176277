import React from "react";
import TabsComponent from "../TabsComponent";
import AlertsAnalogComponentEditor from "./AlertsAnalogComponentEditor";
import AlertsDigitalComponentEditor from "./AlertsDigitalComponentEditor";
import AnalogComponentEditor from "./AnalogComponentEditor";
import DigitalComponentEditor from "./DigitalComponentEditor";
import OutputComponentEditor from "./OutputComponentEditor";
import TextComponentEditor from "./TextComponentEditor";
export class ComponentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.iocomponent = props.iocomponent;

    this.tabs = [
      { index: 0, value: "Componente", isValid: true },
      { index: 1, value: "Alertas", isValid: true },
    ];

    this.state = {
      selectedTab: 0,
    };
    // arreglar los or de comparacion
    if (this.props.iocomponent.type == 'tank' ||
      this.props.iocomponent.type == 'dchronometer' ||
      this.props.iocomponent.type == 'chronometer') {
      this.components = [
        <AnalogComponentEditor
          iocomponent={this.iocomponent}
          setValidStatus={this.setValidStatus}
          index={0}
        />,
        <AlertsAnalogComponentEditor
          iocomponent={this.iocomponent}
          setValidStatus={this.setValidStatus}
          index={1}
        />,
      ];
    } else if (this.props.iocomponent.type == 'pump'||
    this.props.iocomponent.type == 'generic'){
      this.components = [
        <DigitalComponentEditor
          iocomponent={this.iocomponent}
          setValidStatus={this.setValidStatus}
          index={0}
        />,
        <AlertsDigitalComponentEditor
        iocomponent={this.iocomponent}
        setValidStatus={this.setValidStatus}
        index={1}
      />,
      ];
    }
    else if (this.props.iocomponent.type == 'selectortowposition'||
             this.props.iocomponent.type == 'selectorthreeposition'
             ){
      this.components = [
        <DigitalComponentEditor
          iocomponent={this.iocomponent}
          setValidStatus={this.setValidStatus}
          index={0}
        />
      ];
    }
    else if(this.props.iocomponent.type =='switch' || this.props.iocomponent.type == 'switchKey'){
      this.components =[
        <OutputComponentEditor
          iocomponent={this.iocomponent}
          setValidStatus={this.setValidStatus}          
        />
      ]
      this.tabs = [
        { index: 0, value: "Componente", isValid: true },        
      ];
    }else if(this.props.iocomponent.type == 'textLabel'){
      this.components=[
        <TextComponentEditor
        iocomponent={this.iocomponent}
        />
      ]
      this.tabs = [
        { index: 0, value: "Etiqueta", isValid: true },        
      ];
    };
  }


  setValidStatus = (index, isValid) => {
    this.tabs = this.tabs.map((tab) => {
      if (tab.index === index) {
        tab.isValid = isValid;
      }
      return tab;
    });
    const valid =
      this.tabs.filter(({ isValid }) => {
        return isValid === false;
      }).length === 0;
    this.props.setIsValid(valid);
  };

  handleTabPanel = (value) => {
    this.setState(() => ({ selectedTab: value }));
  };

  render() {
    const component = this.components[this.state.selectedTab];    
      return (
        <TabsComponent
          tabs={this.tabs}
          component={component}
          handleTabPanel={this.handleTabPanel}
        />
      );
  }
}

export default ComponentEditor;
