import shapes from "../helpers/shapes";

const defaultAlert = {
  condition: "",
  value: "",
  message: "",
  isActive: false,
  errors: [],
};

const texts = ["label", "dchhronometer", "dchronometerUnit","tank"];

class AnalogComponentModel {
  constructor(props) {
    const {
      id = null,
      label = "",
      type = null,
      xPosition = 100,
      yPosition = 100,
      panel = null,
      pins = [],
      smsAlert = false,
      showElement = false,
      showHistory = false,
      board = null,
      value = 0,
      isActive = false,      
      conversionRules = {
        read: { min: 0, max: 20, um: "mA" },
        write: { min: 0, max: 0, um: null },
      },
      
      alertRules = [
        {
          ...defaultAlert,
          priority: 3,
          severity: "pre-alert",
          label: "Pre-alerta",
        },
        { ...defaultAlert, priority: 2, severity: "alert", label: "Alerta" },
        { ...defaultAlert, priority: 1, severity: "failure", label: "Fallo" },
      ],
      rangeNumbers = [],
      cell = null,
      alerts = [],
      selectable = false,
    } = props;

    this.id = id;
    this.label = label;
    this.type = type;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.panel = panel;
    this.pins = pins;
    this.smsAlert = smsAlert;
    this.showElement = showElement;
    this.showHistory = showHistory;
    this.board = board;
    this.value = value;
    this.isActive = isActive;
    this.conversionRules = conversionRules;
    this.alertRules = alertRules;
    this.rangeNumbers = rangeNumbers;
    this.cell = cell;
    this.alerts = alerts;
    this.selectable = selectable;
  }

  toString = () => {
    let value = this.value != null ? this.value : "";

    if (!isNaN(value) && value !== "") {
      value = parseFloat(value).toFixed(2);
    }

    const um =
      this.conversionRules && this.conversionRules.write.um
        ? this.conversionRules.write.um
        : "";
    return `${value} ${um}`;
  };

  static capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getConfig = (parent = null) => {
    switch (this.type) {
      case "tank":
      case "chronometerValue":
      case "value":
      case "label":
      case "dchronometerUnit":
      case "dchronometerValue":
      case "alertIcon":
        return this.getChildConfig(parent);
      default:
        return null;
    }
  };

  getLabel = (value, xPosition, yPosition) => {
    return new AnalogComponentModel({
      type: "label",
      value,
      xPosition,
      yPosition,
    });
  };

  getChildConfig = (parent) => {
    const shape = shapes[this.type];
    const width = parent.geometry.width;
    return {
      parent,
      object: this,
      shape: { ...shape, width },
      children: [],
    };
  };

  setValue = (value, model) => {
    value = parseFloat(value).toFixed(2);
    this.value = value;
    model.setValue(this.cell, this);
    this.children &&
      this.children.forEach((child) => {
        if (texts.indexOf(child.type) === -1) {
          child.value = value;
          model.setValue(child.cell, child);
        }
      });

    this.updateImage(value);
  };

  updateImage(value) {
    const min = this.conversionRules.write.min;
    const max = this.conversionRules.write.max;

    const percent = ((value - min) / (max - min)) * 100;
    let level = this.getLevel(percent);
    let style = shapes[this.type].styles;
    style = style.replace("default", level);
    this.cell.setStyle(style);
  }

  getImage = () => {
    const min = this.conversionRules.write.min;
    const max = this.conversionRules.write.max;
    const percent = ((this.value - min) / (max - min)) * 100;
    let level = this.getLevel(percent);
    let style = shapes[this.type].styles+"width:50px";
    console.log(style);
    return style.replace("default", level);
  };

  getLevel(percent) {
    if (percent < 10 || Number.isNaN(percent)) {
      return 0;
    } 
    else if (percent >= 10 && percent < 20) {
      return 10;
    } 
    else if (percent >= 20 && percent < 30) {
      return 20;
    } 
    else if (percent >= 30 && percent < 40) {
      return 30;
    } 
    else if (percent >= 40 && percent < 50) {
      return 40;
    }
    else if (percent >= 50 && percent < 60) {
      return 50;
    }
    else if (percent >= 60 && percent < 70) {
      return 60;
    }
    else if (percent >= 70 && percent < 80) {
      return 70;
    }
    else if (percent >= 80 && percent < 90) {
      return 80;
    }
    else if (percent >= 90 && percent < 100) {
      return 90;
    }
    else {
      return 100;
    }
  }

  setAlerts = (alerts) => {
    this.alerts = alerts.length > 0 ? alerts : [{ severity: "default" }];
    const icon = this.children.find((child) => child.type === "alertIcon");
    if (!icon) return;

    const severity = this.alerts[0].severity;
    let style = shapes[icon.type].styles;
    style = style.replace("default", severity);
    icon.cell.setStyle(style);
  };
}

export default AnalogComponentModel;
