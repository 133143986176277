import React from "react";

export const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      String(obj[propName]).trim() === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getFormData = (attributes) => {
  let formData = new FormData();
  Object.keys(attributes).forEach((key, value) => {
    if (Array.isArray(attributes[key])) {
      formData.append(key, JSON.stringify(attributes[key]));
    } else {
      formData.append(key, attributes[key]);
    }
  });
  return formData;
};

export const errors = (state, key) => {
  return (
    state.errors[key] &&
    state.errors[key].map((error, index) => (
      <span key={index} className="error">
        {error}
      </span>
    ))
  );
};

export const hasErrors = (state, key) =>
  typeof state.errors[key] !== "undefined" && state.errors[key].length > 0;

export const comparisonConditions = () => {
  return [
    { key: "==", description: "igual" },
    { key: "!=", description: "distinto" },
    { key: "<", description: "menor" },
    { key: "<=", description: "menor o igual" },
    { key: ">", description: "mayor" },
    { key: ">=", description: "mayor o igual" },
  ];
};

export const comparisonType = () => {
  return [
    { key: "!=", description: "Normal Abierto" },
    { key: "==", description: "Normal Cerrado" },
  ];
};
