import { makeAuthorizedRequest } from "../../services/apiConnector";

export const EDIT_PIN_PENDING = "EDIT_PIN_PENDING";
export const EDIT_PIN_SUCCESS = "EDIT_PIN_SUCCESS";
export const EDIT_PIN_ERROR = "EDIT_PIN_ERROR";


export const editPin = (id) => (dispatch) => {
    dispatch(editPinPending());
    // console.log(id);
    return makeAuthorizedRequest(`/pins/${id}`, { method: "PATCH"})
        .then((response) => {
            dispatch(editPinSuccess(id));
            return response.data.result.id;
        })
        .catch((error) => {
            const message = error.response
                ? error.response.data.message
                : "Error desconocido";
            dispatch(editPinError(message));
        });
};

const editPinPending = () => ({
    type: EDIT_PIN_PENDING,
});

const editPinSuccess = (id) => ({
    type: EDIT_PIN_SUCCESS,
    id
    // updates,
});

const editPinError = (error) => ({
    type: EDIT_PIN_ERROR,
    error,
});
