import { getFormData } from "../../helpers/utils";
import { makeRequest } from "../../services/apiConnector";

export const RESET_PENDING = "RESET_PENDING";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

export const resetPassword = (password, token) => (dispatch) => {
  dispatch(resetPasswordPending());

  const data = getFormData({ password, token });

  return makeRequest("/password/reset", { method: "POST", data })
    .then((response) => {
      const reset = response.data.result;
      dispatch(resetPasswordSuccess());
      return reset;
    })
    .catch((error) => {
      const message = error.response.data.message
        ? error.response.data.message
        : "Error desconocido";
      dispatch(resetPasswordError(message));
    });
};

const resetPasswordPending = () => ({
  type: RESET_PENDING,
  action: "reset",
});

const resetPasswordSuccess = () => ({
  type: RESET_SUCCESS,
  message: "Contraseña restablecida con exito.",
});

const resetPasswordError = (error) => ({
  type: RESET_ERROR,
  error,
});
