import React, {Component} from "react";
//import SweetAlert from 'react-boststrap-sweeta-lert';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DialogContentText from '@material-ui/core/DialogContentText';
//import FlashMessage from '../FlashMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  }, 
  ActiveElement: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    fontSize:'0.5 rem'  
  },
}));

export default function ScrollDialog(props) {
  const iocomponent = props.children.props.iocomponent;
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const [isValid, setIsValid] = React.useState(true);
  const { dividers, title, hideCancel,} = props;
  const maxWidth = props.maxWidth ? props.maxWidth : "lg";
  const[openDelete, setOpenDelete]=React.useState(false);
  const[openActivate, setOpenActivate]=React.useState(false);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenActivate = () => {
    setOpenActivate(true);
  };
  const handleCloseActivate = () => {
    setOpenActivate(false);
  };


  const handleAccept = () => {
    if (!isValid) return;
    setOpen(false);
    if (!!props.handleAccept) {
      props.handleAccept();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    if (!!props.handleCancel) {
      props.handleCancel();
    }
  };

  const handleDeleted = () => {
    setOpen(false);
    if (!!props.handleDeleted) {
      props.handleDeleted();
    }
  };

  const handleSave = () => {
    if (!isValid) return;
    setOpen(false);
  };
  const handleClose = () => {};
  
  const handleActive = () => {    
    if(!!props.handleActive){
      props.handleActive();
    }
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isValid, setIsValid });
    }
    return child;
  });

 
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
    >
    {/* <DialogTitle id="scroll-dialog-title">
      {title}      
      {iocomponent.id &&(
        <Button        
        className={classes.ActiveElement}
        variant="contained"
        color="secondary"
        disabled={iocomponent.isValid && console.log(iocomponent.isValid) }
        onClick={handleClickOpenActivate} >
          Activar
      </Button>
      )}
      
      
    </DialogTitle> */}
      <DialogContent dividers={dividers} className={classes.root}>
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title-Delete"
          aria-describedby="alert-dialog-description-Delete"
        >
          <DialogTitle id="alert-dialog-title-Delete">{"Eliminar componente"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-Delete">
              Estás seguro que deseas borrar este componente?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleted} color="primary" autoFocus>
              Borrar
            </Button>
          </DialogActions>
        </Dialog>
        {/*  */}
        <Dialog
          open={openActivate}
          onClose={handleCloseActivate}
          aria-labelledby="alert-dialog-title-Activate"
          aria-describedby="alert-dialog-description-Activate"
        >
          <DialogTitle id="alert-dialog-title-Activate">{"Activar componente"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-Activate">
              Una vez activo ya no podrás editar más los pines de este componente. <br/>
              Estás seguro de continuar?. 
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseActivate} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleActive} color="primary" autoFocus>
              Activar
            </Button>
          </DialogActions>
        </Dialog>
        {childrenWithProps}
      </DialogContent>

      <DialogActions>
        {iocomponent.id && (          
          <Button
          style={{ marginRight: '1000px' }}
          variant="contained"
          color="secondary"
          onClick={handleClickOpenDelete}
          >
          {props.deletedText}
          </Button>)}
        {!hideCancel && (
          <Button onClick={handleCancel} color="primary">
            {props.cancelText}
          </Button>
        )}
        <Button onClick={handleAccept} color="primary" disabled={!isValid}>
          {props.acceptText}
        </Button>
        
      </DialogActions>
    </Dialog>
    
      
  );
}

ScrollDialog.propTypes = {
  dividers: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

ScrollDialog.defaultProps = {
  dividers: true,
  hideCancel: false,
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  deletedText: "Borrar",
};
