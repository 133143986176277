import React from "react";
import Typography from "@material-ui/core/Typography";
import PanelForm from "./PanelForm";
import { connect } from "react-redux";
import { editPanel } from "../../actions/panels/editActions";
import { removePanel } from "../../actions/panels/removeActions";
import { clean } from "../../helpers/utils";
import { ACTION_REMOVE } from "../../actions/types";
import { PENDING_STATUS } from "../../actions/status";
import FlashMessage from "../FlashMessage";

const PanelEditor = (props) => {
  const { history, message, status, action, panel } = props;

  if(!panel || !panel.id) {
    history.push('/panels');
  }

  const onSubmit = (updates) => {
    const { editPanel, panel } = props;
    updates = clean(updates);
    editPanel(panel.id, updates);
  };

  const onDelete =  (id) => {
    const { removePanel } = props;
    removePanel(id)
    .then((res) => {
      if(res!=false){
        setTimeout(() => {
          goBack();
        }, 2000);
      }
    })
    .catch((error) => {      
      console.error("Error al eliminar el panel:", error);
    });
  };

  const onCancel = () => goBack();

  const goBack = () => history.push("/panels");

  return (
    <div>
      <div className="page-header">
        <Typography variant="h6" noWrap className="pull-left">
          Edición de panel
        </Typography>
      </div>
      <div className="clearfix"></div>
      {action === ACTION_REMOVE && status !== PENDING_STATUS && (
        <FlashMessage message={message} type={status} />
      )}
      <PanelForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        onDelete={onDelete}
        panel={panel}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const panel = state.panels.data.find(
    (panel) => panel.id === parseInt(props.match.params.id)
  );
  return {
    status: state.panels.status,
    message: state.panels.message,
    action: state.panels.action,
    panel,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editPanel: (panel, updates) => dispatch(editPanel(panel, updates)),
  removePanel: (id) => dispatch(removePanel(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelEditor);
