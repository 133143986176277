import React, {useState, useEffect} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
// import TextLabelModel from '../../models/TextLabelModel';
import TextModel from '../../models/TextModel';

const PanelMenuTextLabels = (props) => {       
    const {handleChange, expanded} = props;    
    const [open, setOpen] = React.useState(false);
    const [text, SetText] = React.useState("");
    const [msgError, setMsgError] = React.useState("");
    const [errorText, setErrorText] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };  
    const handleClose = () => {
        setOpen(false);
    };
      
    const addTextComponent = () => {
        const object = new TextModel({ panel: props.panel, value:text});        
        props.addComponent(object.getConfig());                
        props.addText(object)
        setOpen(false);
    };

    return (
        <ExpansionPanel className="expansion-item" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            className="expansion-sumary"
            >
                <Typography className="expansion-header">Textos / Etiquetas  </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography></Typography>
                <Grid container spacing={1} style={{ width: "100%", margin: "auto" }}>
                <Grid item md={6}>                                                        
                        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                           Texto/Etiqueta
                        </Button>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Añadir texto/etiqueta</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                Ingrese en el siguiente campo el texto ó etiqueta deseada, una vez añadida podrá ubicarla en el lugar que desee
                            </DialogContentText>
                            <TextField
                                autoFocus
                                onChange={(e)=>{
                                    SetText(e.target.value);
                                    if(text.length>150){
                                        setErrorText(true);
                                        setMsgError("No se permiten mas de 150 carácteres");
                                    }else{
                                        setErrorText(false);
                                        setMsgError("");
                                    }
                                }}
                                error={errorText}
                                helperText={msgError}
                                margin="dense"
                                id="Text"
                                label="Texto/Etiqueta"
                                type="text"                                
                                fullWidth 
                                multiline
                                maxRows={4}                                                                                     
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={addTextComponent} color="primary">
                                Añadir
                            </Button>
                            </DialogActions>
                        </Dialog>                                        
                </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default PanelMenuTextLabels;