import DigitalComponentModel from "./DigitalComponentModel";
import shapes from "../helpers/shapes";
import { getAlertIcon } from "./AlertIconModel";

class SwitchKeyModel extends DigitalComponentModel {
  constructor(props) {
    super(props);
    this.type = "switchKey";
    this.label = props.label ? props.label : "On/Off";
    this.selectable = true;
  }

  getSwitchValue = () => {
    return new DigitalComponentModel({
      type: "switchKey",
      value: this.value,
      xPosition: 0,
      yPosition: 119,
    });
  };

  getAlert = (xPosition, yPosition) => {
    if (!this.alerts || this.alerts.length == 0) {
      this.alerts.push({
        severity: "default",
      });
    }
    return getAlertIcon(this.alerts[0], xPosition, yPosition);
  };
  setLoad=(load)=>{
    this.isLoading
    if(load==true){
      this.isLoading= true
    }else{
      this.isLoading=false
    }
    return this.isLoading
  }

  getConfig = (parent = null) => {  
    const Switch = this.getSwitchValue();
    const label = this.getLabel(this.label, 0, 200);
        
    const alert = this.getAlert(120,140)
    
    this.children = [label, alert];

    const shape = { ...shapes[this.type] };
    shape.styles = this.getImage();

    return {
      parent,
      object: this,
      shape,
      children: this.children,
    };
  };
}

export default SwitchKeyModel;
