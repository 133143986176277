import React from "react";
import {
  alertColors,
  validateAll,
  fitValue,
  fitAlerts,
} from "../../helpers/alertUtilsAnalog";

import { comparisonConditions, errors, hasErrors } from "../../helpers/utils";

import { MenuItem, Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../FormTextField";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Brightness1Icon from "@material-ui/icons/Brightness1";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
    margin: theme.spacing(1),
  },
}));

export class AlertsAnalogComponentEditor extends React.Component {
  constructor(props) {
    super(props);
    const iocomponent = props.iocomponent;
    const conditions = comparisonConditions();

    const config = iocomponent.conversionRules.write;
    iocomponent.alertRules = fitAlerts(iocomponent.alertRules, config);

    this.state = { conditions, iocomponent };

    this.validate(iocomponent);
  }

  onCheckboxChange = (e, index) => {
    let { iocomponent } = this.state;
    let { name, checked } = e.target;

    iocomponent.alertRules[index][name] = checked;

    this.validate(iocomponent);

    this.setState(() => ({ iocomponent }));
  };

  onValueChange = (e, index) => {
    const { iocomponent } = this.state;
    const { min, max } = iocomponent.conversionRules.write;
    let { name, value } = e.target;
    if (!!value) {
      value = parseFloat(value);
      iocomponent.alertRules[index][name] = value; //fitValue(value, min, max);
      this.validate(iocomponent);
      this.setState(() => ({ iocomponent }));
    } else {
      iocomponent.alertRules[index][name] = "";
      this.validate(iocomponent);
      this.setState(() => ({ iocomponent }));
    }
  };

  onSelectChange = (e, index) => {
    e.persist();
    const { iocomponent } = this.state;
    const { name, value } = e.target;

    iocomponent.alertRules[index][name] = value;

    this.setState(() => ({ iocomponent }));

    this.validate(iocomponent);
  };

  onMessageChange = (e, index) => {
    const { iocomponent } = this.state;
    let { name, value } = e.target;

    iocomponent.alertRules[index][name] = value;
    this.setState(() => ({ iocomponent }));

    this.validate(iocomponent);
  };

  validate = (component) => {
    const { alertRules, conversionRules } = component;
    const { min, max } = conversionRules.write;

    const isValid =
      validateAll(
        alertRules,
        {
          condition: { required: true },
          value: { required: true, min, max },
          message: { required: true },
        },
        {
          required: "Requerido",
          min: `Min ${min}`,
          max: `Max ${max}`,
        }
      ).filter((alert) => {
        return !alert.isValid;
      }).length === 0;
    this.props.setValidStatus(this.props.index, isValid);
  };

  render = () => {
    const { conditions, iocomponent } = this.state;
    const { min, max, um } = iocomponent.conversionRules.write;
    return (
      <form className={useStyles.form}>
        <div className="pull-right">
          <Chip
            label={`Rango de visualización: ${min} - ${max} (${
              um ? um : "N/A"
            })`}
          />
        </div>
        <Table mt={4} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "1rem" }} align="left"></TableCell>
              <TableCell style={{ width: "1rem" }} align="left">
                Severidad
              </TableCell>
              <TableCell style={{ width: "2rem" }} align="left">
                Activo
              </TableCell>
              <TableCell style={{ width: "12rem" }} align="left">
                Condición
              </TableCell>
              <TableCell style={{ width: "10rem" }} align="left">
                Lectura
              </TableCell>
              <TableCell align="left">
                Mensaje
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {iocomponent.alertRules.map((alert, index) => (
              <TableRow key={index}>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Brightness1Icon
                    style={{ color: alertColors[alert.severity] }}
                  />
                </TableCell>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Chip label={alert.label} />
                </TableCell>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Checkbox
                    name="isActive"
                    checked={Boolean(alert.isActive)}
                    onChange={(e) => this.onCheckboxChange(e, index)}
                  />
                </TableCell>
                <TableCell align="left">
                  <FormTextField
                    required
                    select
                    name="condition"
                    value={alert.condition}
                    disabled={!alert.isActive}
                    onChange={(e) => this.onSelectChange(e, index)}
                    error={hasErrors(alert, "condition")}
                  >
                    {conditions.map(({ key, description }) => (
                      <MenuItem key={key} value={key}>
                        {description}
                      </MenuItem>
                    ))}
                  </FormTextField>
                </TableCell>
                <TableCell align="left">
                  <FormTextField
                    required
                    id="value"
                    name="value"
                    type="number"
                    value={alert.value}
                    disabled={!alert.isActive}
                    onChange={(e) => this.onValueChange(e, index)}
                    error={hasErrors(alert, "value")}
                  />
                </TableCell>
                <TableCell align="left">
                  <FormTextField
                    required
                    id="message"
                    name="message"
                    value={alert.message}
                    disabled={!alert.isActive}
                    onChange={(e) => this.onMessageChange(e, index)}
                    error={hasErrors(alert, "message")}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </form>
    );
  };
}

export default AlertsAnalogComponentEditor;
