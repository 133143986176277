import { makeAuthorizedRequest } from "../../services/apiConnector";

export const VIEWED_ALERT_PENDING = "VIEWED_ALERT_PENDING";
export const VIEWED_ALERT_SUCCESS = "VIEWED_ALERT_SUCCESS";
export const VIEWED_ALERT_ERROR = "VIEWED_ALERT_ERROR";

export const setAlertViewed = (id) => (dispatch) => {
  dispatch(viewedAlertPending());

  return makeAuthorizedRequest(`/alerts/viewed?id=${id}`, {
    method: "GET",
  })
    .then((response) => {
      dispatch(viewedAlertSuccess(response.data.result));
      return response.data.result;
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : "Error desconocido";
      dispatch(viewedAlertError(message));
    });
};

const viewedAlertPending = () => ({
  type: VIEWED_ALERT_PENDING,
});

const viewedAlertSuccess = (alert) => ({
  type: VIEWED_ALERT_SUCCESS,
  alert,
});

const viewedAlertError = (error) => ({
  type: VIEWED_ALERT_ERROR,
  error,
});
