import { makeAuthorizedRequest } from "../../services/apiConnector";

export const REMOVE_COMPANY_PENDING = 'REMOVE_COMPANY_PENDING';
export const REMOVE_COMPANY_SUCCESS = 'REMOVE_COMPANY_SUCCESS';
export const REMOVE_COMPANY_ERROR = 'REMOVE_COMPANY_ERROR';

export const removeCompany = (id) => (dispatch) => {
    dispatch(removeCompanyPending(id));
    return makeAuthorizedRequest(`/companies/${id}`,{method: 'DELETE'})
    .then(response => {
        dispatch(removeCompanySuccess(parseInt(response.data.result.id)));            
        return response.data.result.id;    
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(removeCompanyError(error));
    });
};

const removeCompanyPending = (id) => ({
    type: REMOVE_COMPANY_PENDING,
    id
});

const removeCompanySuccess = (id) => ({
    type: REMOVE_COMPANY_SUCCESS,
    id
});

const removeCompanyError = (error) => ({
    type: REMOVE_COMPANY_ERROR,
    error
});