import * as React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import BoardListTable from './BoardListTable';
import TablePagination from '@material-ui/core/TablePagination';

import { fetchBoards } from '../../actions/boards/fetchActions';
import { fetchModelBoards } from '../../actions/boardModels/fetchAction';

import LoadingSpinner from '../LoadingSpinner';

import FlashMessage from '../FlashMessage';

import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import { ACTION_FETCH } from '../../actions/types';
import { hasRoles } from '../../helpers/auth';
import onViewCredit from './BoardCredit';

export class BoardList extends React.Component {
    onCreateClicked = () => this.props.history.push('/boards/create');

    onLinkClicked = () => this.props.history.push('/boards/link');
    
    onEditClicked = (id) => this.props.history.push(`/boards/edit/${id}`);
    
    viewCreditDialog = (credit) => {
        alert(credit)
    }

    componentDidMount = () => {
        const {perPage, current} = this.props.pagination;
        this.props.fetchBoards({currentPage: current, perPage});
        this.props.fetchModelBoards({currentPage: current, perPage});
        console.log(this.props);
    }

    handleChangePage = (event, currentPage) => {
        const { perPage } = this.props.pagination;
        this.props.fetchBoards({currentPage: (currentPage), perPage});
    }
    
    handleChangeRowsPerPage = (event) => {
        let { fetchBoards } = this.props;
        const perPage = event.target.value;
        fetchBoards({currentPage: 0, perPage});
    };
    
    render = () => {
        // const { boards, fetchBoards, fetchModelBoards } = this.props;
        const { status, boards, message, action,fetchModelBoards } = this.props;
        const { currentPage, perPage, total } = this.props.pagination;

        return (
            <div> 
                
                <div className="page-header">
                    <Typography variant="h6" noWrap className="pull-left">
                        Placas
                    </Typography>
                    {hasRoles(['admin']) && (
                        <div className="pull-right">
                            <Button 
                            id="create-board" 
                            onClick={this.onCreateClicked} 
                            color="primary" 
                            variant="contained"
                            endIcon={<SendIcon />}
                            >
                            Crear nueva
                            </Button>
                        </div>                        
                    )}
                    {hasRoles(['owner']) && (
                        <div className="pull-right">
                            <Button 
                            id="link-board" 
                            onClick={this.onLinkClicked} 
                            color="primary" 
                            variant="contained"
                            endIcon={<SendIcon />}
                            >
                            Agregar nueva placa
                            </Button>
                        </div>                        
                    )}
                    <div className="clearfix"></div>
                </div>
                
                {status === PENDING_STATUS && <LoadingSpinner/>}

                {action === ACTION_FETCH && status === ERROR_STATUS && (
                    <FlashMessage type={status} message={message}/>
                )}

                {(status === SUCCESS_STATUS || status === ERROR_STATUS) && boards.length === 0 && (
                    <p>No hay registros para mostrar.</p>
                )}
    
                { boards.length > 0 && (
                    <div>
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <BoardListTable 
                            onRemoveClicked={this.onRemoveClicked} 
                            onEditClicked={this.onEditClicked} 
                            boards={boards}
                            viewCreditDialog={this.viewCreditDialog}
                        />
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                ) }
            </div>
        );
    }
};



const mapStateToProps = (state) => ({    
    boards: state.boards.data,
    action: state.boards.action,
    status: state.boards.status,
    pagination: state.boards.pagination
});

const fetchBoardsAction = (params) => fetchBoards(params);
const fetchModelBoardsAction = (params) => fetchModelBoards(params);

const mapDispatchToProps = {
    fetchBoards: fetchBoardsAction,
    fetchModelBoards: fetchModelBoardsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardList);