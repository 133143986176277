import React from "react";
import PanelMenu from "./PanelMenu";

import {
  mxGraph,
  mxGraphModel,
  mxKeyHandler,
  mxEvent,
  mxConstants,
} from "mxgraph-js";
import ScrollDialog from "../ScrollDialog";

import { connect } from "react-redux";
import { addComponent } from "../../actions/components/addActions";
import { addText } from "../../actions/labels/addActions";
import { editComponent } from "../../actions/components/editActions";
import { fetchComponents } from "../../actions/components/fetchActions";
import { removeComponent } from "../../actions/components/removeActions";
//import { editBoard } from "../../actions/boards/editActions";
import { editPin } from "../../actions/pins/editActions";
import TextComponentEditor from "../components/TextComponentEditor";

import { getModel } from "../../models/ModelHelper";
import ComponentEditor from "../../components/components/ComponentEditor";
import { hasRoles } from "../../helpers/auth";
import {outputComponent} from "../../actions/components/outputActions"

import ButtonsConfig from "../ButtonsConfig";

class PanelBuilderConsole extends React.Component {
  
  state = {
    elements: [],
    isValid: true,
    disabledButtonAcept: true,
    message: "",
    components: []
  };
  
  model = undefined;
  graph = undefined;
  parent = undefined;
  cell = undefined;
  interval = null;
  


  listener = {
    mouseDown: (sender, evt) => {
      this.cell = evt.getCell();
      evt.consume();
    },
    mouseMove: (sender, evt) => { },
    mouseUp: (sender, evt) => {
      if (!hasRoles(["admin", "owner", "editor"])) return;
      if (this.cell) {
        const iocomponent = this.cell.value;
        if (
          this.cell.geometry.x !== iocomponent.xPosition ||
          this.cell.geometry.y !== iocomponent.yPosition
        ) {
          iocomponent.xPosition = this.cell.geometry.x;
          iocomponent.yPosition = this.cell.geometry.y;
          const { xPosition, yPosition } = iocomponent;
          iocomponent.id &&
            this.props.editComponent(iocomponent.id, {
              xPosition,
              yPosition,
            });
        }
      }
      evt.consume();
    },
  };

  constructor(props) {
    super(props);
    this.containerId = "mxgraph-container";
  }

  componentDidMount() {
    this.container = document.getElementById(this.containerId);
    mxEvent.disableContextMenu(document.body);

    mxConstants.VERTEX_SELECTION_COLOR = "transparent";

    this.model = new mxGraphModel();
    this.graph = new mxGraph(this.container, this.model);
    this.parent = this.graph.getDefaultParent();

    // function to open config of the component with double click
    // this.graph.addListener(mxEvent.DOUBLE_CLICK, this.cellDoubleClickListener);
    
    // function enable switch
    this.graph.addListener(mxEvent.CLICK, this.clickSwitch);
    // this.graph.addListener(mxEvent.isRightMouseButton)
    this.graph.addMouseListener(this.listener);

    var keyHandler = new mxKeyHandler(this.graph);
    keyHandler.bindKey(46, (evt) => {
      if (this.graph.isEnabled()) {
        this.graph.removeCells();
      }
    });

    this.addComponents();

    this.interval = setInterval(() => {
      this.refreshComponents();
      
    }, 3000);
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  addComponents = () => {
    const { panel, fetchComponents } = this.props;
    const { components } = this.state;    
    fetchComponents({ currentPage: 0, perPage: 30, panel: panel.id }).then(
      (results) => {        
        results
          .map((component) => getModel(component))
          .forEach((io) => {
            components.push(io);            
            this.addComponent(io.getConfig());
            this.addBlinking(io);
          });        
        this.setState(() => ({ components }));
      }
    );
  };

  refreshComponents = () => {
    const { panel, fetchComponents } = this.props;
    const { components } = this.state;
    fetchComponents({ currentPage: 0, perPage: 30, panel: panel.id }).then(
      (results) => {
        
        results.forEach((object) => {
          const io = components.find((io) => io.id == object.id);
          
          if(object.type =="switchKey"){
            
            if(io){
              io.setLoad(object.isLoading)
            }
          }

          if (object.type == "selectorthreeposition"){
            if (io) {
              let valone= object.pins[0].value;
              let valtow= object.pins[1].value;
              let pins = {valone , valtow};
              io.updateImage(pins);
              io.setAlerts(object.alerts);
            }
          }else{
            if (io) {
              io.setValue(object.value, this.model);
              io.setAlerts(object.alerts);
            }
          }
        });
        this.graph.refresh();
          components.forEach((io) => {
            this.addBlinking(io);
          });
      }
    );    
  };

  addBlinking = (io) => {
    const icon = io.children.find((child) => child.type === "alertIcon");    
    const state = this.graph.view.getState(icon.cell);
    const imgs = state.shape.node.getElementsByTagName("image");
    imgs[0].setAttribute("class", "blinking");
  };

  addComponent = (config) => {
    
    const { parent, object, children, shape } = config;
    const { xPosition, yPosition } = object;
    const { styles, width, height } = shape;
    let v1 = null;

    if(object.type=="textLabel"){      
      try {
        v1 = this.graph.insertVertex(
          parent ? parent : this.parent,
          null,
          object,
          xPosition,
          yPosition,
          width,
          height,
          styles
        );
        object.cell = v1;
      }catch(e){console.log(e);}
      finally{}
    }else{

    
      this.model.beginUpdate();
      try {
        v1 = this.graph.insertVertex(
          parent ? parent : this.parent,
          null,
          object,
          xPosition,
          yPosition,
          width,
          height,
          styles
        );
        object.cell = v1;
        if (children.length > 0) {
          children.forEach((child) => this.addComponent(child.getConfig(v1)));
        }
      } catch (e) {
        console.error("An error has occurred.", e);
      } finally {
        this.model.endUpdate();
      }
      return v1;
    }
  };

  handleCancel = () => {
    this.setState(() => ({ selected: null, selectedTitle: "" }));
  };
  hideOptions = () => {    
    this.setState(() => ({ text: null, selectedTitleText: "" }));
  };

  handleDeleted = () => {
    
    const iocomponent = this.state.selected;
    const components = this.state;
    if(iocomponent.pins.length < 2){// edit pins component
      // this.props.editPin(iocomponent.pins[0].id);
      
    }else{
      for (let i = 0; iocomponent.pins.length != i ; i++) {
        // this.props.editPin(iocomponent.pins[i].id);
        
      };
    };
    this.cleanAlertRules(iocomponent);
    for (let j = 0; components.components.length > j ; j++) {// delete state the panel component
      if(components.components[j].id == iocomponent.id){
        components.components.splice(j,j);
      }
    };
    this.props.removeComponent(iocomponent.id);    
    this.removeCell(iocomponent.cell);
    this.setState(() => ({ selected: null, selectedTitle: "" }));
  
  };

  handleAccept = () => {    
    if (!this.state.isValid) return;
    const iocomponent = this.state.selected;
    this.cleanAlertRules(iocomponent);
    if (!iocomponent.id) {
      
      this.createComponent(iocomponent);
    } else {
      
      this.updateComponent(iocomponent);
    }
  };

  handleActive = () =>{
    const iocomponent = this.state.selected;
    
    this.activeComponent(iocomponent)
  };

  cleanAlertRules = (iocomponent) => {
    iocomponent.alertRules = iocomponent.alertRules.map((rule) => {
      delete rule.errors;
      delete rule.isValid;
      return rule;
    });
  };

  activeComponent = (iocomponent) => {    
    this.props
      .editComponent(iocomponent.id, {
        isActive:true,             
      })
      .then((edited) => {
        this.refreshPanel(iocomponent);
        this.setState(() => ({ selected: null, selectedTitle: "" }));
      });
  };

  createComponent = (iocomponent) => {
    this.props.addComponent(iocomponent).then((newComponent) => {
      this.removeCell(this.cell);
      if (!newComponent) return;
      iocomponent.id = newComponent.id;
      this.refreshPanel(iocomponent);
      this.setState(() => ({ selected: null, selectedTitle: "" }));
    });
  };
  
  
  updateComponent = (iocomponent) => {
    this.props
      .editComponent(iocomponent.id, {
        board:iocomponent.board.id,
        pins: iocomponent.pins.length > 1 ? iocomponent.pins?.map((pin)=>pin.id) : iocomponent.pins[0].id,
        label: iocomponent.label,
        pinRelate:iocomponent.pinRelate.length > 0 ? iocomponent.pinRelate : [],
        conversionRules: iocomponent.conversionRules,
        alertRules: iocomponent.alertRules,
        smsAlert: iocomponent.smsAlert,
        showElement: iocomponent.showElement,
        showHistory: iocomponent.showHistory,
        alertConfig: iocomponent.alertConfig,
      })
      .then((edited) => {
        this.refreshPanel(iocomponent);
        this.setState(() => ({ selected: null, selectedTitle: "" }));
      });
  };

  refreshPanel = (iocomponent) => {
    this.removeCell(iocomponent.cell);
    this.addComponent(iocomponent.getConfig());
  };

  removeCell = (cells) => {
    this.model.beginUpdate();
    this.graph.removeCellsFromParent([cells]);
    this.model.remove(cells);
    this.model.endUpdate();
  };

 
  clickSwitch = (sender,evt)=>{    
      
    

    if (!hasRoles(["admin", "owner", "editor"])){evt.consume();return;}

    this.cell = evt.getProperty("cell");

    let click = evt.properties.event.which;
        
    if(this.cell && this.cell.value.type=="switch" || this.cell && this.cell.value.type=="switchKey"){
      
      if(click==1 && this.cell.loader !== 1){    
        // this.showContextMenu(this.cell.value);
        
        this.cell.value.loader = 1;
        
        const {outputComponent} = this.props;
        outputComponent({ imei: this.cell.value.board.imei, pin:this.cell.value.pins[0].address, panel:this.cell.value.panel});

      }else if(click==3 && this.cell.loader !== 0 ){        
        // this.showContextMenu(this.cell.value);
        
        this.editText(this.cell.value);
        // return false;  
        // this.graph.setSelectionCell(this.cell);
        // this.selectComponent(this.cell.value);

      }
    }
    else if(click==3  )
    
    {      
      this.editText(this.cell.value);
    }
    evt.consume();
  }
  showOptions = ()=>{
    const selectedOptions = "opciones";
    this.setState(() => ({ options, selectedOptions }));
  }

  cellDoubleClickListener = (sender, evt) => {
    if (!hasRoles(["admin", "owner", "editor"])) {
      evt.consume();
      return;
    }

    this.cell = evt.getProperty("cell"); // cell may be null    
    if(this.cell &&this.cell.value.type=="switch" || this.cell &&this.cell.value.type=="switchKey"){
      // si la celda es un switch, cancelamos la funcion ya que el switch se configura con click derecho del mouse
      return;
    }
    if(this.cell && this.cell.value.type=="textLabel"){
      // send props to textEditorComponent and open modal text editor      
      this.editText(this.cell.value)
    }    
    else if(this.cell && this.cell.value.selectable) {
      this.graph.setSelectionCell(this.cell);
      this.selectComponent(this.cell.value);
    }
    evt.consume();
  };

  editText = (text) => {
    const selectedTitleText = text;
    this.setState(() => ({ text, selectedTitleText }));
  }

  cancelEditText = () => {
    this.setState(() => ({ text: null, selectedTitleText: "" }));
  };
  
  addText = (e) => {    
    const {panel}= this.props;
    const label ={
      ...e,
      panel:panel.id,
      company:panel.company
    }
    this.props.addText(label)    
  }

  selectComponent = (selected) => {
    const selectedTitle = "Configuración de componente";
    this.setState(() => ({ selected, selectedTitle }));
  };

  render() {
    const { panel } = this.props;
    const { selected, selectedTitle } = this.state;
    const {text, selectedTitleText}= this.state;    
    const {options, selectedOptions}= this.state;    
    return (
      <div style={{ height: "100%" }}>
        <div id={this.containerId} style={{ width: "100%", height: "100%" }} />
        {hasRoles(["admin", "owner", "editor"]) && (
          <PanelMenu panel={panel} addComponent={this.addComponent} addText={this.addText} />
        )}
        {this.state.selected && (
          <ScrollDialog
            handleAccept={this.handleAccept}
            handleCancel={this.handleCancel}
            handleDeleted={this.handleDeleted}
            handleActive={this.handleActive}
            open={!!selected}
            title={selectedTitle}
            dividers={true}
            isActive={this.isActive}
          >
            {["tank", "chronometer", "dchronometer", "pump","selectortowposition","selectorthreeposition","generic", "switch", "switchKey",'textLabel']
            .indexOf(selected.type) !== -1 && <ComponentEditor iocomponent={selected} />}
          </ScrollDialog>
        )}

        {this.state.text &&(
          <ButtonsConfig 
          hideOptions={this.hideOptions}
          test = {this.test}
          open={!!text}
          borrar={this.removeCell}
          data={selectedTitleText}
          selectComponent={this.selectComponent}
          >
            
          </ButtonsConfig>
        )}

        

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    status: state.panel,
    boards: state.components
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchComponents: (params) => dispatch(fetchComponents(params)),
  addComponent: (component) => dispatch(addComponent(component)),
  removeComponent: (component) => dispatch(removeComponent(component)),
  editPin: (id) => dispatch(editPin(id)),
  editComponent: (component, updates) => {return dispatch(editComponent(component, updates));},
  addText: (text) => dispatch(addText(text)),
  // activeComponent:(component, isActive)=> {return dispatch(activeComponent(component, isActive));},
  outputComponent: (params)=>dispatch(outputComponent(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelBuilderConsole);
