import { FETCH_COMPANIES_ERROR, FETCH_COMPANIES_SUCCESS, FETCH_COMPANIES_PENDING, LIST_COMPANIES_ERROR, LIST_COMPANIES_PENDING, LIST_COMPANIES_SUCCESS } from '../actions/companies/fetchActions';
import { REMOVE_COMPANY_ERROR, REMOVE_COMPANY_SUCCESS, REMOVE_COMPANY_PENDING } from '../actions/companies/removeActions';
import { EDIT_COMPANY_ERROR, EDIT_COMPANY_SUCCESS, EDIT_COMPANY_PENDING } from '../actions/companies/editActions';
import { ADD_COMPANY_ERROR, ADD_COMPANY_SUCCESS, ADD_COMPANY_PENDING } from '../actions/companies/addActions';

import { ACTION_ADD, ACTION_EDIT, ACTION_FETCH, ACTION_REMOVE } from '../actions/types';
import { PENDING_STATUS, SUCCESS_STATUS, ERROR_STATUS } from '../actions/status';

const successMessage = 'Los datos se guardaron con éxito.';

const defaultState = {
    status: null,
    message: null,
    action: null,
    data: [],
    list: [],
    pagination: {
        current: 0,
        perPage: 10,
        total: 0
    }
};

export default (state = defaultState, action) => {
    switch(action.type) {
        
        case FETCH_COMPANIES_PENDING: 
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_FETCH,
                pagination: {
                    ...state.pagination, 
                    perPage: action.perPage,
                    currentPage: action.currentPage
                }
            };
        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,    
                status: SUCCESS_STATUS,        
                data: action.data,
                pagination: {
                    ...state.pagination,
                    currentPage: action.currentPage,
                    perPage: action.perPage,
                    total: action.total
                }
            };
        case FETCH_COMPANIES_ERROR:
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
            };      
        
        case ADD_COMPANY_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                error:null,
                action: ACTION_ADD
            };
        case ADD_COMPANY_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: successMessage,
                data: [
                    ...state.data, 
                    {...action.company}
                ]
            };            
        case ADD_COMPANY_ERROR:             
            return {
                ...state,
                status: ERROR_STATUS,                
                error: action.error
            };         
    
        case EDIT_COMPANY_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_EDIT
            };
        case EDIT_COMPANY_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: successMessage,
                data: state.data.map((object) => {                    
                    if(object.id === action.id) {
                        return {
                        ...object,
                        ...action.updates
                        };
                    }
                    else {
                        return object;
                    }
                })                
            };            
        case EDIT_COMPANY_ERROR:            
            return {
                ...state,
                status: PENDING_STATUS,
                message: action.error
            };      

        case REMOVE_COMPANY_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_REMOVE
            };
        case REMOVE_COMPANY_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                message: 'Registro borrado exitosamente.',
                data: state.data.filter(({id}) => id !== action.id)
            };            
        case REMOVE_COMPANY_ERROR:            
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
            };              
        
        case LIST_COMPANIES_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_FETCH
            }; 
        case LIST_COMPANIES_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                list: action.data
            }; 
        case LIST_COMPANIES_ERROR: 
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
        }
        
        default:
            return state;
    };
};