import {
  FETCH_PANELS_ERROR,
  FETCH_PANELS_SUCCESS,
  FETCH_PANELS_PENDING,
  LIST_PANELS_ERROR,
  LIST_PANELS_PENDING,
  LIST_PANELS_SUCCESS,
} from "../actions/panels/fetchActions";
import {
  REMOVE_PANEL_ERROR,
  REMOVE_PANEL_SUCCESS,
  REMOVE_PANEL_PENDING,
} from "../actions/panels/removeActions";
import {
  EDIT_PANEL_ERROR,
  EDIT_PANEL_SUCCESS,
  EDIT_PANEL_PENDING,
} from "../actions/panels/editActions";
import {
  ADD_PANEL_ERROR,
  ADD_PANEL_SUCCESS,
  ADD_PANEL_PENDING,
} from "../actions/panels/addActions";
import {
  ACTION_ADD,
  ACTION_EDIT,
  ACTION_FETCH,
  ACTION_REMOVE,
} from "../actions/types";
import {
  PENDING_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../actions/status";

const successMessage = "Los datos se guardaron con éxito.";

const defaultState = {
  status: null,
  message: null,
  action: null,
  data: [],
  list: [],
  pagination: {
    current: 0,
    perPage: 10,
    total: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PANELS_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_FETCH,
        pagination: {
          ...state.pagination,
          perPage: action.perPage,
          currentPage: action.currentPage,
        },
      };
    case FETCH_PANELS_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        data: action.data,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
          perPage: action.perPage,
          total: action.total,
        },
      };
    case FETCH_PANELS_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };

    case LIST_PANELS_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_FETCH,
      };
    case LIST_PANELS_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        list: action.data,
      };
    case LIST_PANELS_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };

    case ADD_PANEL_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_ADD,
      };
    case ADD_PANEL_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        message: successMessage,
        data: [...state.data, { ...action.panel }],
      };
    case ADD_PANEL_ERROR:
      return {
        ...state,
        status: PENDING_STATUS,
        message: action.error,
      };

    case EDIT_PANEL_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_EDIT,
      };
    case EDIT_PANEL_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        message: successMessage,
        data: state.data.map((object) => {
          if (object.id === action.id) {
            return {
              ...object,
              ...action.updates,
            };
          } else {
            return object;
          }
        }),
      };
    case EDIT_PANEL_ERROR:
      return {
        ...state,
        status: PENDING_STATUS,
        message: action.error,
      };

    case REMOVE_PANEL_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
        action: ACTION_REMOVE,
      };
    case REMOVE_PANEL_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        message: "Registro borrado exitosamente.",
        data: state.data.filter(({ id }) => id !== action.id),
      };
    case REMOVE_PANEL_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };

    default:
      return state;
  }
};
