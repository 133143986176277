export default class Validator {
  _valid = true;
  _rules = {};
  _errors = {};
  _form = {};

  _messages = {
    required: "Este dato es obligatorio",
    email: "Debe ser un correo válido",
    numeric: "Solo se permiten números",
    min: "Debe ser mayor o igual a {%cond%}.",
    max: "Debe ser menor o igual a {%cond%}.",
    minLength: "Debe tener al menos {%cond%} caracters",
    maxLength: "Se permiten hasta {%cond%} caracteres",
    sameAs: "La confirmación no es correcta",
  };

  constructor(rules, messages = null) {
    this.setRules(rules);
    if (messages) {
      this._messages = {
        ...this._messages,
        ...messages,
      };
    }
  }

  addRule(key, conditions) {
    this._rules[key] = conditions;
    this._errors[key] = [];
  }

  removeRule(key) {
    delete this._rules[key];
    delete this._errors[key];
  }

  setRules(rules) {
    this._rules = { ...rules };
    Object.keys(this._rules).forEach((rule) => {
      this._errors[rule] = [];
    });
  }

  hasErrors(input) {
    return this._errors[input].length > 0;
  }

  errors(input) {
    return this._errors[input];
  }

  isValid() {
    return this._valid;
  }

  prepare(form) {
    Object.keys(form).forEach((input) => {
      if (typeof form[input] === "undefined" || form[input] === null) {
        form[input] = "";
      }
    });
  }

  validate(form) {
      
    this._form = form;

    this.prepare(form);
    this._valid = true;

    Object.keys(this._rules).forEach((input) => {
      this._errors[input] = [];
      Object.keys(this._rules[input]).forEach((rule) => {
        this.doValidation(form, input, rule);
        this._valid = this._valid && !this.hasErrors(input);
      });
    });
    return this._valid;
  }

  dispatch(fn, args) {
    return this[fn].apply(this, args || []);
  }

  doValidation(form, input, rule) {
    let value =
      form[input] && isNaN(form[input]) ? form[input].trim() : form[input];
    let message = this.getMessage(input, rule);
    let condition = this._rules[input][rule];
    if (!this.dispatch(rule, [value, condition])) {
      this._errors[input].push(message);
    }
  }

  getMessage(input, rule) {
    if (this._rules[input][rule].hasOwnProperty("message")) {
      return this._rules[input][rule].message;
    } else {
      let limit = this._rules[input][rule];
      let msg = this._messages[rule].replace("{%cond%}", limit);
      return msg;
    }
  }

  min(value, min) {
    return value >= min;
  }

  max(value, max) {
    return value <= max;
  }

  sameAs(value, confirmed) {
    return value === this._form[confirmed];
  }

  required(value, foo = true) {
    return typeof value !== "undefined" && value !== "";
  }

  minLength(value, min) {
    return String(value).length >= min;
  }

  maxLength(value, max) {
    return String(value).length <= max;
  }

  numeric(value, foo = true) {
    return !isNaN(1 * value);
  }

  email(value, foo = true) {
    let regExpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExpEmail.test(String(value));
  }
}
