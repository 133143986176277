import React ,{useState} from 'react';
import Typography from '@material-ui/core/Typography';
import PanelBuilderConsole from './PanelBuilderConsole';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import DialogComponent from '../dialog/Dialog';



const PanelBuilder = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const handleOpenDialog = () => {
        
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const { history, panel } = props;

    const onBackClicked = () => {
        history.push("/panels");
    };

    if(!panel) {
        props.history.push('/panels');
        return (
            <div></div>
        );
    }
    

    return (
        <div style={{height:'100%'}}>
            <div className="page-header">
                <Typography variant="h6" noWrap className="pull-left">
                    Programación de tablero
                </Typography>
                
                <div className="pull-right">
                    <Button id="back" style={{marginRight:'45px'}} onClick={onBackClicked} color="default" variant="contained">Cerrar</Button>
                </div>
                <div className="clearfix"></div>
            </div>

            
            {panel.boards.length > 0 &&             
                <Button color="primary" variant="contained" onClick={handleOpenDialog}>
                    Última conexión
                </Button>
            }
          
            
            
                <DialogComponent
                    isOpen={dialogOpen}
                    onClose={handleCloseDialog}
                    title="Última conexión"                
                    panel={panel}
                />
               
            


            <PanelBuilderConsole panel={props.panel}/>   
        </div>
    );
}

const mapStateToProps = (state, props) => {
    const panel = state.panels.data.find(
      (panel) => panel.id === parseInt(props.match.params.id)
    );    
    return {
      panel,
    };
};
  
export default connect(mapStateToProps, null)(PanelBuilder);