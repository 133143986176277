import { applyMiddleware, createStore, combineReducers, compose } from "redux";

import authReducer from "../reducers/auth";
import passwordReducer from "../reducers/password";
import usersReducer from "../reducers/users";
import companiesReducer from "../reducers/companies";
import rolesReducer from "../reducers/roles";
import boardsReducer from "../reducers/boards";
import panelsReducer from "../reducers/panels";
import alertsReducer from "../reducers/alerts";
import modelsReducer from "../reducers/models";
import categoriesReducer from "../reducers/categories";
import historyReducer from "../reducers/history";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const middlewares = [thunk];

if (process.env.NODE_ENV !== "development") {
  const logger = createLogger({ level: "log" | "error" });
  middlewares.push(logger);
}

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // other store enhancers if any
);

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      password: passwordReducer,
      users: usersReducer,
      companies: companiesReducer,
      roles: rolesReducer,
      boards: boardsReducer,
      panels: panelsReducer,
      alerts: alertsReducer,
      models: modelsReducer,
      categories: categoriesReducer,
      history: historyReducer,
    }),
    enhancer
  );

  return store;
};
