import React from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WidgetsIcon from '@material-ui/icons/Widgets';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import BusinessIcon from '@material-ui/icons/Business';
import MemoryIcon from '@material-ui/icons/Memory';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MapIcon from '@material-ui/icons/Map';
import { hasRoles } from '../helpers/auth';

const AppMenu = (props) => {
    
    const { classes, open } = props;
    const excludePaths = ['/signup','/login','/password/forgot','/password/reset'];
    const location = useLocation();

    if (excludePaths.indexOf(location.pathname.trim()) !== -1 || !props.isAuthenticated) return null;
    
    return (
        <Drawer
        className={classes.drawer + ' app-menu'}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.drawerPaper, }}>
            
            <div className={classes.drawerHeader + ' app-menu__header'}>
                <IconButton onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            
            <Divider />
            
            <List>
                {false && (
                    <NavLink className="menu-link" activeClassName="is-active" to="/" exact={true}>
                        <ListItem button className="menu-link__button">
                            <ListItemIcon className="menu-link__icon">
                                <InsertChartIcon />
                            </ListItemIcon>
                            <ListItemText className="menu-link__text" primary="Dashboard" />                    
                        </ListItem>
                    </NavLink>
                )}
                <NavLink className="menu-link" activeClassName="is-active" to="/panels">
                    <ListItem button className="menu-link__button">                
                        <ListItemIcon className="menu-link__icon">
                            <WidgetsIcon />
                        </ListItemIcon>
                        <ListItemText className="menu-link__text" primary="Tableros" />
                    </ListItem>            
                </NavLink>
                {hasRoles(['admin']) && (
                    <NavLink className="menu-link" activeClassName="is-active" to="/companies">
                        <ListItem button className="menu-link__button">                
                            <ListItemIcon className="menu-link__icon">
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText className="menu-link__text" primary="Empresas" />                
                        </ListItem>
                    </NavLink>
                )}
                {hasRoles(['admin','owner']) && (
                    <NavLink className="menu-link" activeClassName="is-active" to="/users">
                        <ListItem button className="menu-link__button">                
                            <ListItemIcon className="menu-link__icon">
                                <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText className="menu-link__text" primary="Usuarios" />                
                        </ListItem>
                    </NavLink>
                )}                                  
                {hasRoles(['admin','owner']) && (
                    <NavLink className="menu-link" activeClassName="is-active" to="/boards">
                        <ListItem button className="menu-link__button">                
                            <ListItemIcon className="menu-link__icon">
                                <MemoryIcon/>
                            </ListItemIcon>
                            <ListItemText className="menu-link__text" primary="Placas" />                
                        </ListItem>
                    </NavLink>
                )}                                  
                {hasRoles(['admin']) && (
                    <NavLink className="menu-link" activeClassName="is-active" to="/boardModels">
                        <ListItem button className="menu-link__button">                
                            <ListItemIcon className="menu-link__icon">
                                <NoteAddIcon/>
                            </ListItemIcon>
                            <ListItemText className="menu-link__text" primary="Modelos" />                
                        </ListItem>
                    </NavLink>
                )}      
                <NavLink className="menu-link" activeClassName="is-active" to="/locations">
                    <ListItem button className="menu-link__button">                
                        <ListItemIcon className="menu-link__icon">
                            <MapIcon />
                        </ListItemIcon>
                        <ListItemText className="menu-link__text" primary="Ubicaciones" />
                    </ListItem>            
                </NavLink>                            
            </List>        
        
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
 isAuthenticated: state.auth.authenticated
});

export default connect(mapStateToProps)(AppMenu);