import React from "react";
import Grid from "@material-ui/core/Grid";
import FormTextField from "../FormTextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { hasRoles } from "../../helpers/auth";

const ModelPins = (props) => {

  const { board, onPineTypeChange, pinTypes } = props;

  return (
    board.pins.length > 0 && (
      <div>
        <Typography variant="h6" noWrap style={{ marginTop: "30px" }}>
          Pines en la placa
        </Typography>
        <Grid container spacing={2}>
          {board.pins.map((pin, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FormTextField
                select
                label={
                  index === 16 ? "IA1" :
                  index === 17 ? "IA2" :
                  index >= 18 ? "Q" + (index - 17) :
                  "Pin #" + (index + 1)
                }
                name={"pin_" + (index + 1)}
                value={pin.type}
                disabled={!hasRoles(['admin'])}
                onChange={onPineTypeChange}
              >
                {pinTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  );
};

export default ModelPins;
