import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Paper, CircularProgress, Link } from '@material-ui/core';
import FlashMessage from '../../components/FlashMessage';

import { login, logout } from '../../actions/auth/loginActions';
import { ERROR_STATUS } from '../../actions/status';
import FormTextField from '../FormTextField';


import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { isAuthenticated } from '../../helpers/auth';

export class LoginPage extends React.Component {

    constructor(props) {
        super(props);
       

        this.state = {
            email: '',
            password: '',
            submitted: false,
            showPassword: false
        };
        
        
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    componentDidMount() {
        // Verificar si hay un usuario almacenado al cargar el componente        
        if (isAuthenticated()) {
            this.props.history.push('/');
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(() => ({ [name]: value }));
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ submitted: true });

        const { email, password } = this.state;

        if (email && password) {
            this.props.login({ email, password })
            .then(token => {
                if(!!token) {
                    this.props.history.push('/');
                }
            });
        }
    }

    handleClickShowPassword() {
        this.setState((prevState) => ({
          showPassword: !prevState.showPassword,
        }));
    }

    render() {
        const { showPassword } = this.state;
        const { inProgress, error, action } = this.props;
        const { email, password } = this.state;

        return (
            <form id="login-form" onSubmit={this.handleSubmit}>
                {!inProgress && action === 'login' && error && (
                    <FlashMessage message={error} type={ERROR_STATUS}/>
                )}            
                <Grid container align="center" justify="center" direction="row">
                    <Grid item xs></Grid>
                    <Grid item container xs={12} md={4}>
                        <Paper style={{width:'100%', padding:'40px'}}>
                            <img 
                                alt="logo" 
                                className="logo" 
                                title="logo" 
                                src="/images/logo.png"
                                style={{width:'80%',maxWidth:'240px'}}
                            />
                            <Grid item xs={12} md={12}>
                                <FormTextField
                                    label="Usuario"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormTextField
                                    type={showPassword ? 'text' : 'password'}
                                    label="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={this.handleChange}
                                    InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={this.handleClickShowPassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    ),
                                    }}
                                />
                            </Grid>
                           
                            <Grid item container xs={12} md={12}>
                                <Grid item xs={12} md={6}>
                                    <div style={{ marginTop: '2.1rem', textAlign: 'left' }}>
                                        {this.props.inProgress ? (
                                            <Button color="primary" variant="contained">
                                                <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px'}} />
                                                Ingresando...
                                            </Button>
                                        ) : (
                                            <Button color="primary" type="submit" variant="contained">
                                                Ingresar
                                            </Button>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{ marginTop: '2.7rem', textAlign: 'right' }}>
                                        <Link href="/password/forgot" color="secondary">Olvidé mi contraseña</Link>                            
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{ marginTop: '1.9rem'}}>
                                    <span>¿Usted es un nuevo cliente de Hidra?</span><br/>
                                    <span>
                                        Regístrese haciendo click <Link href="/signup" color="secondary">aquí</Link>
                                    </span>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { inProgress, error, action } = state.auth;
    return {
        inProgress,
        error,
        action
    };
};

const mapDispatchToProps = (dispatch) => ({
    login: (params) => dispatch(login(params)),
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);