import React from "react";
import App from "../components/App";
import { Router, Route, Switch } from "react-router-dom";

import DashboardPage from "../components/DashboardPage";
import NotFoundPage from "../components/NotFoundPage";
import PanelsList from "../components/panels/PanelsList";

import UserList from "../components/users/UserList";
import UserCreator from "../components/users/UserCreator";
import UserEditor from "../components/users/UserEditor";

import CompanyList from "../components/companies/CompanyList";
import CompanyCreator from "../components/companies/CompanyCreator";
import CompanyEditor from "../components/companies/CompanyEditor";

import BoardList from "../components/boards/BoardList";

import LoginPage from "../components/auth/LoginPage";
import SignupPage from "../components/auth/SignupPage";

import ForgotPassword from "../components/password/ForgotPassword";
import ResetPassword from "../components/password/ResetPassword";

import PrivateRoute from "../components/PrivateRoute";

import history from "./history";
import BoardCreator from "../components/boards/BoardCreator";
import BoardEditor from "../components/boards/BoardEditor";
import BoardLink from "../components/boards/BoardLink";

import PanelCreator from "../components/panels/PanelCreator";
import PanelEditor from "../components/panels/PanelEditor";
import PanelBuilder from "../components/panels/PanelBuilder";
import PanelViewer from "../components/panels/PanelViewer";
import PanelHistory from "../components/panels/PanelHistory";

import ModelList from "../components/boardModels/ModelList";
import ModelCreator from "../components/boardModels/ModelCreator";
import MapView from "../components/map/MapView";

import ApiStatus from "../components/apiStatus/apiStatus";

const AppRouter = () => (
  <Router history={history}>
    <App>
      <Switch>
        <PrivateRoute path="/" component={DashboardPage} exact={true} />
        <PrivateRoute path="/panels" component={PanelsList} exact={true} />
        <PrivateRoute path="/panels/create" component={PanelCreator} />
        <PrivateRoute path="/panels/edit/:id" component={PanelEditor} />
        <PrivateRoute path="/panels/build/:id" component={PanelBuilder} />
        <PrivateRoute path="/panels/view/:id" component={PanelViewer} />
        <PrivateRoute path="/panels/history/:id" component={PanelHistory} />

        <PrivateRoute path="/users" component={UserList} exact={true} />
        <PrivateRoute path="/users/create" component={UserCreator} />
        <PrivateRoute path="/users/edit/:id" component={UserEditor} />

        <PrivateRoute path="/companies" component={CompanyList} exact={true} />
        <PrivateRoute path="/companies/create" component={CompanyCreator} />
        <PrivateRoute path="/companies/edit/:id" component={CompanyEditor} />

        <PrivateRoute path="/boards" component={BoardList} exact={true} />
        <PrivateRoute
          path="/boards/create"
          component={BoardCreator}
          exact={true}
        />
        <PrivateRoute path="/boards/link" component={BoardLink} exact={true} />
        <PrivateRoute
          path="/boards/edit/:id"
          component={BoardEditor}
          exact={true}
        />
        <PrivateRoute path="/boardModels" component={ModelList} exact={true} />
        <PrivateRoute
          path="/model/create"
          component={ModelCreator}
          exact={true}
        />
        <PrivateRoute path="/locations" component={MapView} exact={true} />

        <Route path="/login" component={LoginPage} exact={true} />
        <Route path="/signup" component={SignupPage} exact={true} />
        <Route
          path="/password/forgot"
          component={ForgotPassword}
          exact={true}
        />
        <Route
          path="/password/reset/:token"
          component={ResetPassword}
          exact={true}
        />
        {/* <Route path="/status" component={ApiStatus} exact={true} /> */}

        <Route component={NotFoundPage} />
      </Switch>
    </App>
  </Router>
);

export default AppRouter;
