import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_ALERTS_PENDING = "FETCH_ALERTS_PENDING";
export const FETCH_ALERTS_SUCCESS = "FETCH_ALERTS_SUCCESS";
export const FETCH_ALERTS_ERROR = "FETCH_ALERTS_ERROR";

export const fetchAlerts = () => (dispatch) => {
  dispatch(fetchAlertsPending());
  return makeAuthorizedRequest(`/alerts/findUnviewed`)
    .then((response) => {
      dispatch(fetchAlertsSuccess(response.data));
      return response.data.result;
    })
    .catch((error) => {
      const message =
        typeof error.response !== "undefined"
          ? error.response.data.message
          : "Error desconocido";
      dispatch(fetchAlertsError(message));
    });
};

const fetchAlertsPending = () => ({
  type: FETCH_ALERTS_PENDING,
});

const fetchAlertsSuccess = (data) => ({
  type: FETCH_ALERTS_SUCCESS,
  data: data.result,
});

const fetchAlertsError = (error) => ({
  type: FETCH_ALERTS_ERROR,
  error,
});
