import React from "react";
import {
  alertColors,
  validateAll,
} from "../../helpers/alertUtilsDigital";

import {comparisonConditions, comparisonType, hasErrors } from "../../helpers/utils";

import { MenuItem, Grid, Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../FormTextField";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Pinterest } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
    margin: theme.spacing(1),
  },
}));

export class AlertsDigitalComponentEditor extends React.Component {
  editMode = false;
  constructor(props) {
    super(props);

    const iocomponent = props.iocomponent;

    const conditions = comparisonType();

    this.editMode = iocomponent.id !== null;

    const config = iocomponent.conversionRules.rules;

    this.state = { conditions, iocomponent };

    this.validate(iocomponent);
  }

  onCheckboxChange = (e, index) => {
    const { iocomponent } = this.state;
    const { name, checked } = e.target;

    iocomponent.alertRules[index][name] = checked;
    
    this.validate(iocomponent);

    this.setState(() => ({ iocomponent }));
  };

  onSelectPinChangeAlert = (e, index) => {
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    const { name, value } = e.target;

    const pin = iocomponent.board.pins.find((pin) => (pin.id === value));
    iocomponent.pins[1]= pin;

    if (iocomponent.pins[0].id != value){
      iocomponent.alertRules[index][name] = 0;
    }else{
      iocomponent.alertRules[index][name] = '';
    }
    this.validate(iocomponent); 
    this.setState(() => ({ iocomponent }));
  };

  onSelectChange = (e, index) => {
    e.persist();
    const { iocomponent } = this.state;
    const { name, value } = e.target;
        
    iocomponent.alertRules[index][name] = value;

    this.validate(iocomponent); 
    this.setState(() => ({ iocomponent }));
  };

  onMessageChange = (e, index) => {
    let { iocomponent } = this.state;
    let { name, value } = e.target;

    iocomponent.alertRules[index][name] = value;

    this.setState(() => ({ iocomponent }));

    this.validate(iocomponent);
  };

  validate = (component) => {
    const { alertRules, conversionRules } = component;
    const type = conversionRules.type; 
    const isValid =
      validateAll(
        alertRules,
        {
          value: { required: true },
          condition: { required: true },
          message: { required: true },
        }
      ).filter((alert) => {
        return !alert.isValid;
      }).length === 0;
    this.props.setValidStatus(this.props.index, isValid);
  };


  render = () => {
    const { conditions,  iocomponent } = this.state;

    const { type } = iocomponent.conversionRules;
    const pins = iocomponent.board
      ? iocomponent.board.pins
      : [{ id: null, description: "Seleccionar la placa" }];
    return (
      <form className={useStyles.form}>
        <div className="pull-right">
          <Chip label={`Estado de pin de dato: (${type ? type : "N/A"})`}/>
        </div>
        <Table mt={4} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "1rem" }} align="left"></TableCell>
              <TableCell style={{ width: "1rem" }} align="left">
                Severidad
              </TableCell>
              <TableCell style={{ width: "2 rem" }} align="left">
                Activo
              </TableCell>
              <TableCell style={{ width: "5 rem" }} align="left">
                Pin de Alerta
              </TableCell>
              <TableCell style={{ width: "5 rem" }} align="left">
                Tipo de Entrada
              </TableCell>
              <TableCell align="left">
                Mensaje
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {iocomponent.alertRules.map((alert, index) => (
              <TableRow key={index}>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Brightness1Icon
                    style={{ color: alertColors[alert.severity] }}
                  />  
                </TableCell>
                
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Chip label={alert.label} />
                </TableCell>

                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Checkbox
                    name="isActive"
                    checked={Boolean(alert.isActive)}
                    onChange={(e) => this.onCheckboxChange(e, index)}
                  />
                </TableCell>  
                  <TableCell align="left" >
                    {this.editMode ? (                     
                        <FormTextField
                          id="value"
                          name="value"
                          value={ (iocomponent.pins.length > 1 && iocomponent.pins[1].description) || "" }
                          disabled={ true }               
                        />                   
                  ) : (                
                        <FormTextField
                          id="value"
                          name="value"
                          select
                          value={
                            (iocomponent.pins.length > 1 && iocomponent.pins[1].id) || ""                          
                          }
                          disabled={!alert.isActive  || this.editMode }
                          onChange={(e) => this.onSelectPinChangeAlert(e, index)}
                          error={hasErrors(alert, "value")}
                        >
                          {pins.map((pin, index) => { 
                            if (pins[0].id == null){
                              return (
                                <MenuItem key={index} value={pin.id}>
                                  {pin.description}
                                </MenuItem>
                              );
                            }
                            if(iocomponent.pins.length > 0){  
                              if(iocomponent.pins[0].id != pin.id){
                                return (
                                  <MenuItem key={index} value={pin.id}>
                                    {pin.description}
                                  </MenuItem>
                                );
                              }
                            };
                    })}
                      </FormTextField>                                
                  )}

                </TableCell>
                <TableCell align="left">
                  <FormTextField
                    required
                    select
                    name="condition"
                    value={alert.condition}
                    disabled={!alert.isActive}
                    onChange={(e) => this.onSelectChange(e, index)}
                    error={hasErrors(alert, "condition")}
                  >
                  {conditions.map(({ key, description }) => (
                      <MenuItem key={key} value={key}>
                        {description}
                      </MenuItem>
                  ))}
                  </FormTextField>
                </TableCell>
                <TableCell align="left">
                  <FormTextField
                    required
                    id="message"
                    name="message"
                    value={alert.message}
                    disabled={!alert.isActive}
                    onChange={(e) => this.onMessageChange(e, index)}
                    error={hasErrors(alert, "message")}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </form>
    );
  };
}
export default AlertsDigitalComponentEditor;
