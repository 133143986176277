import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter';
import 'normalize.css/normalize.css';
import './css/styles.scss'
import storeConfig from './store/storeConfig';

const store = storeConfig();

ReactDOM.render(
    <Provider store={store}>
        <AppRouter/>
    </Provider>
, document.getElementById('root'))