import { Grid, MenuItem } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import FormTextField from "../FormTextField";
import { hasErrors } from "../../helpers/utils";
import { useState, useEffect } from "react";
import Validator from "../../services/validator";

const DigitalConversionRules = (props) => {
  const [validation, setValidation] = useState({ errors: [] });
  const [conversionRules, setConversionRules] = useState(props.conversionRules);
  const [validated, setValidated] = useState(false);

  const validate = (convRules) => {
    setValidated(true);
    const validator = new Validator({
      type: { required: true },
    });
    validator.validate({
      type: convRules.type,
    });

    setValidation({ errors: validator._errors });

    return validator.isValid();
  };


  const onSelectUnitChange = (e) => {
    const { name, value } = e.target;
    updateConversionRules(name, value);
    const isValid = validate(conversionRules);
    props.updateConversionRules(conversionRules, isValid);
  };

  const updateConversionRules = (name, value) => {
    const parts = name.split("_");
    conversionRules.type = value;
    if (conversionRules.type == 'Normal Abierto'){
      conversionRules.rules[0].read = 0;
      conversionRules.rules[0].write = 0;
      conversionRules.rules[1].read = 1;
      conversionRules.rules[1].write = 1;
    }else if(conversionRules.type == 'Normal Cerrado'){
      conversionRules.rules[0].read = 0;
      conversionRules.rules[0].write = 1;
      conversionRules.rules[1].read = 1;
      conversionRules.rules[1].write = 0;
    }
    setConversionRules(conversionRules);
  };

  useEffect(() => {
    if (!validated) {
      const isValid = validate(conversionRules);
      props.updateConversionRules(conversionRules, isValid);
    }
  }, []);

  return (

    <Grid container item spacing={2}>
      <Grid item md={2}>
        <FormTextField
          required
          label="Estado de Entrada"
          select
          name="type"
          value={conversionRules.type ? conversionRules.type : ""}
          onChange={onSelectUnitChange}
          error={hasErrors(validation, "type")}
        >
          {props.typeUnits.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </FormTextField>
      </Grid>
    </Grid>
  );
};

export default DigitalConversionRules;
