import { getFormData } from '../../helpers/utils';
import { makeAuthorizedRequest } from '../../services/apiConnector';

export const ADD_USER_PENDING = 'ADD_USER_PENDING';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const addUser = (user) => (dispatch) => {
    dispatch(addUserPending());
    
    const data = getFormData(user);

    return makeAuthorizedRequest(`/users`,{method: 'post', data})
    .then(response => {
        dispatch(addUserSuccess(response.data.result));            
        return response.data.result;
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido' 
        dispatch(addUserError(message));
        return null;
    });
};

const addUserPending = () => ({
    type: ADD_USER_PENDING
});

const addUserSuccess = (user) => ({
    type: ADD_USER_SUCCESS,
    user
});

const addUserError = (error) => ({
    type: ADD_USER_ERROR,
    error
});