import * as React from "react";
import { connect } from "react-redux";
import { fetchBoards } from "../../actions/boards/fetchActions";
import Button from "@material-ui/core/Button";
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  LayersControl,
  FeatureGroup,
  LayerGroup,
  Overlay,
  Circle,
  Rectangle,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { iconUbication, iconFilter } from "./icon";
import { fetchPanels } from "../../actions/panels/fetchActions";
import { fetchCategories } from "../../actions/categories/fetchActions";

import LoadingSpinner from "../LoadingSpinner";
import FlashMessage from "../FlashMessage";
import {
  PENDING_STATUS,
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../actions/status";
import { ACTION_FETCH } from "../../actions/types";

export class MapView extends React.Component {
  componentDidMount = () => {
    const { perPage, current } = this.props.pagination;
    
    this.props.fetchCategories({ currentPage: current, perPage });

    this.props.fetchPanels({ currentPage: current, perPage });
    
  };

  handlePopupClick = (panelId) => {
    this.props.history.push(`/panels/view/${panelId}`);
  };

  render = () => {
    const { panels, categories ,status} = this.props;
    
    return (
      <div>
        {status === PENDING_STATUS ? 
        
          (<LoadingSpinner />):

          <MapContainer
            center={[-27.369992, -55.8966606]}
            zoom={13}
            scrollWheelZoom={true}
            className="leftleat-container"
          >
        
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

            <LayersControl>
              {categories.map((category, index) => (
                <LayersControl.Overlay
                  key={index}
                  checked={true}
                  name={category.name}
                  onChange={() => handleCategoryChange(category.name)}
                >
                  {panels
                    .filter((panel) => panel.latitude && panel.longitude )
                    .map((panel, panelIndex) => (
                      <Marker
                        key={panelIndex}
                        position={[panel.latitude, panel.longitude]}
                        icon={iconUbication}
                      >
                        <Popup>
                          {panel.name}
                          <br/>
                          <Button                          
                            color="primary"
                            variant="contained"
                            onClick={() => this.handlePopupClick(panel.id)}
                          >Ver</Button>
                        </Popup>
                      </Marker>
                    ))}
                </LayersControl.Overlay>
              ))}
            </LayersControl>
          </MapContainer> 
        }
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  panels: state.panels.data,
  action: state.panels.action,
  status: state.panels.status,
  pagination: state.panels.pagination,
  categories: state.categories.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPanels: (params) => dispatch(fetchPanels(params)),
  fetchCategories: (params) => dispatch(fetchCategories(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
