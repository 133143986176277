import React from "react";
import { CircularProgress } from "@material-ui/core";
import { PENDING_STATUS } from "../actions/status";
import { ACTION_ADD, ACTION_EDIT, ACTION_REMOVE } from "../actions/types";
import Button from "@material-ui/core/Button";

const FormButtons = (props) => {
  const { status, action, object } = props;

  const editing =
    status === PENDING_STATUS &&
    (action === ACTION_EDIT || action === ACTION_ADD);

  const deleting = status === PENDING_STATUS && action === ACTION_REMOVE;

  return (
    <div style={{ marginTop: "3.1rem" }}>
      <Button
        color="secondary"
        type={editing ? "button" : "submit"}
        variant="contained"
      >
        {editing && (
          <CircularProgress
            style={{
              width: "15px",
              height: "15px",
              marginRight: "5px"
            }}
          />
        )}
        {editing ? "Guardando..." : "Guardar"}
      </Button>

      <Button
        style={{ marginLeft: "0.8rem" }}
        variant="contained"
        onClick={props.onCancel}
      >
        Volver
      </Button>

      {props.onDelete && (
        <Button
          style={{ float: "right" }}
          color="secondary"
          variant="contained"
          onClick={() => props.onDelete(object.id)}
        >
          {deleting && (
            <CircularProgress
              style={{
                width: "15px",
                height: "15px",
                marginRight: "5px",
              }}
            />
          )}
          {deleting ? "Borrando..." : "Borrar"}
        </Button>
      )}
    </div>
  );
};

export default FormButtons;
