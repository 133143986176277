import React, { useEffect, useState } from "react";
import { alertColors, alertLabels } from "../../helpers/alertUtilsAnalog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { render } from "enzyme";
import Typography from "@material-ui/core/Typography";  

class AlertList extends React.Component {
  interval = null;

  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.props.fetchAlerts().then((alerts) => {
        if (!alerts) return;
        this.props.setAlertCount(alerts.length);
        this.setState(() => ({ alerts }));
      });
    }, 10000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  markAsViewed = (index) => {
    let { alerts } = this.state;
    console.log(alerts);
    const alert = alerts[index];
    this.props
      .setAlertViewed(alert.id)
      .then((alert) => {
        if (!alert) return;
        alerts.splice(index, 1);
        this.setState(() => ({ alerts }));
        this.props.setAlertCount(alerts.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render = () => (
    <Menu
      anchorEl={this.props.anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="alert-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={this.props.alertListOpen}
      onClose={this.props.onAlertListClose}
    >
      {this.state.alerts.length == 0 ? (
        <MenuItem className="alert-item" key={0}>
          <ListItemIcon>
            <FiberManualRecordIcon
              fontSize="default"
              style={{ color: alertColors["ok"] }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Sistema sin novedades"
            secondary="No existen alertas sin leer"
          />
        </MenuItem>
      ) : (
        this.state.alerts.map((alert, index) => (
          <MenuItem className="alert-item" key={index}>
            <ListItemIcon>
              <FiberManualRecordIcon
                fontSize="default"
                style={{ color: alertColors[alert.severity] }}
              />
            </ListItemIcon>
            <ListItemText  
              primary={`Mensaje: ${alert.message}`}
              secondary={alertLabels[alert.severity]+ (alert.component ? ` en ${alert.component.label} ` : "") + ` ${new Date(alert.updatedAt).toLocaleString()}`}
            />
            <CloseIcon
              style={{ marginLeft: "1rem" }}
              fontSize="default"
              onClick={() => this.markAsViewed(index)}
            />
          </MenuItem>
        ))
      )}
    </Menu>
  );
}

export default AlertList;
