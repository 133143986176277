import React from "react";
import { MenuItem, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { hasErrors, errors } from "../../helpers/utils";
import Validator from "../../services/validator";
import { connect } from "react-redux";
import { listBoards, fetchBoard } from "../../actions/boards/fetchActions";
import Typography from "@material-ui/core/Typography";
import DigitalConversionRules from "./DigitalConversionRules";
import FormTextField from "../FormTextField";
import { validate } from "uuid";
import TableCell from "@material-ui/core/TableCell";
import { CompareArrowsOutlined } from "@material-ui/icons";

export class DigitalComponentEditor extends React.Component {
  editMode = false;
  selectorThre = false;
  pinInUse;
  pinTwoInUse;
  constructor(props) {
    super(props);

    this.props.listBoards();
    
    const iocomponent = props.iocomponent;
    
    this.editMode = iocomponent.id !== null;
    if (this.editMode) {      
      this.pinInUse = iocomponent.pins[0];
      this.pinTwoInUse = iocomponent.pins[1];
    }

    this.valid = {
      form: true,
      conversionRules: true,
    };

    this.validator = new Validator({
      label: { required: true },
      imei: { required: true },
      pin: { required: true },
    });  

    if (iocomponent.type == 'selectorthreeposition'){
      this.selectorThre = true;  
    }

    this.state = {
      errors: this.validator._errors,
      iocomponent,
    };
  }

  validate = () => {
    const { label, board, pins } = this.state.iocomponent;
    if (this.state.iocomponent.type != "selectorthreeposition"){
      this.validator.validate({
        label,
        imei: board ? board.imei : "",
        pin: pins.length > 0 ? pins[0].id : "",
      })
    }else{
      this.validator.validate({
        label,
        imei: board ? board.imei : "",
        pin:  pins.length > 1 ? pins[1].id : "",
      })
    }
    this.valid.form = this.validator.isValid();
    const { form, conversionRules } = this.valid;

    if (this.state.iocomponent.alertRules[0].isActive == true &&
      this.state.iocomponent.alertRules[0].value == ''){
      this.props.setValidStatus(this.props.index, false && conversionRules);
    }else {
      this.props.setValidStatus(this.props.index, form && conversionRules);
    }
  };
 
  updateConversionRules = (convRules, isValid) => {
    const { iocomponent } = this.state;
    iocomponent.conversionRules = convRules;
    this.setState(() => ({ iocomponent }));

    this.valid.conversionRules = isValid;
    const { form, conversionRules } = this.valid;
    this.props.setValidStatus(this.props.index, form && conversionRules);
  };

  componentDidMount = () => {
    this.validate();
    
    this.updateBoardPins();
  };
  updateBoardPins() {
    const { iocomponent } = this.state;
    
    if (this.editMode) {
      this.props.fetchBoard(iocomponent.board.id).then((board)=>{        
        iocomponent.board.pins = this.preparePins(board.pins);        
        this.setState(() => ({ iocomponent }));
        this.validate();
      })
    }
  }

  onTextChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.value;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectImeiChange = (e) => {
    if (!e.target.value) return;
    // this.editMode = false
    const { iocomponent } = this.state;

    if ( iocomponent.board != null ){ // validation boards select      
      let lastboardselected = this.props.boards.find((board) => board.id === e.target.value);
      if(iocomponent.board.id != lastboardselected.id){iocomponent.pins[0] = '';}
    }

    iocomponent.board = this.props.boards.find((board) => board.id === e.target.value);    
    // iocomponent.board.pins = iocomponent.board.pins
    // this.pinsInputs = this.pinsInput(iocomponent.board.pins);
    
    iocomponent.board.pins = this.preparePins(iocomponent.board.pins);
    
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectPinChange = (e) => {
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    const pin = iocomponent.board.pins.find((pin) => (pin.id === e.target.value));
    iocomponent.pins[0]= pin;
// validation selector three position compoennt
    if (iocomponent.pins.length > 1){if (iocomponent.pins[0].id == iocomponent.pins[1].id){iocomponent.pins[1] = '';}}
// validation pin alert compoennt
    if (iocomponent.alertRules[0].value == 0){iocomponent.alertRules[0].value = '';}
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectPinChangeTow = (e) => {
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    const pin = iocomponent.board.pins.find((pin) => (pin.id === e.target.value));
    iocomponent.pins[1]= pin;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  handleCheckboxChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.checked;
    this.setState(() => ({ iocomponent }));
  };

  preparePins = (pins, inUse = false) => {
    const { pinTypes } = this.props;
    let result = pins
      .filter((pin) => {
        return pin.type === 3; // pin tipo digital
      })
      .map((pin) => ({
        ...pin,
        description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
          }`,
      }));
    
    if (result.length === 0) {
      result.push({ id: null, description: "No hay pines disponibles" });
    }
    
    return result;
  };

  render = () => {
    const { iocomponent, pintow="" } = this.state;
    const { boards, type , boardSucces} = this.props;
    const validBoards = boards.filter(board => board.id !== null);

    const pins = iocomponent.board
      ? iocomponent.board.pins
      : [{ id: null, description: "Seleccionar la placa" }];
    
    
      
    
    return (
      <form id="component-config-form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormTextField
              label="Nombre/Etiqueta"
              id="label"
              name="label"
              value={iocomponent.label ? iocomponent.label : ""}
              onChange={this.onTextChange}
              error={hasErrors(this.state, "label")}
              required
            />
            {errors(this.state, "label")}
          </Grid>


            <Grid item xs={12} md={6}>

              <FormTextField
                label="IMEI"
                id="imei"
                select
                value={iocomponent.board ? iocomponent.board.id : ""}                
                onChange={this.onSelectImeiChange}
                error={hasErrors(this.state, "imei")}
              >
                {validBoards.length > 0 ? (
                  validBoards.map((board) => (
                    <MenuItem 
                      key={board.id} 
                      value={board.id} 
                      selected = {this.editMode && board.id === iocomponent.board.id}
                      disabled = {this.editMode && board.id === iocomponent.board.id}
                    >
                      {board.imei}{" "}
                      {Boolean(board.description)
                        ? `- ${board.description}`
                        : "- Sin descripción"}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No hay boards disponibles</MenuItem>
                )}


              </FormTextField>
              {errors(this.state, "imei")}
            </Grid>
        

        {/* esto es pin  */}
         
            <Grid item xs={12} md={6}>
              <FormTextField
                label={this.selectorThre ? "Pin de entrada izquiedo" : "Pin de Entrada"}
                id="pin"
                select
                value={
                  (iocomponent.pins.length > 0 && iocomponent.pins[0].id) || ""
                }                
                onChange={this.onSelectPinChange}
                error={hasErrors(this.state, "pin")}
              >
                {pins?.length - 1 > 0 ? (
                  pins.map((pin, index) => {
                    return (
                      <MenuItem 
                        key={index} 
                        value={pin.id}
                        selected={this.editMode && pin.id === this.pinInUse.id}
                        disabled={pin.component}
                      >
                        {pin.description}
                      </MenuItem>
                    );
                  })
                ):(
                  <MenuItem disabled>No hay pins disponibles</MenuItem>
                )}
                
              </FormTextField>
              {errors(this.state, "pin")}
            </Grid>
          
          <Grid item xs={12} md={6}></Grid>

          {this.selectorThre ?  (
            
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Pin de Entrada Derecho"
                id="pin"
                select
                value={
                  (iocomponent.pins.length > 1 && iocomponent.pins[1].id) || ""
                }                                
                onChange={this.onSelectPinChangeTow}
                error={hasErrors(this.state, "pin")}
              >
              {pins?.length - 1 > 0 ? (
                pins.map((pin, index) => {
                    return (
                      <MenuItem 
                      key={index} 
                      value={pin.id}
                      selected={this.editMode && pin.id === this.pinTwoInUse?.id}
                      disabled={pin.component || pin.id === this.pinInUse?.id}
                      >
                        {pin.description}
                      </MenuItem>
                    );
                })
              ):(
                <MenuItem disabled>No hay pins disponibles</MenuItem>
              )}

              </FormTextField>
              {errors(this.state, "pin")}
            </Grid>
          ) : (<Grid item xs={12} md={6}></Grid>)}
          


          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Tipo de entrada
            </Typography>
          </Grid>
          <DigitalConversionRules
            conversionRules={iocomponent.conversionRules}
            updateConversionRules={this.updateConversionRules}
            typeUnits={type}
          />

          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Configuración Adicional
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.smsAlert}
                  onChange={this.handleCheckboxChange}
                  name="smsAlert"
                />
              }
              label="Notificar alertas mediante SMS"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showElement}
                  onChange={this.handleCheckboxChange}
                  name="showElement"
                />
              }
              label="Ocultar para usuario Viewer"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showHistory}
                  onChange={this.handleCheckboxChange}
                  name="showHistory"
                />
              }
              label="Registrar en historial"
            />
          </Grid>

        </Grid>
      </form>
    );
  };
}

const getDigitalPins = (iocomponent, pins, pinTypes) => {
  let result = pins
    .filter((pin) => {
      return pin.type === 3
    })
    .map((pin) => ({
      ...pin,
      description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
        }`,
    }));


  return result;
};

const getBoards = (component, boardList, pinTypes) => {
  let boards = boardList
    .map((board) => {
      board.pins = getDigitalPins(component, board.pins, pinTypes);
      return board;
    })
    .filter((board) => board.pins.length > 0);

  if (boards.length === 0) {
    boards.push({ id: null, description: "- No hay placas disponibles -" });
  }

  return boards;
};

const mapStateToProps = (state, props) => {
  const { iocomponent } = props;
  const board = iocomponent.id ? iocomponent.board : undefined;

  const boards = getBoards(
    iocomponent,
    state.boards.list,
    state.boards.pinTypes
  );
  
  return {
    iocomponent,
    pinTypes: state.boards.pinTypes,
    boards: boards,
    board,
    pins: board ? board.pins : [],
    type: [
      "Normal Abierto",
      "Normal Cerrado",
    ],
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  listBoards: () => dispatch(listBoards()),
  fetchBoard: (id) => dispatch(fetchBoard(id)),
  boardSucces: () => dispatch(fetchBoardsSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalComponentEditor);
