import { getFormData } from '../../helpers/utils';
import { makeAuthorizedRequest } from "../../services/apiConnector";

export const EDIT_USER_PENDING = 'EDIT_USER_PENDING';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

export const editUser = (id, updates) => (dispatch) => {
    dispatch(editUserPending());

    const data = getFormData(updates);

    return makeAuthorizedRequest(`/users/${id}`,{method: 'PATCH', data})
    .then(response => {
        dispatch(editUserSuccess(id, updates));                    
        return id;
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido';
        dispatch(editUserError(message));
    });
};

const editUserPending = () => ({
    type: EDIT_USER_PENDING
});

const editUserSuccess = (id, updates) => ({
    type: EDIT_USER_SUCCESS,
    id, 
    updates
});

const editUserError = (error) => ({
    type: EDIT_USER_ERROR,
    error
});