import React from 'react';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import Validator from '../../services/validator';

import { CircularProgress } from '@material-ui/core';

import FlashMessage from '../FlashMessage';

import { ACTION_EDIT, ACTION_REMOVE, ACTION_ADD } from '../../actions/types';
import { PENDING_STATUS } from '../../actions/status';
import FormTextField from '../FormTextField';
import { errors, hasErrors } from '../../helpers/utils';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CompanyForm extends React.Component {

    company = null;
    editMode = false;

    constructor(props) {
       
        super(props);

        this.validator = new Validator({
            name: { required: true },
            email: { required: true, email: true },
            phone: { required: true },
            address: { required: true }
        });

        this.company = this.props.company || {
            name: '',
            email: '',
            phone: '',
            address: '',
            isPremium:'',
        };

        this.editMode = !!this.company.id

        this.state = {
            company: this.company,
            errors: this.validator._errors
        };
    }

    onSubmit = (e) => {
        e.preventDefault();
        
        const { name, email, address, phone, isPremium } = this.state.company;

        if (!this.validator.validate({ name, email, address, phone })) {
            const errors = this.validator._errors;
            this.setState(() => ({ errors }));
            return;
        }

        this.props.onSubmit({ name, email, address, phone, isPremium });
    };

    onTextChange = (e) => {        
        this.company[e.target.id] = e.target.value;
        this.setState(() => ({ company: this.company }));
    }
 

    render() {
        const { error, status, action, message } = this.props;
        return (
            <div>
                {(action === ACTION_EDIT || action === ACTION_ADD) &&
                   status == "error" && (
                    <FlashMessage message={error} type={status}/>
                )}
                {(action === ACTION_EDIT || action === ACTION_ADD) &&
                   status === "success" && (
                    <FlashMessage message={message} type={status}/>
                )}

                <form id="company-form" onSubmit={this.onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Nombre o Razón Social"
                                id="name"
                                value={this.company.name}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'name')}
                            />
                            {errors(this.state, 'name')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Email"
                                id="email"
                                disabled={this.editMode}
                                value={this.company.email}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'email')}
                            />
                            {errors(this.state, 'email')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Teléfono"
                                id="phone"
                                value={this.company.phone}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'phone')}
                            />
                            {errors(this.state, 'phone')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                label="Dirección"
                                id="address"
                                value={this.company.address}
                                onChange={this.onTextChange}
                                error={hasErrors(this.state, 'address')}
                            />
                            {errors(this.state, 'address')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                            <Switch
                                id='isPremium'
                                onChange={this.setPremium}                                
                                color="Secondary"
                                value={this.company.isPremium}
                            />
                            }
                            label="Premium"
                        />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: '3.1rem' }}>
                        {status === PENDING_STATUS && (action === ACTION_EDIT || action === ACTION_ADD)  ? (
                            <Button color="secondary" variant="contained">
                                <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px' }}/>
                                Guardando...
                            </Button>
                        ) : (
                            <Button color="secondary" type="submit" variant="contained">
                                Guardar
                            </Button>
                        )}
                        <Button style={{ marginLeft: '0.8rem' }} variant="contained" onClick={this.props.onCancel}>
                            Volver
                        </Button>
                        {status === PENDING_STATUS && action === ACTION_REMOVE ? (
                            <Button 
                            style={{ float: 'right' }} 
                            variant="contained">
                                <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px'}} />
                                Borrando...
                            </Button>
                        ) : (
                            <Button 
                            style={{ float: 'right' }} 
                            variant="contained" 
                            onClick={() => this.props.onDelete(this.state.company.id)}>
                                Borrar 
                            </Button>                            
                        )}
                    </div>
                </form>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    status: state.companies.status,
    action: state.companies.action,
    message: state.companies.message,
    error: state.companies.error,
});


export default connect(mapStateToProps)(CompanyForm);