import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import PanelMenuAnalogInputs from './PanelMenuAnalogInputs';
import PanelMenuDigitalInputs from './PanelMenuDigitalInputs';
import PanelMenudigitalOutputs from './PanelMenuDigitalOutputs';
import PanelMenuTextLabels from './PanelMenuTextLabels';


const PanelMenu = (props) => {

    const [visible,setVisible] = useState(false);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    const toggleMenu = () => setVisible(!visible);

    const handleClick = () => {
        toggleMenu();
    };

    return (
        <div id="panel-menu" className={visible ? 'show' : 'hide'}>
            <div className="menu-header">
                <div id="menu-toggle">
                    <IconButton onClick={handleClick}>
                        {visible ? <ChevronRightIcon /> : <TuneIcon />}
                    </IconButton>
                </div>
                <Typography varian="h7" style={{ paddingTop: '12px' }}>
                    Herramientas de Desarrollo
                </Typography>
            </div>

            <div className="clearfix"></div>

            <div id="expansion-menu">
        
                <PanelMenuDigitalInputs {...props} expanded={expanded} handleChange={handleChange} />
        
                <PanelMenuAnalogInputs {...props} expanded={expanded} handleChange={handleChange} />
        
                <PanelMenudigitalOutputs {...props} expanded={expanded} handleChange={handleChange} />
                
                <PanelMenuTextLabels {...props} expanded={expanded} handleChange={handleChange} />
        
            </div>
      
        </div>
    );
};

export default PanelMenu;