import { FETCH_COMPONENT_HISTORY_PENDING, FETCH_COMPONENT_HISTORY_SUCCESS, FETCH_COMPONENT_HISTORY_ERROR } from '../actions/history/fetchActions';
import { PENDING_STATUS, SUCCESS_STATUS, ERROR_STATUS } from '../actions/status';

const defaultState = {
  status: null,
  message: null,
  data: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_COMPONENT_HISTORY_PENDING:
      return {
        ...state,
        status: PENDING_STATUS,
      };
    case FETCH_COMPONENT_HISTORY_SUCCESS:
      return {
        ...state,
        status: SUCCESS_STATUS,
        data: action.data,
      };
    case FETCH_COMPONENT_HISTORY_ERROR:
      return {
        ...state,
        status: ERROR_STATUS,
        message: action.error,
      };
    default:
      return state;
  }
};
