import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../helpers/auth';

export default ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { referrer: props.location }}} />
    )} />
)