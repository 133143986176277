import AnalogComponentModel from "./AnalogComponentModel";
import shapes from "../helpers/shapes";
import { getAlertIcon } from "./AlertIconModel";

class DChronometerModel extends AnalogComponentModel {
  constructor(props) {
    super(props);
    this.type = "dchronometer";
    this.label = props.label ? props.label : "Medidor Digital";
    this.selectable = true;
  }

  getChronometerValue = () => {
    return new AnalogComponentModel({
      type: "dchronometerValue",
      value: this.value,
      xPosition: 0,
      yPosition: 90,
    });
  };

  getChronometerUnit = () => {
    const um = this.conversionRules.write.um;
    return new AnalogComponentModel({
      type: "dchronometerUnit",
      label: "Unit",
      value: um ? um : "n/a",
      xPosition: 0,
      yPosition: 115,
    });
  };

  getAlert = (xPosition, yPosition) => {
    if (!this.alerts || this.alerts.length == 0) {
      this.alerts.push({
        severity: "default",
      });
    }
    return getAlertIcon(this.alerts[0], xPosition, yPosition);
  };

  getConfig = (parent = null) => {
    const label = this.getLabel(this.label, 0, 170);
    const value = this.getChronometerValue();
    const unit = this.getChronometerUnit();
    const alert = this.getAlert(120, 140);

    this.children = [label, value, unit, alert];

    const shape = { ...shapes[this.type] };
    shape.styles = this.getImage();

    return {
      parent,
      object: this,
      shape,
      children: this.children,
    };
  };
}

export default DChronometerModel;
