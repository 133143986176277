import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Paper, CircularProgress, Link } from '@material-ui/core';
import FlashMessage from '../FlashMessage';

import { signup } from '../../actions/auth/signupActions';

import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import { ACTION_ADD } from '../../actions/types';

import FormTextField from '../FormTextField';
import { hasErrors, errors } from '../../helpers/utils';
import Validator from '../../services/validator';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


export class SignupPage extends React.Component {
    
    constructor(props) {
        super(props);

        const signup = {
            imei: '',
            email: '',
            name: '',
            address: '',
            phone: '',
            companyPhone: '',
            password: '',
            password_confirm: ''
        }

        this.validator = new Validator({
            imei: { required: true },
            phone: { required: true },
            name: { required: true },
            email: { required: true, email: true },
            companyPhone: { required: false },
            address: { required: false },
            password: { required: true, minLength: 8 },
            password_confirm: { sameAs: 'password' }
        });

        this.state = {
            signup,
            errors: this.validator._errors
        };

        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    componentDidMount() {
        if(this.props.authenticated) {
            this.props.history.push('/')
        }
    }

    onTextChange = (e) => {
        const { name, value } = e.target;
        const { signup } = this.state;
        signup[name] = value;
        this.setState(() => ({ signup }));
    }

    onSubmit = (e) => {
        
        e.preventDefault();

        const { signup } = this.state;

        if (!this.validator.validate(signup)) {
            const errors = this.validator._errors;
            this.setState(() => ({ errors }));
            return;
        }

        this.props.signup(signup).then(token => {
            if(!!token) {
                setTimeout(() => this.props.history.push('/'), 4000)
            }
        });
    }

    handleClickShowPassword() {
        this.setState((prevState) => ({
          showPassword: !prevState.showPassword,
        }));
    }

    render() {
        const {showPassword}= this.state
        const { inProgress, error, action, message } = this.props;
        const { signup } = this.state;

        return (
            <form id="login-form" onSubmit={this.onSubmit}>
                {!inProgress && action === 'signup' && error && (
                    <FlashMessage message={error} type={ERROR_STATUS}/>
                )} 
                {!inProgress && action === 'signup' && message && (
                    <FlashMessage message={message} type={SUCCESS_STATUS}/>
                )}           
                <Grid container justify="center" direction="row">
                    <Grid item xs></Grid>
                    <Grid item container xs={12} md={8}>
                        <Paper style={{width:'100%', padding:'40px', marginBottom:'5.1rem'}}>
                            <img 
                                alt="logo" 
                                className="logo" 
                                title="logo" 
                                src="/images/logo.png"
                                style={{width:'80%',maxWidth:'240px',marginBottom:'20px',display: 'block', margin: '0 auto'}}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="IMEI"
                                        id="imei"
                                        name="imei"
                                        value={signup.imei}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'imei')}
                                    />
                                    {errors(this.state, 'imei')}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Tel. placa"
                                        id="phone"
                                        name="phone"
                                        value={signup.phone}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'phone')}
                                    />
                                    {errors(this.state, 'phone')}
                                </Grid>                                
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Nombre o Razón social"
                                        id="name"
                                        name="name"
                                        value={signup.name}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'name')}
                                    />
                                    {errors(this.state, 'name')}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Dirección"
                                        id="address"
                                        name="address"
                                        value={signup.address}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'address')}
                                    />
                                    {errors(this.state, 'address')}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Tel. de contacto"
                                        id="companyPhone"
                                        name="companyPhone"
                                        value={signup.companyPhone}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'companyPhone')}
                                    />
                                    {errors(this.state, 'companyPhone')}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Email"
                                        id="email"
                                        name="email"
                                        value={signup.email}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'email')}
                                    />
                                    {errors(this.state, 'email')}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                <FormTextField
                                    type={showPassword ? 'text' : 'password'}                                                                        
                                    label="Contraseña"                                       
                                    id="password"
                                    name="password"
                                    value={signup.password}
                                    onChange={this.onTextChange}
                                    error={hasErrors(this.state, 'password')}
                                    InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={this.handleClickShowPassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    ),
                                    }}
                                />
                            </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormTextField
                                        label="Confirmación contraseña"
                                        type={showPassword ? 'text' : 'password'} 
                                        id="password_confirm"
                                        name="password_confirm"
                                        value={signup.password_confirm}
                                        onChange={this.onTextChange}
                                        error={hasErrors(this.state, 'password_confirm')}
                                    />
                                    {errors(this.state, 'password_confirm')}
                                </Grid>
                                <Grid container item xs={12} md={12} style={{ marginTop:'3.1rem' }}>
                                    <Grid item xs={12} md={5} style={{ textAlign:'left' }}>
                                        {status === PENDING_STATUS && action === ACTION_ADD ? (
                                            <Button color="primary" variant="contained">
                                                <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px'}} />
                                                Registrando usuario...
                                            </Button>
                                        ) : (
                                            <Button color="primary" type="submit" variant="contained">
                                                Completar registro
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={7} style={{ textAlign:'right', paddingTop:'0.6rem' }}>
                                        ¿Ya tiene cuenta? Inicie sesión <Link href="/login" color="secondary">aquí</Link>                            
                                    </Grid>    
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { inProgress, error, action, message, authenticated } = state.auth;
    return {
        inProgress,
        error,
        action,
        message,
        authenticated
    };
};

const mapDispatchToProps = (dispatch) => ({
    signup: (params) => dispatch(signup(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);