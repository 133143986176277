import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UserListTable from './UserListTable';
import TablePagination from '@material-ui/core/TablePagination';

import { fetchUsers } from '../../actions/users/fetchActions';

import LoadingSpinner from '../LoadingSpinner';

import FlashMessage, { FLASH_TYPE_ERROR } from '../FlashMessage';
import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from '../../actions/status';
import { ACTION_FETCH } from '../../actions/types';

export class UserList extends React.Component {

    onCreateClicked = () => this.props.history.push('/users/create');

    onEditClicked = (id) => this.props.history.push(`/users/edit/${id}`);

    componentDidMount = () => {
        const {perPage, currentPage} = this.props.pagination;
        this.props.fetchUsers({currentPage, perPage});
    }

    handleChangePage = (event, currentPage) => {
        const { perPage } = this.props.pagination;
        this.props.fetchUsers({currentPage: (currentPage), perPage});}
    
    handleChangeRowsPerPage = (event) => {
        let { fetchUsers } = this.props;
        const perPage = event.target.value;
        fetchUsers({currentPage: 0, perPage});
    };
    
    render = () => {

        const { users, status, message, action } = this.props;
        const { currentPage, perPage, total } = this.props.pagination;

        return (
            <div>
                <div className="page-header">
                    <Typography variant="h6" noWrap className="pull-left">
                        Usuarios
                    </Typography>
                    <div className="pull-right">
                        <Button 
                            id="create-user" 
                            onClick={this.onCreateClicked} 
                            color="secondary" 
                            variant="contained"
                            >
                                Crear nuevo
                        </Button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                
                {status === PENDING_STATUS && <LoadingSpinner/>}

                {action === ACTION_FETCH && status === ERROR_STATUS && (
                    <FlashMessage type={status} message={message}/>
                )}
                
                {(status === SUCCESS_STATUS || status === ERROR_STATUS) && users.length === 0 && (
                    <p>No hay registros para mostrar.</p>
                )}
    
                { users.length > 0 && (
                    <div>
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <UserListTable 
                            onRemoveClicked={this.onRemoveClicked} 
                            onEditClicked={this.onEditClicked} 
                            users={users}
                        />
                        <TablePagination
                            component="div"
                            count={total}
                            page={currentPage}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={perPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                ) }
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    users: state.users.data,
    action: state.users.action,
    status: state.users.status,
    message: state.users.message,
    pagination: state.users.pagination
});

const mapDispatchToProps = (dispatch) => ({
    fetchUsers: (params) => dispatch(fetchUsers(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);