import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_COMPANIES_PENDING = 'FETCH_COMPANIES_PENDING';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';

export const LIST_COMPANIES_PENDING = 'LIST_COMPANIES_PENDING';
export const LIST_COMPANIES_SUCCESS = 'LIST_COMPANIES_SUCCESS';
export const LIST_COMPANIES_ERROR = 'LIST_COMPANIES_ERROR';

export const fetchCompanies = ({currentPage, perPage}) => {
    return (dispatch) => {
        dispatch(fetchCompaniesPending({currentPage, perPage}));
        const skip = parseInt(currentPage) * parseInt(perPage);
        return makeAuthorizedRequest(`/companies?skip=${skip}&limit=${perPage}`)
        .then(response => {
            dispatch(fetchCompaniesSuccess(response.data, currentPage, perPage));
            return response.data.result;
        })
        .catch(error => {
            const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
            dispatch(fetchCompaniesError(message));
        })
    }
}

export const listCompanies = () => (dispatch) => {
    dispatch(listCompaniesPending());
    return makeAuthorizedRequest('/companies?select=id,name')
    .then(response => {
        dispatch(listCompaniesSuccess(response.data));
        return response.data.result;
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(listCompaniesError(message));
    });    
};

const fetchCompaniesPending = ({currentPage, perPage}) => ({
    type: FETCH_COMPANIES_PENDING,
    currentPage,
    perPage
});

const fetchCompaniesSuccess = (data, currentPage, perPage) => ({
    type: FETCH_COMPANIES_SUCCESS,
    data: data.result,
    currentPage: currentPage,
    perPage: perPage,
    total: data.total
});

const fetchCompaniesError = (error) => ({
    type: FETCH_COMPANIES_ERROR,
    error
});

const listCompaniesPending = () => ({
    type: LIST_COMPANIES_PENDING
});

const listCompaniesSuccess = (data) => ({
    type: LIST_COMPANIES_SUCCESS,
    data: data.result
});

const listCompaniesError = (error) => ({
    type: LIST_COMPANIES_ERROR,
    error
});
