import Validator from "../services/validator";
import { yellow, orange, red, green } from "@material-ui/core/colors";

// export const getInputsRequired = () => {
//   return ["condition", "value", "message"];
// };

// export const validateRow = (data) => {
//   var inputs = getInputsRequired();

//   removeErrors(data);

//   if (data.smsAlert) {
//     inputs.push("telNumber");
//   }

//   if (data.isActive) {
//     inputs.forEach((cond) => {
//       if (cond === "value") {
//         if (data[cond] === "" || data[cond] === null || data[cond] < 0) {
//           data.errors[cond] = getMessage();
//         } else {
//           delete data.errors[cond];
//           removeKey(data, cond);
//         }
//       } else {
//         if (!data[cond]) {
//           data.errors[cond] = getMessage();
//         } else {
//           delete data.errors[cond];
//           removeKey(data, cond);
//         }
//       }
//     });
//   } else {
//     removeErrors(data);
//   }
// };

// export const getMessage = () => {
//   return ["Este dato es obligatorio"];
// };

// export const getValidationRequeridMessage = () => {
//   return "Existen campos requeridos";
// };

// export const getValidationAlertMessage = () => {
//   return "Existen alertas activas que no tienen la información requerida para guardarse";
// };

// export const getMatchedSeverities = () => {
//   return {
//     "pre-alert": "Pre-Alarma",
//     alert: "Alarma",
//     fail: "Fallo",
//     "out-range": "Fuera de rango",
//   };
// };

// export const getMatchedSeverity = (severity) => {
//   return getMatchedSeverities()[severity];
// };

// export const getRangeNumber = (start, end) => {
//   // crea un bucle que se repite de principio a fin
//   var ans = [];
//   for (let i = start; i <= end; i++) {
//     ans.push(i);
//   }
//   return ans;
// };

// Geronimo
export const alertColors = {
  "pre-alert": yellow[500],
  alert: orange[500],
  failure: red[500],
  ok: green[500],
};

export const alertLabels = {
  "pre-alert": "Pre-alarma",
  alert: "Alarma",
  failure: "Fallo",
  ok: "Sistema ok",
};

export const mergeAlerts = (iocomponent, defaultAlerts) => {
  iocomponent.alerts.forEach((alert) => {
    const index = defaultAlerts.findIndex(
      (element) => element.severity === alert.severity
    );
    if (index > -1) {
      const { min, max } = iocomponent.conversionRules.write;

      defaultAlerts[index] = {
        id: alert.id,
        condition: alert.condition,
        value: this.fitValue(alert.value, min, max),
        message: alert.message,
        severity: alert.severity,
        smsAlert: alert.smsAlert,
        telNumber: alert.telNumber,
        alertViewed: alert.alertViewed,
        component: alert.component,
        color: defaultAlerts[index].color,
        isActive: !!alert.isActive,
        errors: [],
      };
    }
  });
  return defaultAlerts;
};

export const fitAlerts = (alerts, config) => {
  return alerts.map((alert) => {
    if (!alert.isActive) return alert;
    alert.value = fitValue(alert.value, config.min, config.max);
    return alert;
  });
};

export const fitValue = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export const validateAll = (alerts, rules, messages = null) => {
  return alerts.map((alert) => {
    return validate(alert, rules, messages);
  });
};

export const validate = (alert, rules, messages = null) => {
  alert.errors = [];
  alert.isValid = true;

  if (!alert.isActive) return alert;

  const validator = new Validator(rules, messages);
  validator.validate({
    condition: alert.condition,
    value: alert.value,
    message: alert.message,
  });

  alert.errors = validator._errors;
  alert.isValid = validator.isValid();
  return alert;
};
