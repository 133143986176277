import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_MODELS_PENDING = "FETCH_MODELS_PENDING";
export const FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS";
export const FETCH_MODELS_ERROR = "FETCH_MODELS_ERROR";

export const LIST_MODELS_PENDING = "LIST_MODELS_PENDING";
export const LIST_MODELS_SUCCESS = "LIST_MODELS_SUCCESS";
export const LIST_MODELS_ERROR = "LIST_MODELS_ERROR";

export const fetchModels = () => {
  return () => {
    let url = `/models`;
    return makeAuthorizedRequest(url)
      .then((response) => {
        // console.log(response.data.result[0]);
        return response.data.result[0];
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
      });
  };
};

export const fetchModelBoards = ({
  currentPage,
  perPage,
  component = null,
}) => {
  return (dispatch) => {
    dispatch(fetchModelsPending({ currentPage, perPage }));
    const skip = parseInt(currentPage) * parseInt(perPage);
    return makeAuthorizedRequest(`/models?skip=${skip}&limit=${perPage}`)
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data, currentPage, perPage));
        return response.data.result;
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
        dispatch(fetchModelsError(message));
      });
  };
};

const fetchModelsPending = ({ currentPage, perPage }) => ({
  type: FETCH_MODELS_PENDING,
  currentPage,
  perPage,
});

const fetchModelsSuccess = (data, currentPage, perPage) => ({
  type: FETCH_MODELS_SUCCESS,
  data: data.result,
  currentPage: currentPage,
  perPage: perPage,
  total: data.total,
});

const fetchModelsError = (error) => ({
  type: FETCH_MODELS_ERROR,
  error,
});

const listModelsPending = () => ({
  type: LIST_MODELS_PENDING,
});

const listModelsSuccess = (data) => ({
  type: LIST_MODELS_SUCCESS,
  data: data.result,
});

const listModelsError = (error) => ({
  type: LIST_MODELS_ERROR,
  error,
});
