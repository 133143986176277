import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_COMPONENT_HISTORY_PENDING = "FETCH_COMPONENT_HISTORY_PENDING";
export const FETCH_COMPONENT_HISTORY_SUCCESS = "FETCH_COMPONENT_HISTORY_SUCCESS";
export const FETCH_COMPONENT_HISTORY_ERROR = "FETCH_COMPONENT_HISTORY_ERROR";

export const fetchComponentHistory = (componentIds) => {
  return (dispatch) => {
    dispatch(fetchComponentHistoryPending());

    return makeAuthorizedRequest(
      `/log/component?componentIds=${componentIds.join(",")}`
    )
      .then((response) => {
        dispatch(fetchComponentHistorySuccess(response.data));
        console.log(response);
        return response.data.result;
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
        dispatch(fetchComponentHistoryError(message));
      });
  };
};

const fetchComponentHistoryPending = () => ({
  type: FETCH_COMPONENT_HISTORY_PENDING,
});

const fetchComponentHistorySuccess = (data) => ({
  type: FETCH_COMPONENT_HISTORY_SUCCESS,
  data,
});

const fetchComponentHistoryError = (error) => ({
  type: FETCH_COMPONENT_HISTORY_ERROR,
  error,
});
