import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CompanyForm from './CompanyForm';
import { connect } from 'react-redux';

import { editCompany } from '../../actions/companies/editActions';
import { removeCompany } from '../../actions/companies/removeActions';
import { ACTION_REMOVE } from '../../actions/types';
import { PENDING_STATUS } from '../../actions/status';
import FlashMessage from '../FlashMessage';

export class CompanyEditor extends React.Component {

    constructor(props) {
        super(props);
        const company = this.getCompanyForm();         
        if(!company.id) {
            this.goBack();
            this.state = { company: null };
        }
        else {
            this.state = { company };
        }       
    }

    getCompanyForm = () => {        
        const {company} = this.props;
        const form = company ? {
            id: company.id,
            name: company.name,
            email: company.email,
            phone: company.phone,
            address: company.address,
            isPremium: company.isPremium
        } : {
            name: '',
            email: '',
            phone: '',
            address: ''
        };    
        return form;
    };

    clean = (obj) => {
        for (var propName in obj) { 
            if (obj[propName] === null || obj[propName] === undefined || String(obj[propName]).trim() === '') {
                delete obj[propName];
            }
        }
        return obj;
    };
      
    onSubmit = (updates) => {
        const {editCompany} = this.props;
        const {company} = this.state;
        updates = this.clean(updates); 
        editCompany(company.id, updates);
    };

    onDelete = (id) => {   
        const {removeCompany} = this.props;     
        removeCompany(id).then(id => {
            if(id) {
                const company = this.getCompanyForm();         
                this.setState(() => ({company}));    
                setTimeout(() => {
                    this.goBack();            
                }, 2000);
            }
        });
    };    

    goBack = () => this.props.history.push('/companies');

    onCancel = () => {
        this.goBack();
    }

    render = () => {
        const { action, status, message } = this.props;
        return (
            <div>
                <div className="page-header">
                    <Typography variant="h6" noWrap className="pull-left">
                        Edición de Empresa
                    </Typography>
                </div>
                <div className="clearfix"></div>
                {action === ACTION_REMOVE && status !== PENDING_STATUS && (
                    <FlashMessage message={message} type={status}/>
                )}            
                <CompanyForm
                 onSubmit={this.onSubmit}
                 company={this.state.company} 
                 onCancel={this.onCancel} 
                 onDelete={this.onDelete}
                />
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    const company = state.companies.data.find((companies) => companies.id === parseInt(props.match.params.id));
    return {
        status: state.companies.status,
        message: state.companies.message,
        action: state.companies.action,
        company
    };
};

const mapDispatchToProps = (dispatch) => ({
    editCompany: (id, updates) => dispatch(editCompany(id, updates)),
    removeCompany: (id) => dispatch(removeCompany(id))
});

export default connect(mapStateToProps,mapDispatchToProps)(CompanyEditor);