import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PanelCard from "./PanelCard";
import NewPanelCard from "./NewPanelCard";

import { connect } from "react-redux";
import { fetchPanels } from "../../actions/panels/fetchActions";
import LoadingSpinner from "../LoadingSpinner";
import FlashMessage from "../FlashMessage";
import {
  PENDING_STATUS,
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../actions/status";
import { ACTION_FETCH } from "../../actions/types";

export class PanelsList extends React.Component {
  interval = null;

  componentDidMount = () => {
    const { perPage, current } = this.props.pagination;

    this.interval = setInterval(() => {
      this.props.fetchPanels({ currentPage: current, perPage });
    }, 5000);

    this.props.fetchPanels({ currentPage: current, perPage });
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  render = () => {
    const { panels, action, status, message, pagination } = this.props;
    
    return (
      <div className="main-content">
        <Typography variant="h6" noWrap>
          Tableros
        </Typography>

        {status === PENDING_STATUS && <LoadingSpinner />}

        {action === ACTION_FETCH && status === ERROR_STATUS && (
          <FlashMessage type={status} message={message} />
        )}

        <div className="panels">
          <Grid container spacing={2}>
            {panels.length > 0 &&
              panels.map((panel, index) => (
                <PanelCard key={index} panel={panel} />
              ))}
            <NewPanelCard />
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  panels: state.panels.data,
  action: state.panels.action,
  status: state.panels.status,
  pagination: state.panels.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPanels: (params) => dispatch(fetchPanels(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelsList);
