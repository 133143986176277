import { makeAuthorizedRequest } from "../../services/apiConnector";

export const EDIT_PANEL_PENDING = "EDIT_PANEL_PENDING";
export const EDIT_PANEL_SUCCESS = "EDIT_PANEL_SUCCESS";
export const EDIT_PANEL_ERROR = "EDIT_PANEL_ERROR";

export const editPanel = (id, updates) => (dispatch) => {
  dispatch(editPanelPending());

  const data = updates;

  return makeAuthorizedRequest(`/panels/${id}`, { method: "PATCH", data })
    .then((response) => {
      dispatch(editPanelSuccess(id, updates));
      return response.data.result.id;
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : "Error desconocido";
      dispatch(editPanelError(message));
    });
};

const editPanelPending = () => ({
  type: EDIT_PANEL_PENDING,
});

const editPanelSuccess = (id, updates) => ({
  type: EDIT_PANEL_SUCCESS,
  id,
  updates,
});

const editPanelError = (error) => ({
  type: EDIT_PANEL_ERROR,
  error,
});
