import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {          
          align: 'left',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {          
          title: {
            text: 'Valor',
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0);
            },
          },
        },
      },
    };
  }

  // Añadimos un método para ordenar las fechas y actualizar el estado del componente
  updateChartData(data) {
    // Ordenamos las fechas de menor a mayor
    const sortedData = data.logs.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    
    // Creamos los objetos para la serie y los actualizamos en el estado
    const seriesData = sortedData.map((item) => ({
      x: new Date(item.updatedAt).getTime() - 3 * 60 * 60 * 1000,
      y: item.convertedValue,
    }));

    this.setState({
      series: [{ name: data.name, data: seriesData }],      
    });
  }

  componentDidMount() {
    // Llamamos al método de actualización del gráfico cuando el componente está montado
    const { data } = this.props;
    this.updateChartData(data);
  }

  componentDidUpdate(prevProps) {
    // Actualizamos el gráfico solo si los datos de las props han cambiado
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.updateChartData(data);
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} width={900} />
      </div>
    );
  }
}

export default ApexChart;
