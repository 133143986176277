import React from "react";
import Typography from "@material-ui/core/Typography";
import UserForm from "./UserForm";
import { connect } from "react-redux";

import { editUser } from "../../actions/users/editActions";
import { removeUser } from "../../actions/users/removeActions";
import { ACTION_REMOVE } from "../../actions/types";
import { PENDING_STATUS } from "../../actions/status";

import FlashMessage from "../FlashMessage";
import { clean } from "../../helpers/utils";

export class UserEditor extends React.Component {
  constructor(props) {
    super(props);
    const user = this.getUserForm();
    if (!user.id) {
      this.goBack();
      this.state = { user: null };
    } else {
      this.state = { user };
    }
  }

  getUserForm = () => {
    const { user } = this.props;
    const form = user
      ? {
          id: user.id,
          name: user.name,
          email: user.email,
          role: user.role,
          company: user.company,
          password: "",
          password_confirm: "",
        }
      : {
          name: "",
          email: "",
          role: undefined,
          company: undefined,
          password: "",
          password_confirm: "",
        };
    return form;
  };

  onSubmit = (updates) => {
    const { editUser } = this.props;
    const { user } = this.state;
    updates = clean(updates);
    editUser(user.id, updates);
  };

  onDelete = (id) => {
    const { removeUser } = this.props;
    removeUser(id).then((id) => {
      if (id) {
        const user = this.getUserForm();
        this.setState(() => ({ user }));
        setTimeout(() => {
          this.goBack();
        }, 2000);
      }
    });
  };

  goBack = () => this.props.history.push("/users");

  onCancel = () => {
    this.goBack();
  };

  render = () => {
    const { action, status, message } = this.props;

    return (
      <div>
        <div className="page-header">
          <Typography variant="h6" noWrap className="pull-left">
            Edición de usuario
          </Typography>
        </div>
        <div className="clearfix"></div>
        {action === ACTION_REMOVE && status !== PENDING_STATUS && (
          <FlashMessage message={message} type={status} />
        )}
        <UserForm
          onSubmit={this.onSubmit}
          user={this.state.user}
          onCancel={this.onCancel}
          onDelete={this.onDelete}
        />
      </div>
    );
  };
}

const mapStateToProps = (state, props) => {
  const user = state.users.data.find(
    (user) => user.id === parseInt(props.match.params.id)
  );
  return {
    status: state.users.status,
    message: state.users.message,
    action: state.users.action,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editUser: (id, updates) => dispatch(editUser(id, updates)),
  removeUser: (id) => dispatch(removeUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditor);
