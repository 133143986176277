import React from "react";
import { MenuItem, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { hasErrors, errors } from "../../helpers/utils";
import Validator from "../../services/validator";
import { connect } from "react-redux";
import { listBoards, fetchBoard } from "../../actions/boards/fetchActions";
import Typography from "@material-ui/core/Typography";
import DigitalConversionRules from "./DigitalConversionRules";
import FormTextField from "../FormTextField";
import { validate } from "uuid";
import TableCell from "@material-ui/core/TableCell";
import { CompareArrowsOutlined } from "@material-ui/icons";


export class OutputComponentEditor extends React.Component {
  
  editMode = false;
  isRelate = false;
  
  pinsInputs = [ ];
  componentRelate = []
  pinRelate;


  constructor(props) {
    super(props);

    this.props.listBoards();
    
    const iocomponent = props.iocomponent;
    console.log(iocomponent);
    
    this.editMode = iocomponent.id !== null;

    if (this.editMode) {      
      this.pinInUse = iocomponent.pins[0];
      this.pinRelate = iocomponent.pinRelate.length > 0 ?iocomponent.pinRelate[0]: null;
    }
    this.isRelate = this.pinRelate = iocomponent.pinRelate.length > 0 ? true : false;

    this.valid = {
      form: true,      
    };

    this.validator = new Validator({
      label: { required: true },
      imei: { required: true },
      pin: { required: true },
    });

    if (this.editMode) {
      iocomponent.pins = this.preparePins(iocomponent.pins, true);      
    }

    this.state = {
      errors: this.validator._errors,
      iocomponent,
      componentRelate: this.componentRelate
    };
  }

  validate = () => {
    const { label, board, pins } = this.state.iocomponent;

    this.validator.validate({
      label,
      imei: board ? board.imei : "",
      pin: pins.length > 0 ? pins[0].id : "",
    });
    this.valid.form = this.validator.isValid();
    const { form  } = this.valid;
    this.props.setValidStatus(this.props.index, form );
  };

  componentDidMount = () => {
    this.validate();
    this.updateBoardPins()
  };
  updateBoardPins() {
    const { iocomponent } = this.state;
    
    if (this.editMode) {
      this.props.fetchBoard(iocomponent.board.id).then((board)=>{        
        iocomponent.board.pins = this.preparePins(board.pins);        
        this.setState(() => ({ iocomponent }));
        this.validate();
      })
    }
  }
  onTextChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.value;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectImeiChange = (e) => {
    if (!e.target.value) return;
    this.editMode = false
    const { iocomponent } = this.state;    
    if ( iocomponent.board != null ){ // validation boards select      
      let lastboardselected = this.props.boards.find((board) => board.id === e.target.value);
      if(iocomponent.board.id != lastboardselected.id){iocomponent.pins[0] = '';}
    }
    iocomponent.board = this.props.boards.find((board) => board.id === e.target.value);    
    iocomponent.board.pins = iocomponent.board.pins
    
    iocomponent.board.pins = this.preparePins(iocomponent.board.pins);

    this.setState(() => ({ iocomponent }));
    this.validate();
  };
  
  preparePins = (pins, inUse = false) => {
    const { pinTypes } = this.props;
    let result = pins
      .filter((pin) => {
        return pin.type === 3 || pin.type === 4; // pin tipo entrada y salida digital
      })
      .map((pin) => ({
        ...pin,
        description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
          }`,
      }));
    
    if (result.length === 0) {
      result.push({ id: null, description: "No hay pines disponibles" });
    }
    
    return result;
  };
  

  // seleccion de pin de salida digital
  onSelectPinChange = (e) => {
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    console.log(iocomponent);
    const pin = iocomponent.board.pins.find((pin) => (pin.id === e.target.value));
    iocomponent.pins[0]= pin;

    if (iocomponent.alertRules[0].value == 0){iocomponent.alertRules[0].value = '';}
    this.setState(() => ({ iocomponent }));
    this.validate();
  };
  
  // seleccion de pin de entrada digital
  onSelectPinRelate = (e)=>{
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    console.log(iocomponent);
    const pin = iocomponent.board.pins.find((pin) => (pin.id === e.target.value));
    iocomponent.pinRelate[0]= pin;

    if (iocomponent.alertRules[0].value == 0){iocomponent.alertRules[0].value = '';}
    this.setState(() => ({ iocomponent }));
    this.validate();
  }
 
  handleCheckboxChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.checked;
    console.log(e.target.name);

    // si se desactiva esperar confirmacion se quita el pin relacionado
    if(e.target.name == "isActive"){
      this.isRelate = e.target.checked;
      console.log(e.target.checked);
      if(this.isRelate == false){        
        iocomponent.pinRelate = [];
        this.setState(() => ({ iocomponent }));  
      }
    }

    this.setState(() => ({ iocomponent }));
        
  };
  
  
  render = () => {
    const { iocomponent } = this.state;
    const { boards} = this.props;
    const validBoards = boards.filter(board => board.id !== null);
    const pins = iocomponent.board
    ? iocomponent.board.pins
    : [{ id: null, description: "Seleccionar la placa" }];
    
    const pinDescriptions = {
     
      "19": "Q1",
      "20": "Q2",
      "21": "Q3",
      "22": "Q4",
      "23": "Q5",
      "24": "Q6",
      "25": "Q7",
      "26": "Q8"
    };

    return (
      <form id="component-config-form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormTextField
              label="Nombre/Etiqueta"
              id="label"
              name="label"
              value={iocomponent.label ? iocomponent.label : ""}
              onChange={this.onTextChange}
              error={hasErrors(this.state, "label")}
              required
            />
            {errors(this.state, "label")}
          </Grid>
         
          <Grid item xs={12} md={6}>
            <FormTextField
              label="IMEI"
              id="imei"
              select
              value={iocomponent.board ? iocomponent.board.id : ""}
              // disabled={th}
              onChange={this.onSelectImeiChange}
              error={hasErrors(this.state, "imei")}
            >
              {validBoards.length > 0 ? (
                  validBoards.map((board) => (
                    <MenuItem 
                      key={board.id} 
                      value={board.id} 
                      selected = {this.editMode && board.id === iocomponent.board.id}
                      disabled = {this.editMode && board.id === iocomponent.board.id}
                    >
                      {board.imei}{" "}
                      {Boolean(board.description)
                        ? `- ${board.description}`
                        : "- Sin descripción"}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No hay boards disponibles</MenuItem>
                )}
            </FormTextField>
            {errors(this.state, "imei")}
          </Grid>  

          
            <Grid item xs={12} md={6}>
              <FormTextField
                label={"Pin de Salida"}
                id="pin"
                select
                value={
                  (iocomponent.pins.length > 0 && iocomponent.pins[0].id) || ""
                }                
                onChange={this.onSelectPinChange}
                error={hasErrors(this.state, "pin")}
              >
                {pins && pins.length > 0 ? (
                    pins.filter((pin) => {                      
                      return (pin.type === 4);
                    }).map((pin, index) => (
                      <MenuItem 
                        key={index} 
                        value={pin.id}
                        selected={this.editMode && pin.id === this.pinInUse.id}
                        disabled={pin.component}
                        >
                        {pinDescriptions[pin.address] || pin.description}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No hay pines disponibles</MenuItem>
                )}
              </FormTextField>
              {errors(this.state, "pin")}
            </Grid>
          


                             
            <Grid item xs={12} md={6}>
            <FormTextField
              label="Pin de confirmacion"
              id="pin"              
              value={          
                (iocomponent.pinRelate.length > 0 && iocomponent.pinRelate[0].id) || ""
              }
              select
              disabled={!this.isRelate}
              onChange={this.onSelectPinRelate}
            >
             {pins && pins.length > 0 ? (
                    pins.filter((pin) => {                      
                      return (pin.type === 3 );
                    }).map((pin, index) => (
                      <MenuItem 
                        key={index} 
                        value={pin.id}
                        selected={this.editMode && this.pinRelate?.length > 0 ? pin.id === this.pinInUse.id:""}
                        // disabled={!pin.component}
                        >
                        {pin.description}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No hay pines disponibles</MenuItem>
                )}
              </FormTextField>
            </Grid>

              
          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Configuración Adicional
            </Typography>
          </Grid>
         
              
          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox                       
                  checked={this.isRelate}             
                  onChange={this.handleCheckboxChange}
                  name="isActive"                  
                />
              }
              label="Esperar Confirmación"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showElement}
                  onChange={this.handleCheckboxChange}
                  name="showElement"
                />
              }
              label="Ocultar para usuario Viewer"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showHistory}
                  onChange={this.handleCheckboxChange}
                  name="showHistory"
                />
              }
              label="Registrar en historial"
            />
          </Grid>

        </Grid>
      </form>
    );
  };
}

const getDigitalPins = (iocomponent, pins, pinTypes) => {  
  let result = pins
  .filter((pin) => {
    return pin.type === 3 || pin.type === 4; // pin tipo entrada digital o salida digital
  })
  .map((pin) => ({
    ...pin,
    description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
      }`,
  }));


return result;
};



const getBoards = (component, boardList, pinTypes) => {  

  let boards = boardList
  .map((board) => {
      board.pins = getDigitalPins(component, board.pins, pinTypes);
      return board;
    })
    .filter((board) => board.pins.length > 0);

  if (boards.length === 0) {
    boards.push({ id: null, description: "- No hay placas disponibles -" });
  }
  
  return boards;
};


const mapStateToProps = (state, props) => {
  const { iocomponent } = props;
  const board =  undefined;
  const boards = getBoards(
    iocomponent,
    state.boards.list,
    state.boards.pinTypes
  );
  
  return {
    iocomponent,
    pinTypes: state.boards.pinTypes,
    boards: boards,
    board,
    pins: board ? board.pins : [],    
  };
};

const mapDispatchToProps = (dispatch) => ({
  listBoards: () => dispatch(listBoards()),  
  fetchBoard: (id) => dispatch(fetchBoard(id)),
  boardSucces: () => dispatch(fetchBoardsSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutputComponentEditor);
