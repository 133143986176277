import React from "react";
import Typography from "@material-ui/core/Typography";
import PanelForm from "./PanelForm";
import { connect } from "react-redux";
import { addPanel } from "../../actions/panels/addActions";

const PanelCreator = (props) => {
  const { history, addPanel } = props;

  const onSubmit = (panel) => {
    addPanel(panel).then((newPanel) => {
      if (newPanel) {
        history.push(`/panels`);
      }
    });
  };

  const onCancel = () => {
    goBack();
  };

  const goBack = () => {
    history.push("/panels");
  };

  return (
    <div>
      <div className="page-header">
        <Typography variant="h6" noWrap className="pull-left">
          Nuevo panel
        </Typography>
      </div>
      <div className="clearfix"></div>
      <PanelForm onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addPanel: (panel) => dispatch(addPanel(panel)),
});

export default connect(null, mapDispatchToProps)(PanelCreator);
