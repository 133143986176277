import { makeAuthorizedRequest } from "../../services/apiConnector";

export const ADD_TEXT_PENDING = "ADD_TEXT_PENDING";
export const ADD_TEXT_SUCCESS = "ADD_TEXT_SUCCESS";
export const ADD_TEXT_ERROR = "ADD_TEXT_ERROR";

export const addText = (textComponent) => (dispatch) => {
    // dispatch(addTextPending());

    delete textComponent['cell'];
    delete textComponent['board'];
    delete textComponent['id'];
    delete textComponent['selectable'];
    delete textComponent['children'];
    delete textComponent['getChildConfig'];
    delete textComponent['getConfig'];
    delete textComponent['getLabel'];
    delete textComponent['setValue'];
    delete textComponent['toString'];
    // console.log(textComponent)    
    return makeAuthorizedRequest(`/labels`, { method: "post", textComponent })
    //     .then((response) => {
    //         dispatch(addTextSuccess(response.data.result));
    //         return response.data.result;
    //     })
    //     .catch((error) => {
    //         const message = error.response
    //             ? error.response.data.message
    //             : "Error desconocido";
    //         dispatch(addTextError(message));
    // });
    
}
const addTextPending = () => ({
    type: ADD_TEXT_PENDING,
});

const addTextSuccess = (textComponent) => ({
    type: ADD_TEXT_SUCCESS,
    textComponent,
});

const addTextError = (error) => ({
    type: ADD_TEXT_ERROR,
    error,
});