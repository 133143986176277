import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}> {children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function generateId(index) {
    if (!index) return;
    const random = getRandomInt(0,500);
    return {
      id: `tab-${index}-${random}`,
      "aria-controls": `tabpanel-${index}-${random}`,
      key: `tabpanel-${index}-${random}`,
    };
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export default function TabsComponent(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const { tabs, component } = props;

    const handleChange = (event, newValue) => {
        setSelectedIndex(newValue);
        props.handleTabPanel(newValue);
    };

    return (
        <div>
            <Paper elevation={0}>
                <Tabs
                    value={selectedIndex}
                    onChange={handleChange}
                    aria-label="tabs"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    variant="scrollable"
                    orientation="horizontal"
                    variant="fullWidth"
                >
                    {tabs.map((tab, index) => {
                        return (
                          <Tab
                            label={tab.value}
                            {...generateId(tab.index.toString())}
                          />
                        );
                    })}
                </Tabs>
                <Divider />
            </Paper>
            {tabs.map((tab, index) => {
                if (selectedIndex === tab.index) {
                    return (
                        <TabPanel
                            value={selectedIndex}
                            index={index}
                            key={tab.index.toString()}
                        >
                            {component}
                        </TabPanel>
                    );
                }
            })}
        </div>
    );
}
