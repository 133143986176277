import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FormTextField from "../FormTextField";
import { CircularProgress } from "@material-ui/core";
import Validator from "../../services/validator";
import FlashMessage from "../FlashMessage";
import { ACTION_EDIT, ACTION_REMOVE, ACTION_ADD } from "../../actions/types";
import { ERROR_STATUS, PENDING_STATUS } from "../../actions/status";
import MenuItem from "@material-ui/core/MenuItem";
import { listCompanies } from "../../actions/companies/fetchActions";
import { hasErrors, errors } from "../../helpers/utils";
import BoardPins from "./BoardPins";

// fetch models boards to create boards
import { fetchModels } from "../../actions/boardModels/fetchAction";
import { fetchModelBoards } from "../../actions/boardModels/fetchAction";

class BoardAdminForm extends React.Component {
  board = null;
  editMode = false;

  constructor(props) {
    super(props);

    this.validator = new Validator({
      imei: { required: true },
      pinsQuantity: { required: true, min: 1 },
      model: { required: true },
    });

    this.board = this.props.board || {
      imei: "",
      pins: [],
      model: null,
      time: null,
    };

    this.editMode = !!this.board.id;

    this.state = {
      board: this.board,
      errors: this.validator._errors,
      pinsQuantity: this.board.pins.length,
    };
  }

  componentDidMount = () => {
    const current = 1,
      perPage = 100;
    this.props.fetchModels({ currentPage: current, perPage });
    this.props.fetchModelBoards({ currentPage: current, perPage });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { imei, pins, model, time } = this.state.board;
    const { pinsQuantity } = this.state;

    let form = { imei, pinsQuantity, model, time };

    if (!this.validator.validate(form)) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }

    this.props.onSubmit({ imei, pins, model, time });
  };

  onEmaiChange = (e) => {
    const emei = (this.board[e.target.id] = e.target.value);

    this.board = {
      ...this.board,
      emei: emei,
    };

    this.setState(() => ({ board: this.board }));
  };

  handleModelChange = (event) => {
    const selectedModelId = event.target.value;
    const selectedModel = this.props.models.find(
      (model) => model.id === selectedModelId
    );

    if (selectedModel) {
      const modelConfig = selectedModel.config;
      const pinsQuantity = modelConfig ? modelConfig.length : 0;

      this.board = {
        ...this.board,
        pins: modelConfig,
        model: selectedModel.id,
      };

      this.setState(() => ({ pinsQuantity, board: this.board }));
    }
  };

  handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    this.board = {
      ...this.board,
      time: selectedTime,
    };
    this.setState(() => ({ board: this.board }));
  };

  onPineTypeChange = (e) => {
    const { pinTypes } = this.props;
    const name = e.target.name;
    const index = name.split("_")[1] - 1;
    this.board.pins[index].type = e.target.value;
    this.setState(() => ({ board: this.board }));
  };

  onDelete = (id) => {
    const { status, action } = this.props;
    const deleting = status === PENDING_STATUS && action === ACTION_REMOVE;
    if (deleting) return;
    this.props.onDelete(id);
  };

  render() {
    const { status, action, message, companies, pinTypes, models, error } =
      this.props;
    const { pinsQuantity, board } = this.state;

    const editing =
      status === PENDING_STATUS &&
      (action === ACTION_EDIT || action === ACTION_ADD);
    const deleting = status === PENDING_STATUS && action === ACTION_REMOVE;

    return (
      <div>
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status == ERROR_STATUS && (
            <FlashMessage message={error} type={status} />
          )}
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status !== PENDING_STATUS && (
            <FlashMessage message={message} type={status} />
          )}

        <form id="board-form" onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <FormTextField
                label="IMEI"
                id="imei"
                value={this.board.imei}
                disabled={this.editMode}
                onChange={this.onEmaiChange}
                error={hasErrors(this.state, "imei")}
              />
              {errors(this.state, "imei")}
            </Grid>

            <Grid item xs={12} md={2}>
              <FormTextField
                select
                label="Tiempo de Espera"
                id="timeAwait"
                onChange={this.handleTimeChange}
                value={this.board.time || ""}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((minutes) => (
                  <MenuItem key={minutes} value={minutes * 60}>
                    {minutes} minuto{minutes > 1 ? "s" : ""}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>

            <Grid item xs={12} md={2}>
              {board.id ? (
                <FormTextField
                  label="Modelo"
                  id="imei"
                  value={this.board.model}
                  disabled={this.editMode}
                  onChange={this.onEmaiChange}
                  error={hasErrors(this.state, "imei")}
                />
              ) : (
                <FormTextField
                  select
                  label="Modelo"
                  id="model"
                  onChange={this.handleModelChange}
                >
                  {models && models.length > 0 ? (
                    models.map((model) => (
                      <MenuItem key={model.id} value={model.id}>
                        {model.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No hay modelos disponibles</MenuItem>
                  )}
                </FormTextField>
              )}
            </Grid>
          </Grid>

          <BoardPins
            board={board}
            // onPineTypeChange={this.onPineTypeChange}
            pinTypes={pinTypes}
            disabled={true}
          />

          <div style={{ marginTop: "3.1rem" }}>
            <Button
              color="primary"
              type={editing ? "button" : "submit"}
              variant="contained"
              startIcon={<SaveIcon />}
            >
              {editing && (
                <CircularProgress
                  style={{ width: "15px", height: "15px", marginRight: "5px" }}
                />
              )}
              {editing ? "Guardando..." : "Guardar"}
            </Button>

            <Button
              style={{ marginLeft: "0.8rem" }}
              variant="contained"
              onClick={this.props.onCancel}
            >
              Volver
            </Button>

            {this.props.onDelete && (
              <Button
                style={{ float: "right" }}
                color="secondary"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => this.onDelete(this.board.id)}
              >
                {deleting && (
                  <CircularProgress
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {deleting ? "Borrando..." : "Borrar"}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.boards.status,
  action: state.boards.action,
  message: state.boards.message,
  pinTypes: state.boards.pinTypes,
  error: state.boards.error,
  models: state.models.data,
});

const fetchModelsAction = (params) => fetchModels(params);
const fetchModelBoardsAction = (params) => fetchModelBoards(params);

const mapDispatchToProps = {
  fetchModels: fetchModelsAction,
  fetchModelBoards: fetchModelBoardsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardAdminForm);
