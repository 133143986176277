import { Grid, MenuItem } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import FormTextField from "../FormTextField";
import { hasErrors } from "../../helpers/utils";
import { useState, useEffect } from "react";
import Validator from "../../services/validator";

const AnalogConversionRules = (props) => {
  const [validation, setValidation] = useState({ errors: [] });
  const [conversionRules, setConversionRules] = useState(props.conversionRules);
  const [validated, setValidated] = useState(false);

  const validate = (convRules) => {
    setValidated(true);

    const validator = new Validator({
      read_min: { required: true },
      read_max: { required: true, min: convRules.read.min },
      read_um: { required: true },
      write_min: { required: true },
      write_max: { required: true, min: convRules.write.min },
      write_um: { required: true },
    });

    validator.validate({
      read_min: convRules.read.min,
      read_max: convRules.read.max,
      read_um: convRules.read.um,
      write_min: convRules.write.min,
      write_max: convRules.write.max,
      write_um: convRules.write.um,
    });

    setValidation({ errors: validator._errors });

    return validator.isValid();
  };

  const onReadWriteChange = (e) => {
    let { name, value } = e.target;
    value = !!value ? parseFloat(value) : "";
    updateConversionRules(name, value);
    const isValid = validate(conversionRules);
    props.updateConversionRules(conversionRules, isValid);
  };

  const onSelectUnitChange = (e) => {
    const { name, value } = e.target;
    updateConversionRules(name, value);
    const isValid = validate(conversionRules);
    props.updateConversionRules(conversionRules, isValid);
  };

  const updateConversionRules = (name, value) => {
    const parts = name.split("_");
    conversionRules[parts[0]][parts[1]] = value;
    setConversionRules(conversionRules);
  };

  useEffect(() => {
    if (!validated) {
      const isValid = validate(conversionRules);
      props.updateConversionRules(conversionRules, isValid);
    }
  }, []);

  //

  return (
    <Grid container item spacing={2}>
      <Grid item md={6}>
        <Typography noWrap style={{ marginTop: "21px" }}>
          Valores de lectura
        </Typography>
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          label="Min"
          select
          name="read_min"
          value={conversionRules.read.min}
          onChange={onReadWriteChange}
          error={hasErrors(validation, "read_min")}
        >
          {props.rangeNumbers.map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </FormTextField>
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          label="Max"
          select
          name="read_max"
          value={conversionRules.read.max}
          onChange={onReadWriteChange}
          error={hasErrors(validation, "read_max")}
        >
          {props.rangeNumbers.map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </FormTextField>
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          label="U.M."
          select
          name="read_um"
          value={conversionRules.read.um}
          onChange={onSelectUnitChange}
          error={hasErrors(validation, "read_um")}
        >
          {props.readUnits.map((um) => (
            <MenuItem key={um} value={um}>
              {um}
            </MenuItem>
          ))}
        </FormTextField>
      </Grid>

      <Grid item md={6}>
        <Typography noWrap style={{ marginTop: "23px" }}>
          Rango de visualización
        </Typography>
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          type="number"
          label="Min"
          name="write_min"
          value={conversionRules.write.min}
          onChange={onReadWriteChange}
          error={hasErrors(validation, "write_min")}
        />
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          type="number"
          label="Max"
          name="write_max"
          value={conversionRules.write.max}
          onChange={onReadWriteChange}
          error={hasErrors(validation, "write_max")}
        />
      </Grid>
      <Grid item md={2}>
        <FormTextField
          required
          label="U.M."
          select
          name="write_um"
          value={conversionRules.write.um ? conversionRules.write.um : ""}
          onChange={onSelectUnitChange}
          error={hasErrors(validation, "write_um")}
        >
          {props.writeUnits.map((um) => (
            <MenuItem key={um} value={um}>
              {um}
            </MenuItem>
          ))}
        </FormTextField>
      </Grid>
    </Grid>
  );
};

export default AnalogConversionRules;
