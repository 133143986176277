import shapes from "../helpers/shapes";


const texts = ["text"];

class TextComponentModel {
  constructor(props) {
    const {
      id = null,
      label = "",
      type = null,
      xPosition = 100,
      yPosition = 100,
      panel = null,
      showElement = true,
      board = null,
      value = 0,
      cell = null,
      selectable = false,
    } = props;

    this.id = id;
    this.label = label;
    this.type = type;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.panel = panel;
    // this.pins = pins;
    // this.smsAlert = smsAlert;
    this.showElement = showElement;
    // this.showHistory = showHistory;
    this.board = board;
    this.value = value;
    // this.conversionRules = conversionRules;
    // this.alertRules = alertRules;
    // this.rangeNumbers = rangeNumbers;
    this.cell = cell;
    // this.alerts = alerts;
    this.selectable = selectable;
  }

  toString = () => {
    let value = this.value != null ? this.value : "";

    if (!isNaN(value) && value !== "") {
      value = parseFloat(value).toFixed(2);
    }

    const um =
      this.conversionRules && this.conversionRules.write.um
        ? this.conversionRules.write.um
        : "";
    return `${value} ${um}`;
  };

  static capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getConfig = (parent = null) => {
    switch (this.type) {
      case "text":
        return this.getChildConfig(parent);
      default:
        return null;
    }
  };

  getLabel = (value, xPosition, yPosition) => {
    return new TextComponentModel({
      type: "label",
      value,
      xPosition,
      yPosition,
    });
  };

  getChildConfig = (parent) => {
    const shape = shapes[this.type];
    const width = parent.geometry.width;
    return {
      parent,
      object: this,
      shape: { ...shape, width },
      children: [],
    };
  };

  setValue = (value, model) => {
    this.value = value;
    model.setValue(this.cell, this);
    // this.children &&
    //   this.children.forEach((child) => {
    //     if (texts.indexOf(child.type) === -1) {
    //       child.value = value;
    //       model.setValue(child.cell, child);
    //     }
    //   });

    
  };



  
  
}

export default TextComponentModel;
