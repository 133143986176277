import React from "react";

import { mxGraph, mxGraphModel, mxConstants } from "mxgraph-js";
import ScrollDialog from "../ScrollDialog";

import { connect } from "react-redux";
import { fetchComponents } from "../../actions/components/fetchActions";

import { getModel } from "../../models/ModelHelper";

import { hasRoles } from "../../helpers/auth";

class PanelViewerConsole extends React.Component {
  state = {
    elements: [],
    message: "",
    components: [],
  };

  model = undefined;
  graph = undefined;
  parent = undefined;
  cell = undefined;

  interval = null;

  constructor(props) {
    super(props);
    this.containerId = "mxgraph-container";
  }

  componentDidMount() {
    this.container = document.getElementById(this.containerId);

    mxConstants.VERTEX_SELECTION_COLOR = "transparent";

    this.model = new mxGraphModel();
    this.graph = new mxGraph(this.container, this.model);
    this.parent = this.graph.getDefaultParent();

    this.addComponents();

    this.interval = setInterval(() => {
      this.refreshComponents();
    }, 30000);
  }

  addComponents = () => {
    const { panel, fetchComponents } = this.props;
    const { components } = this.state;
    fetchComponents({ currentPage: 0, perPage: 30, panel: panel.id }).then(
      (results) => {
        results
          .map((component) => getModel(component))
          .forEach((io) => {
            
            if (hasRoles(['viewer'])){
              
              if (io.showElement == true){                
                return
              }else{
                components.push(io);
                this.addComponent(io.getConfig());
                this.addBlinking(io);
              }
            }
            if(hasRoles(["admin", "owner", "editor"])){ 
              components.push(io);
              this.addComponent(io.getConfig());
              this.addBlinking(io);
            }
           
          });
        this.setState(() => ({ components }));
      }
    );
  };

  refreshComponents = () => {
    const { panel, fetchComponents } = this.props;
    const { components } = this.state;

    fetchComponents({ currentPage: 0, perPage: 30, panel: panel.id }).then(
      (results) => {
        results.forEach((object) => {
          const io = components.find((io) => io.id == object.id);
          if (object.type == "selectorthreeposition"){
            if (io) {
              let valone= object.pins[0].value
              let valtow= object.pins[1].value
              let pins = {valone , valtow};
              io.updateImage(pins);
              io.setAlerts(object.alerts);
            }
          }else{
            if (io) {
              io.setValue(object.value, this.model);
              io.setAlerts(object.alerts);
            }
          }
        });
        this.graph.refresh();
        components.forEach((io) => {
          this.addBlinking(io);
        });
      }
    );
  };

  addBlinking = (io) => {
    const icon = io.children.find((child) => child.type === "alertIcon");
    const state = this.graph.view.getState(icon.cell);
    const imgs = state.shape.node.getElementsByTagName("image");
    imgs[0].setAttribute("class", "blinking");
  };

  addComponent = (config) => {
    const { parent, object, children, shape } = config;
    const { xPosition, yPosition } = object;
    const { styles, width, height } = shape;
    let v1 = null;

    this.model.beginUpdate();
    try {
      v1 = this.graph.insertVertex(
        parent ? parent : this.parent,
        null,
        object,
        xPosition,
        yPosition,
        width,
        height,
        styles
      );
      object.cell = v1;
      if (children.length > 0) {
        children.forEach((child) => this.addComponent(child.getConfig(v1)));
      }
    } catch (e) {
      console.error("An error has occurred.", e);
    } finally {
      this.model.endUpdate();
    }
    return v1;
  };

  refreshPanel = (iocomponent) => {
    this.addComponent(iocomponent.getConfig());
  };

  render() {
    const { panel } = this.props;
    const { selected, selectedTitle } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <div id={this.containerId} style={{ width: "100%", height: "100%" }} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchComponents: (params) => dispatch(fetchComponents(params)),
});

export default connect(null, mapDispatchToProps)(PanelViewerConsole);
