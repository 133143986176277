import { getFormData } from "../../helpers/utils";
import { makeAuthorizedRequest } from "../../services/apiConnector";

export const ADD_PANEL_PENDING = "ADD_PANEL_PENDING";
export const ADD_PANEL_SUCCESS = "ADD_PANEL_SUCCESS";
export const ADD_PANEL_ERROR = "ADD_PANEL_ERROR";

export const addPanel = (panel) => (dispatch) => {
  dispatch(addPanelPending());

  const data = getFormData(panel);

  return makeAuthorizedRequest(`/panels`, { method: "post", data })
    .then((response) => {
      dispatch(addPanelSuccess(response.data.result));
      return response.data.result;
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : "Error desconocido";
      dispatch(addPanelError(message));
    });
};

const addPanelPending = () => ({
  type: ADD_PANEL_PENDING,
});

const addPanelSuccess = (panel) => ({
  type: ADD_PANEL_SUCCESS,
  panel,
});

const addPanelError = (error) => ({
  type: ADD_PANEL_ERROR,
  error,
});
