import { LIST_ROLES_ERROR, LIST_ROLES_PENDING, LIST_ROLES_SUCCESS } from '../actions/roles/fetchActions';
import { ACTION_FETCH } from '../actions/types';
import { PENDING_STATUS, SUCCESS_STATUS, ERROR_STATUS } from '../actions/status';


const defaultState = {
    status: null,
    message: null,
    action: null,
    list: []
};

export default (state = defaultState, action) => {
    switch(action.type) { 
        case LIST_ROLES_PENDING:
            return {
                ...state,
                status: PENDING_STATUS,
                action: ACTION_FETCH
            }; 
        case LIST_ROLES_SUCCESS:
            return {
                ...state,
                status: SUCCESS_STATUS,
                list: action.data
            }; 
        case LIST_ROLES_ERROR: 
            return {
                ...state,
                status: ERROR_STATUS,
                message: action.error
        }
        default:
            return state;
    };
};