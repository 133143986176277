import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from '../actions/auth/loginActions';
import { SIGNUP_PENDING, SIGNUP_SUCCESS, SIGNUP_ERROR } from '../actions/auth/signupActions';

let user = JSON.parse(localStorage.getItem('user'));

const defaultState = user ? { 
  inProgress: false,
  authenticated: true, 
  error: null, 
  message: null,
  user,
  action: null 
} : {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
    case SIGNUP_PENDING:
      return {
        ...state,
        error: null,
        message: null,
        inProgress: true,
        action: action.action
      };
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
      const { user, token, message } = action;
      return {
        ...state,
        inProgress: false,
        authenticated: true,
        message,
        user: { ...user, token }
      };
    case LOGIN_ERROR:
    case SIGNUP_ERROR:

      return { 
        ...state,
        inProgress: false,
        error: action.error 
      };
    case LOGOUT:
      return {};
    default:
      return state
  }
}