import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SettingsIcon from '@material-ui/icons/Settings';

export default function ButtonsConfig(props) {

    const [open, setOpen] = React.useState(props.open);

    const[openDelete, setOpenDelete]=React.useState(false);

    const handleClickOpenDelete = () => {
      setOpenDelete(true);
    };
    const handleCloseDelete = () => {
      setOpenDelete(false);
    };

  const handleClose = () => {   
    setOpen(false);
    if (!!props.hideOptions) {
        props.hideOptions();
    }     
  };
    const handleDeleted=()=>{
      handleClose();
      props.borrar(props.data.cell);
    }
  const activar =()=>{
    // console.log(props.data);
    props.selectComponent(props.data);
    handleClose();
  }
  return (
    <div>
      <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title-Delete"
          aria-describedby="alert-dialog-description-Delete"
        >
          <DialogTitle id="alert-dialog-title-Delete">{"Eliminar componente"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-Delete">
              Estás seguro que deseas borrar este componente?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleted} color="primary" autoFocus>
              Borrar
            </Button>
          </DialogActions>
        </Dialog>



      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Opciones"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
          
                <List>                
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <SettingsIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Button                                        
                            variant="contained"
                            color="secondary"
                            onClick={activar}
                            >                            
                            Configurar
                            </Button>
                        </ListItemText>
                    </ListItem>      

                    {/* <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <DeleteIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Button                                        
                            variant="contained"
                            color="secondary"
                            onClick={handleClickOpenDelete}
                            >
                            Borrar
                            </Button>
                        </ListItemText>
                    </ListItem>                 */}
                </List>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>          
        </DialogActions>
      </Dialog>
    </div>
  );
}
