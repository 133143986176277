import React from 'react';
import Typography from '@material-ui/core/Typography';
import CompanyForm from './CompanyForm';
import { connect } from 'react-redux';
import { addCompany } from '../../actions/companies/addActions';

const CompanyCreator = (props) => {

    const { history, addCompany } = props;

    const onSubmit = (company) => {
        addCompany(company).then(newCompany => {
            if(newCompany) {
                history.push(`/companies/edit/${newCompany.id}`);    
            }
        });
    };

    const onCancel = () => {
        goBack();
    }

    const goBack = () => {
        history.push('/companies');
    };

    return (
        <div>
            <div className="page-header">
                <Typography variant="h6" noWrap className="pull-left">
                    Nueva Empresa
                </Typography>
            </div>
            <div className="clearfix"></div>
            <CompanyForm onSubmit={onSubmit} onCancel={onCancel}/>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addCompany: company => dispatch(addCompany(company))
});

export default connect(null, mapDispatchToProps)(CompanyCreator);