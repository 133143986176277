//panel entradas analogicas

import React from "react";
import { Grid } from "@material-ui/core";
import ComponentButton from "../components/ComponentButton";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TankModel from "../../models/TankModel";
import ChronometerModel from "../../models/ChronometerModel";
import DChronometerModel from "../../models/DChronometerModel";

const PanelMenuAnalogInputs = (props) => {
  const { handleChange, expanded } = props;

  const addTank = () => {
    const object = new TankModel({ panel: props.panel });
    props.addComponent(object.getConfig());
  };

  const addChronometer = () => {
    const object = new ChronometerModel({ panel: props.panel });
    props.addComponent(object.getConfig());
  };

  const addDigitalChronometer = () => {
    const object = new DChronometerModel({ panel: props.panel });
    props.addComponent(object.getConfig());
  };

  return (
    <ExpansionPanel
      className="expansion-item"
      expanded={expanded === "panel2"}
      onChange={handleChange("panel2")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
        className="expansion-sumary"
      >
        <Typography className="expansion-header">Entradas Analógicas (lecturas)</Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <Grid container spacing={1} style={{ width: "100%", margin: "auto" }}>
          <Grid item md={6}>
            <ComponentButton
              handleClick={addTank}
              text={"Tanque"}
              image={"/images/tank-default.png"}
            />
          </Grid>
          <Grid item md={6}>
            <ComponentButton
              handleClick={addChronometer}
              text={"Medidor Analógico"}
              image={"/images/a-tachometer-default.png"}
            />
          </Grid>
          <Grid item md={6}>
            <ComponentButton
              handleClick={addDigitalChronometer}
              text={"Medidor Digital"}
              image={"/images/d-tachometer-button.png"}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default PanelMenuAnalogInputs;
