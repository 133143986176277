import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchComponentHistory } from "../../actions/history/fetchActions";
import {
  PENDING_STATUS,
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../actions/status";
import { ACTION_FETCH } from "../../actions/types";
import LoadingSpinner from "../LoadingSpinner";
import FlashMessage from "../FlashMessage";
import ComponentButton from "../components/ComponentButton";
import ApexChart from "../history/graph";

class PanelHistoryData extends Component {
  componentDidMount() {
    const { panel, fetchComponentHistory } = this.props;
    if (panel) {
      const componentIds = panel.components.map((component) => component.id);
      fetchComponentHistory(componentIds);
    }
  }

  render() {
    const { panel, historyData, status, action, message } = this.props;

    if (status === PENDING_STATUS) {
      return <LoadingSpinner />;
    }

    if (action === ACTION_FETCH && status === ERROR_STATUS) {
      return <FlashMessage type={status} message={message} />;
    }
    console.log("status",status);
    console.log(historyData)
    return (
      <div>
      {status==='success' && historyData.length > 0 ?(

        historyData.map((component) => (
          <div key={component.id} className="app" id={`chart-${component.id}`}>
            <Grid container spacing={1} style={{ width: "100%", margin: "auto" }}>
              <Grid item md={2}>
                <ComponentButton
                  text={component.name}
                  image={`/images/${component.type}-default.png`}
                  disabled
                />
              </Grid>
              <Grid item md={6}>
                <ApexChart data={component} />
              </Grid>
            </Grid>
          </div>
        ))

      ) :(
        <p>No hay registros para mostrar.</p>
      )} 
        
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.history.status,
    message: state.history.message,
    action: state.history.action,
    historyData: state.history.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchComponentHistory: (componentIds) =>
    dispatch(fetchComponentHistory(componentIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelHistoryData);
