import { FORGOT_PENDING, FORGOT_SUCCESS, FORGOT_ERROR } from '../actions/password/forgotActions';
import { RESET_PENDING, RESET_SUCCESS, RESET_ERROR } from '../actions/password/resetActions';

import { PENDING_STATUS, SUCCESS_STATUS, ERROR_STATUS } from '../actions/status';

const defaultState = { 
  action: null,
  doing: false, 
  done: false,
  status: null, 
  message: null, 
  error: null 
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FORGOT_PENDING:
    case RESET_PENDING:
      return {
        ...state,
        doing: true,
        done: false,
        error: null,
        message: null,
        status: PENDING_STATUS,
        action: action.action
      };
    case FORGOT_SUCCESS:
    case RESET_SUCCESS:
      return {
        ...state,
        doing: false,
        done: true,        
        status: SUCCESS_STATUS,
        message: action.message
      };
    case FORGOT_ERROR:
    case RESET_ERROR:
      return { 
        ...state,
        doing: false,
        done: true,
        status: ERROR_STATUS,
        error: action.error 
      };
    default:
      return state
  }
}