import React from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

import { editBoard } from "../../actions/boards/editActions";
import { removeBoard } from "../../actions/boards/removeActions";
import { ACTION_REMOVE } from "../../actions/types";
import { PENDING_STATUS } from "../../actions/status";
import FlashMessage from "../FlashMessage";

import BoardForm from "./BoardForm";
import { clean } from "../../helpers/utils";
import { hasRoles } from "../../helpers/auth";
import BoardAdminForm from "./BoardAdminForm";

export class BoardEditor extends React.Component {
  constructor(props) {
    super(props);
    const board = this.getForm();
    
    if (!board.id) {
      this.state = { board: null };
      this.goBack();
    } else {
      this.state = { board };//entra siempre que tiene que editar
    }
  }

  getForm = () => {
    const { board } = this.props;
    const form = board
      ? {
          id: board.id,
          description: board.description,
          simCredit: board.simCredit,
          phone: board.phone,
          imei: board.imei,
          company: board.company,
        }
      : {
          description: "",
          imei: "",
          phone: "",
          simCredit: "",
          company: undefined,
        };
    return form;
  };

  onSubmit = (updates) => {

    const { editBoard } = this.props;
    const { board } = this.state;
    updates = clean(updates);
    delete updates['imei'];

    editBoard(board.id, updates);
  };

  onDelete = (id) => {
    const { removeBoard } = this.props;
    removeBoard(id).then((id) => {
      const { action, status, message } = this.props;
      if (id) {
        const board = this.getForm();
        this.setState(() => ({ board }));
        setTimeout(() => {
          this.goBack();
        }, 2000);
      }
    });
  };

  goBack = () => this.props.history.push("/boards");

  onCancel = () => {
    this.goBack();
  };

  render = () => {
    const { action, status, message, board } = this.props;
    
    return (
      <div style={{ paddingBottom:'20px'}}>
        <div className="page-header">
          <Typography variant="h6" noWrap className="pull-left">
            Edición de Placa
          </Typography>
        </div>
        <div className="clearfix"></div>
        {action === ACTION_REMOVE && status !== PENDING_STATUS && (
          <FlashMessage message={message} type={status} />
        )}

        {board && (hasRoles(["admin"]) ? (
          <BoardAdminForm
            board={board}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onDelete={this.onDelete}
          />
        ) : (
          <BoardForm
            board={board}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onDelete={this.onDelete}
          />
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state, props) => {
  const board = state.boards.data.find(
    (board) => board.id === parseInt(props.match.params.id)
  );
  return {
    status: state.boards.status,
    message: state.boards.message,
    action: state.boards.action,
    board,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editBoard: (id, updates) => dispatch(editBoard(id, updates)),
  removeBoard: (id) => dispatch(removeBoard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardEditor);
