import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Paper, CircularProgress } from "@material-ui/core";
import FlashMessage from "../FlashMessage";

import { resetPassword } from "../../actions/password/resetActions";

import { ERROR_STATUS, SUCCESS_STATUS } from "../../actions/status";
import FormTextField from "../FormTextField";
import { NavLink } from "react-router-dom";
import Validator from "../../services/validator";

import { hasErrors, errors } from "../../helpers/utils";
import { isAuthenticated } from "../../helpers/auth";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new Validator({
      password: { required: true, minLength: 8 },
      passwordConfirm: { sameAs: "password" },
    });

    this.state = {
      password: "",
      passwordConfirm: "",
      submitted: false,
      errors: this.validator._errors,
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push("/");
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });

    const { password, passwordConfirm } = this.state;
    const { token } = this.props;

    if (!this.validator.validate({ password, passwordConfirm })) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }

    this.props.resetPassword(password, token);
  };

  render() {
    const { password, passwordConfirm } = this.state;
    const { action, error, message, done, doing } = this.props;

    return (
      <form id="login-form" onSubmit={this.handleSubmit}>
        {action === "reset" && done && error && (
          <FlashMessage message={error} type={ERROR_STATUS} />
        )}
        {action === "reset" && done && message && (
          <FlashMessage message={message} type={SUCCESS_STATUS} />
        )}
        <Grid container justify="center" direction="row">
          <Grid item xs></Grid>
          <Grid item container xs={12} md={4}>
            <Paper style={{ width: "100%", padding: "40px" }}>
              <img
                alt="logo"
                className="logo"
                title="logo"
                src="/images/logo.png"
                style={{ width: "80%", maxWidth: "240px" }}
              />
              <Grid item xs={12} md={12}>
                <FormTextField
                  type="password"
                  label="Contraseña"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  error={hasErrors(this.state, "password")}
                />
                {errors(this.state, "password")}
              </Grid>
              <Grid item xs={12} md={12}>
                <FormTextField
                  type="password"
                  label="Confirmar contraseña"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={this.handleChange}
                  error={hasErrors(this.state, "passwordConfirm")}
                />
                {errors(this.state, "passwordConfirm")}
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ marginTop: "2.1rem", textAlign: "left" }}>
                  {doing ? (
                    <Button color="primary" variant="contained">
                      <CircularProgress
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      />
                      Cambiando contraseña...
                    </Button>
                  ) : (
                    <Button color="primary" type="submit" variant="contained">
                      Cambiar contraseña
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ marginTop: "1.7rem", textAlign: "left" }}>
                  <NavLink to="/login">Iniciar Sesión</NavLink>
                </div>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const token = props.match.params.token;
  const { action, error, message, done, doing } = state.password;
  const { authenticated } = state.auth;
  return {
    action,
    error,
    message,
    done,
    doing,
    token,
    authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (password, token) => dispatch(resetPassword(password, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
