import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    image: {      
      height: 100,
      width: 100,
      display: 'block',
      margin: 'auto'
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: '70%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    label: {
        width: '100%',
        display: 'block',
        padding: '5px',
        textAlign: 'center',
        fontSize: '10px'
    }
}));

const ComponentButton = (props) => {
    
    const classes = useStyles();

    return (
        <div>
            <ButtonBase onClick={() => props.handleClick()} className={classes.image}>
                <span style={{backgroundImage: `url(${props.image})`}} className={classes.imageSrc}/>
            </ButtonBase>    
            <span className={classes.label}>{props.text}</span>
        </div>
    );
};

export default ComponentButton;

