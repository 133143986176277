import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";

import Brightness1Icon from "@material-ui/icons/Brightness1";
import HistoryIcon from "@material-ui/icons/History";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import BuildIcon from "@material-ui/icons/Build";
import Tooltip from '@material-ui/core/Tooltip';

import { useHistory } from "react-router-dom";
import { hasRoles } from "../../helpers/auth";




const PanelCard = (props) => {
  const { panel } = props;
  const history = useHistory();

  const handleEditClick = () => {
    history.push(`/panels/edit/${panel.id}`);
  };

  const handleViewClick = () => {
    history.push(`/panels/view/${panel.id}`);
  };

  const handleHistoryClick = () => {
    history.push(`/panels/history/${panel.id}`);
  };

  const handleBuildClick = () => {
    history.push(`/panels/build/${panel.id}`);
  };

  return (
    <Grid item xs={12} sm={3}>
      <Paper className="panel">
        <Grid container spacing={2} className="panel-info">
          <Grid container item xs={12} className="panel__header">
            <Grid item>
              <Typography className="panel__title">{panel.name}</Typography>
            </Grid>
            <Grid item xs className="text-align-end">
              <Brightness1Icon
                className={`panel__status-${panel.status} ${
                  panel.status !== "ok" ? " blinking" : ""
                }`}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className="panel__body"
            style={{
              background: `url('/images/panel-bg.png')`,
              backgroundPosition: "center",
              backgroundSize: "100%",
            }}
          ></Grid>
          <Grid container item xs={12} className="panel__footer">
            <Grid item>

              <Tooltip title={'Historial'} placement="bottom">
                <IconButton           
                  disabled={!hasRoles(["owner", "editor", "viewer"])}     
                  aria-label="Ver historial"
                  component="span"
                  onClick={handleHistoryClick}
                >                
                  <HistoryIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={'Visualizar '} placement="bottom">
                <IconButton
                  disabled={!hasRoles(["admin","owner", "editor", "viewer"])}
                  aria-label="Ver mas"
                  component="span"
                  onClick={handleViewClick}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={'Editar Tablero'} placement="bottom">
                <IconButton
                  disabled={!hasRoles([ "owner", "editor"])}
                  aria-label="Editar"
                  component="span"
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={'Configurar Tablero'} placement="bottom">
                <IconButton
                  disabled={!hasRoles([ "owner", "editor"])}
                  aria-label="Configurar"
                  component="span"
                  onClick={handleBuildClick}
                >
                  <BuildIcon />
                </IconButton>
              </Tooltip>


            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PanelCard;
