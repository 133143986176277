import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";

export const iconUbication = new L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/7068/7068006.png",
  iconRetinaUrl: "https://cdn-icons-png.flaticon.com/512/7068/7068006.png",
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
});
export const iconFilter = new L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/4502/4502383.png",
  iconRetinaUrl: "https://cdn-icons-png.flaticon.com/512/4502/4502383.png",
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
});
