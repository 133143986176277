import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_PANELS_PENDING = "FETCH_PANELS_PENDING";
export const FETCH_PANELS_SUCCESS = "FETCH_PANELS_SUCCESS";
export const FETCH_PANELS_ERROR = "FETCH_PANELS_ERROR";

export const LIST_PANELS_PENDING = "LIST_PANELS_PENDING";
export const LIST_PANELS_SUCCESS = "LIST_PANELS_SUCCESS";
export const LIST_PANELS_ERROR = "LIST_PANELS_ERROR";

export const fetchPanels = ({ currentPage, perPage }) => {
  return (dispatch) => {
    dispatch(fetchPanelsPending({ currentPage, perPage }));
    const skip = parseInt(currentPage) * parseInt(perPage);
    return makeAuthorizedRequest(`/panels?skip=${skip}&limit=${perPage}`)
      .then((response) => {
        dispatch(fetchPanelsSuccess(response.data, currentPage, perPage));
        return response.data.result;
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
        dispatch(fetchPanelsError(message));
      });
  };
};

export const listPanels = () => (dispatch) => {
  dispatch(listPanelsPending());
  return makeAuthorizedRequest("/panels?select=id,imei")
    .then((response) => {
      dispatch(listPanelsSuccess(response.data));
      return response.data.result;
    })
    .catch((error) => {
      const message =
        typeof error.response !== "undefined"
          ? error.response.data.message
          : "Error desconocido";
      dispatch(listPanelsError(message));
    });
};

const fetchPanelsPending = ({ currentPage, perPage }) => ({
  type: FETCH_PANELS_PENDING,
  currentPage,
  perPage,
});

const fetchPanelsSuccess = (data, currentPage, perPage) => ({
  type: FETCH_PANELS_SUCCESS,
  data: data.result,
  currentPage: currentPage,
  perPage: perPage,
  total: data.total,
});

const fetchPanelsError = (error) => ({
  type: FETCH_PANELS_ERROR,
  error,
});

const listPanelsPending = () => ({
  type: LIST_PANELS_PENDING,
});

const listPanelsSuccess = (data) => ({
  type: LIST_PANELS_SUCCESS,
  data: data.result,
});

const listPanelsError = (error) => ({
  type: LIST_PANELS_ERROR,
  error,
});
