import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import MUIAppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";

import { logout } from "../actions/auth/loginActions";
import { withRouter } from "react-router-dom";
import { getUser } from "../helpers/auth";

import { useLocation } from "react-router-dom";
import AlertList from "./alerts/AlertList";
import UserMenu from "./UserMenu";
import { fetchAlerts } from "../actions/alerts/fetchActions";
import { setAlertViewed } from "../actions/alerts/viewedActions";

const AppBar = (props) => {
  const { classes, open } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const [alertListOpen, setAlertListOpen] = React.useState(false);
  const [alertCount, setAlertCount] = React.useState(0);

  const user = getUser();

  const excludePaths = [
    "/signup",
    "/login",
    "/password/forgot",
    "/password/reset",
  ];

  const location = useLocation();

  const onProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setProfileMenuOpen(true);
  };

  const onProfileMenuClose = () => {
    setAnchorEl(null);
    setProfileMenuOpen(false);
  };

  const onAlertListOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setAlertListOpen(true);
  };

  const onAlertListClose = () => {
    setAlertListOpen(false);
  };

  const onLogout = () => {
    onProfileMenuClose();
    onAlertListClose();
    props.logout();
    props.history.push("/");
  };

  if (
    excludePaths.indexOf(location.pathname.trim()) !== -1 ||
    !props.isAuthenticated
  ) {
    return null;
  }

  return (
    <div>
      <MUIAppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: open })}
      >
        <Toolbar className="toolbar">
          <IconButton
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <img className="logo" src="/images/logo.png" />
          <div className={classes.grow} />
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={onAlertListOpen}
            color="inherit"
          >
            <Badge badgeContent={alertCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <div>
            <span className="auth-user">{user.name}</span>
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={onProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </div>
        </Toolbar>
      </MUIAppBar>
      <UserMenu
        anchorEl={anchorEl}
        profileMenuOpen={profileMenuOpen}
        onProfileMenuClose={onProfileMenuClose}
        onLogout={onLogout}
      />
      <AlertList
        anchorEl={anchorEl}
        alertListOpen={alertListOpen}
        onAlertListClose={onAlertListClose}
        setAlertCount={setAlertCount}
        fetchAlerts={props.fetchAlerts}
        setAlertViewed={props.setAlertViewed}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  fetchAlerts: () => dispatch(fetchAlerts()),
  setAlertViewed: (id) => dispatch(setAlertViewed(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBar));
