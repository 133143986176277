import { getFormData } from '../../helpers/utils';
import { makeRequest } from '../../services/apiConnector';
import { setUser, removeUser } from '../../helpers/auth';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';

export const login = (credentials) => (dispatch) => {
    dispatch(loginPending());
    
    const data = getFormData(credentials);

    return makeRequest('/auth/login', { method: 'POST', data })
    .then(response => {
        const auth = response.data.result;
        setUser(auth);
        dispatch(loginSuccess(auth.user, auth.token));            
        return auth.token;
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido'
        dispatch(loginError(message));
    });
};

export const logout = () => (dispatch) => {
    removeUser();
    dispatch(logoutStarted())
};

const logoutStarted = () => ({
    type: LOGOUT
});

const loginPending = () => ({
    type: LOGIN_PENDING,
    action: 'login'
});

const loginSuccess = (user, token) => ({
    type: LOGIN_SUCCESS,
    user,
    token
});

const loginError = (error) => ({
    type: LOGIN_ERROR,
    error
});