import { makeAuthorizedRequest } from "../../services/apiConnector";

export const FETCH_CATEGORIES_PENDING = "FETCH_CATEGORIES_PENDING";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";

export const LIST_CATEGORIES_PENDING = "LIST_CATEGORIES_PENDING";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_ERROR = "LIST_CATEGORIES_ERROR";

export const fetchCategory = () => {
  return () => {
    let url = `/categories`;
    return makeAuthorizedRequest(url)
      .then((response) => {
        // console.log(response.data.result[0]);
        return response.data.result[0];
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
      });
  };
};

export const fetchCategories = ({
  currentPage,
  perPage,
  component = null,
}) => {
  return (dispatch) => {
    dispatch(fetchCategoriesPending({ currentPage, perPage }));
    const skip = parseInt(currentPage) * parseInt(perPage);
    return makeAuthorizedRequest(`/categories?skip=${skip}&limit=${perPage}`)
      .then((response) => {
        dispatch(fetchCategoriesSuccess(response.data, currentPage, perPage));
        return response.data.result;
      })
      .catch((error) => {
        const message =
          typeof error.response !== "undefined"
            ? error.response.data.message
            : "Error desconocido";
        dispatch(fetchCategoriesError(message));
      });
  };
};

const fetchCategoriesPending = ({ currentPage, perPage }) => ({
  type: FETCH_CATEGORIES_PENDING,
  currentPage,
  perPage,
});

const fetchCategoriesSuccess = (data, currentPage, perPage) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  data: data.result,
  currentPage: currentPage,
  perPage: perPage,
  total: data.total,
});

const fetchCategoriesError = (error) => ({
  type: FETCH_CATEGORIES_ERROR,
  error,
});

const listCategoriesPending = () => ({
  type: LIST_CATEGORIES_PENDING,
});

const listCategoriesSuccess = (data) => ({
  type: LIST_CATEGORIES_SUCCESS,
  data: data.result,
});

const listCategoriesError = (error) => ({
  type: LIST_CATEGORIES_ERROR,
  error,
});
