import React from "react";
import { MenuItem, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { hasErrors, errors } from "../../helpers/utils";
import Validator from "../../services/validator";
import { connect } from "react-redux";
import { listBoards, fetchBoard } from "../../actions/boards/fetchActions";
import Typography from "@material-ui/core/Typography";
import DigitalConversionRules from "./DigitalConversionRules";
import FormTextField from "../FormTextField";
import { validate } from "uuid";
import TableCell from "@material-ui/core/TableCell";
import { CompareArrowsOutlined } from "@material-ui/icons";

export class TextComponentEditor extends React.Component {
  editMode = false;
  
  constructor(props) {
    super(props);

console.log("mis props", props);
    const iocomponent = props.iocomponent;
    
    this.editMode = iocomponent.id !== null;
    

    this.valid = {
      form: true,
      conversionRules: true,
    };

    this.validator = new Validator({
      label: { required: true },      
    });  

    

    this.state = {
      errors: this.validator._errors,
      iocomponent,
    };
  }

  validate = () => {
    const { label} = this.state.iocomponent;
  
      this.validator.validate({
        label       
      });
      this.valid.form = this.validator.isValid();
  };
 
  

  componentDidMount = () => {
    this.validate();

  };
  

  onTextChange = (e) => {
    console.log( e.target.value);
    const { iocomponent } = this.state;
    console.log(iocomponent);
    iocomponent[e.target.name] = e.target.value;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  


  handleCheckboxChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.checked;
    this.setState(() => ({ iocomponent }));
  };

  

  render = () => {
    const { iocomponent } = this.state;

    return (
      <form id="component-config-form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormTextField
              label="Nombre/Etiqueta"
              id="label"
              name="value"
              value={iocomponent.value ? iocomponent.value : ""}
              onChange={this.onTextChange}
              error={hasErrors(this.state, "label")}
              required
            />
            {errors(this.state, "label")}
          </Grid>


       
          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Configuración Adicional
            </Typography>
          </Grid>

       

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showElement}
                  onChange={this.handleCheckboxChange}
                  name="showElement"
                />
              }
              label="Ocultar para usuario Viewer"
            />
          </Grid>

         

        </Grid>
      </form>
    );
  };
}



const mapStateToProps = (state, props) => {
  const { iocomponent } = props;
 

  return {
    iocomponent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listBoards: () => dispatch(listBoards()),
  fetchBoard: (id) => dispatch(fetchBoard(id)),
  boardSucces: () => dispatch(fetchBoardsSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextComponentEditor);
