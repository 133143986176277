import Validator from "../services/validator";
import { red, green } from "@material-ui/core/colors";

export const alertColors = {
  failure: red[500],
  ok: green[500],
};

export const alertLabels = {
  alert: "Alarma",
  failure: "Fallo",
  ok: "Sistema ok",
};

export const mergeAlerts = (iocomponent, defaultAlerts) => {
  iocomponent.alerts.forEach((alert) => {
    const index = defaultAlerts.findIndex(
      (element) => element.severity === alert.severity
    );
    if (index > -1) {
      defaultAlerts[index] = {
        id: alert.id,
        condition: alert.condition,
        value: alert.value,
        message: alert.message,
        severity: alert.severity,
        smsAlert: alert.smsAlert,
        telNumber: alert.telNumber,
        alertViewed: alert.alertViewed,
        component: alert.component,
        color: defaultAlerts[index].color,
        isActive: !!alert.isActive,
        errors: [],
      };
    }
  });
  return defaultAlerts;
};

export const validateAll = (alerts, rules, messages = null) => {
  return alerts.map((alert) => {
    return validate(alert, rules, messages);
  });
};

export const validate = (alert, rules, messages = null) => {
  alert.errors = [];
  alert.isValid = true;
  if (!alert.isActive) return alert;
  const validator = new Validator(rules, messages);
  validator.validate({
    value: alert.value,
    condition: alert.condition,
    message: alert.message,
  });

  alert.errors = validator._errors;
  alert.isValid = validator.isValid();
  return alert;
};
