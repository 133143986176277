import AnalogComponentModel from "./AnalogComponentModel";
import shapes from "../helpers/shapes";
import { getAlertIcon } from "./AlertIconModel";

class ChronometerModel extends AnalogComponentModel {
  constructor(props) {
    super(props);
    this.type = "chronometer";
    this.label = props.label ? props.label : "Medidor Analogico";
    this.selectable = true;
  }

  getChronometerValue = () => {
    return new AnalogComponentModel({
      type: "chronometerValue",
      value: this.value,
      xPosition: 0,
      yPosition: 119,
    });
  };

  getAlert = (xPosition, yPosition) => {
    if (!this.alerts || this.alerts.length == 0) {
      this.alerts.push({
        severity: "default",
      });
    }
    return getAlertIcon(this.alerts[0], xPosition, yPosition);
  };

  getConfig = (parent = null) => {
    const chrono = this.getChronometerValue();
    const label = this.getLabel(this.label, 0, 170);
    const alert = this.getAlert(120, 140);
    this.children = [chrono, label, alert];

    const shape = { ...shapes[this.type] };
    shape.styles = this.getImage();

    return {
      parent,
      object: this,
      shape,
      children: this.children,
    };
  };
}

export default ChronometerModel;
