import React from "react";
import { MenuItem, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { hasErrors, errors } from "../../helpers/utils";
import Validator from "../../services/validator";
import { connect } from "react-redux";
import { listBoards, fetchBoard } from "../../actions/boards/fetchActions";
import Typography from "@material-ui/core/Typography";
import AnalogConversionRules from "./AnalogConversionRules";
import FormTextField from "../FormTextField";
import { validate } from "uuid";


export class AnalogComponentEditor extends React.Component {
  editMode = false;
  constructor(props) {
    super(props);
    
    this.props.listBoards()
    
    const iocomponent = props.iocomponent;
    
    iocomponent.rangeNumbers = [...Array(21).keys()];

    this.editMode = iocomponent.id !== null;
    this.isActive = iocomponent.isActive    
    if (this.editMode) {      
      this.pinInUse = iocomponent.pins[0];
    }

    this.valid = {
      form: true,
      conversionRules: true,
    };

    this.validator = new Validator({
      label: { required: true },
      imei: { required: true },
      pin: { required: true },
    });

    // if (this.editMode) {      
    //   iocomponent.pins = this.preparePins(iocomponent.pins, true);      
    // }
    
    this.state = {
      errors: this.validator._errors,
      iocomponent,
    };
  }

  validate = () => {
    const { label, board, pins } = this.state.iocomponent;

    this.validator.validate({
      label,
      imei: board ? board.imei : "",
      pin: pins.length > 0 ? pins[0].id : "",
    });
    this.valid.form = this.validator.isValid();
    const { form, conversionRules } = this.valid;
    this.props.setValidStatus(this.props.index, form && conversionRules);
  };

  updateConversionRules = (convRules, isValid) => {
    const { iocomponent } = this.state;
    iocomponent.conversionRules = convRules;
    this.setState(() => ({ iocomponent }));

    this.valid.conversionRules = isValid;
    const { form, conversionRules } = this.valid;
    this.props.setValidStatus(this.props.index, form && conversionRules);
  };

  componentDidMount = () => {
    this.validate();
    this.updateBoardPins();
  };

  onTextChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.value;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectImeiChange = (e) => {    
    
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    if ( iocomponent.board != null ){ // validation boards select
      let lastboardselected = this.props.boards.find((board) => board.id === e.target.value);
      if(iocomponent.board.id != lastboardselected.id){iocomponent.pins[0] = '';}
    }
    iocomponent.board = this.props.boards.find((board) => board.id === e.target.value);
    iocomponent.board.pins = this.preparePins(iocomponent.board.pins);
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectPinChange = (e) => {
    if (!e.target.value) return;
    const { iocomponent } = this.state;
    const pin = iocomponent.board.pins.find((pin) => (pin.id === e.target.value));
    iocomponent.pins[0] =  pin;
    this.setState(() => ({ iocomponent }));
    this.validate();
  };

  onSelectUnitChange = (e) => {
    const { iocomponent } = this.state;
    const { name, value } = e.target;
    const um = this.props.units.find((um) => um === value);
    const parts = name.split("_");
    iocomponent.conversionRules[parts[0]][parts[1]] = um;
    this.setState(() => ({ iocomponent }));
    validate();
  };

  onReadWriteChange = (e) => {
    const { iocomponent } = this.state;
    const parts = e.target.name.split("_");
    iocomponent.conversionRules[parts[0]][parts[1]] = e.target.value;
    this.setState(() => ({ iocomponent }));
    validate();
  };

  handleCheckboxChange = (e) => {
    const { iocomponent } = this.state;
    iocomponent[e.target.name] = e.target.checked;
    this.setState(() => ({ iocomponent }));
  };

  updateBoardPins() {
    const { iocomponent } = this.state;
    
    if (this.editMode) {
      this.props.fetchBoard(iocomponent.board.id).then((board)=>{        
        iocomponent.board.pins = this.preparePins(board.pins);
        // iocomponent.board.pins.unshift(pinInUse);
        this.setState(() => ({ iocomponent }));
        this.validate();
      })
      // iocomponent.board = this.props.boards.find((board) => board.id === iocomponent.board.id);      
    }
  }
  preparePins = (pins, inUse = false) => {
    const { pinTypes } = this.props;

    let result = pins
      .filter((pin) => {
        return pin.type === 2  // pin tipo analogico
      })
      .map((pin) => ({
        ...pin,
        description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
          }`,
      }));
    if (result.length === 0) {
      result.push({ id: null, description: "No hay pines disponibles" });
    }
    return result;
  };

  matchBoardAndPins = ()=> {        
    const {iocomponent}= this.state;
    const {boards} = this.props;
    const pinInUse = iocomponent.pins[0].id
    let lastboardselected = this.props.boards.find((board) => board.id == iocomponent.board.id);  
    const pinsFree = []
    
    if(lastboardselected == undefined){
      let firstPin = {
                  id: iocomponent.pins[0].id,
                  description: iocomponent.pins[0].description}
      pinsFree.push(firstPin)
    }else{
      let pin = lastboardselected.pins
      pinsFree.push(pin)
    }
    
    return (
      <Grid item xs={12} md={6}>
        <FormTextField
          label="Pin de Entrada"
          id="pin"
          select
          value={
            (iocomponent.pins.length > 0 && iocomponent.pins[iocomponent.pins.length - 1].id) || ""
          }
          disabled={this.isActive}
          onChange={this.onSelectPinChange}
          error={hasErrors(this.state, "pin")}
          required
        >
          {pinsFree.map((pin, index) => {
            return (
              <MenuItem key={index} value={pin.id}>
                {pin.description}
              </MenuItem>
            );
          })}
        </FormTextField>
              {errors(this.state, "pin")}
      </Grid>
    );
  }


  render = () => {
    const { iocomponent } = this.state;
    const { boards, units } = this.props;

    let updatedBoards = [...boards];

    if (iocomponent.board && !boards.some(board => board.id === iocomponent.board.id)) {
      updatedBoards = [...boards, iocomponent.board];
    }
    const pins = iocomponent.board
      ? iocomponent.board.pins
      : [{ id: null, description: "Seleccionar la placa" }];
    

    const pinDescriptions = {
      "17": "IA 1",
      "18": "IA 2",
      
    };

    return (        
      <form id="component-config-form">
        <Grid container spacing={2}>
          
          {/* label */}
          <Grid item xs={12} md={12}>
            <FormTextField
              label="Nombre/Etiqueta"
              id="label"
              name="label"
              value={iocomponent.label ? iocomponent.label : ""}
              onChange={this.onTextChange}
              error={hasErrors(this.state, "label")}
              required
            />
            {errors(this.state, "label")}
          </Grid>
          
          {/* imei */}
          <Grid item xs={12} md={6}>
            <FormTextField
              label="IMEI"
              id="imei"
              select
              value={iocomponent.board ? iocomponent.board.id : ""}
              onChange={this.onSelectImeiChange}
              error={hasErrors(this.state, "imei")}
              required
            >
              {this.loading ? (
                <MenuItem value="">Cargando...</MenuItem>
              ):(
                updatedBoards.map((board) => (
                  <MenuItem 
                  key={board.id} 
                  value={board.id}
                  selected = {this.editMode && board.id === iocomponent.board.id}
                  disabled = {this.editMode && board.id === iocomponent.board.id}
                  >
                    {board.imei}{" "}
                    {Boolean(board.description)
                      ? `- ${board.description}`
                      : "- Sin descripción"}
                  </MenuItem>
                ))
              )}
            </FormTextField>
            {errors(this.state, "imei")}
          </Grid>

          {/* pin */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Pin de Entrada"
                id="pin"
                select
                value={
                  (iocomponent.pins.length > 0 && iocomponent.pins[iocomponent.pins.length - 1].id) || ""
                }
                onChange={this.onSelectPinChange}
                error={hasErrors(this.state, "pin")}
                required
              >

              {pins && pins.length > 0 ? (
                pins.map((pin, index) => (
                  <MenuItem 
                    key={index} 
                    value={pin.id}
                    selected={this.editMode && pin.id === this.pinInUse.id}
                    disabled={pin.component}
                    >
                    {pinDescriptions[pin.address] || pin.description}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No hay pines disponibles</MenuItem>
              )}

              </FormTextField>
              {errors(this.state, "pin")}
            </Grid>

          {/* escalas */}
          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Escala de conversión
            </Typography>
          </Grid>
          <AnalogConversionRules
            rangeNumbers={iocomponent.rangeNumbers}
            conversionRules={iocomponent.conversionRules}
            updateConversionRules={this.updateConversionRules}
            readUnits={units.filter((unit) => ["mA"].indexOf(unit) !== -1)}
            writeUnits={units}
          />

          <Grid item xs={12} md={12}>
            <Typography variant="h6" noWrap className="pull-left">
              Configuración Adicional
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.smsAlert}
                  onChange={this.handleCheckboxChange}
                  name="smsAlert"
                />
              }
              label="Notificar alertas mediante SMS"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showElement}
                  onChange={this.handleCheckboxChange}
                  name="showElement"
                />
              }
              label="Ocultar para usuario Viewer"
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!iocomponent.showHistory}
                  onChange={this.handleCheckboxChange}
                  name="showHistory"
                />
              }
              label="Registrar en historial"
            />
          </Grid>

        </Grid>
      </form>
    );
  };
}

const getAnalogPins = (iocomponent, pins, pinTypes) => {
  let result = pins
    .filter((pin) => {
      return pin.type === 2; // pin tipo analogico      
    })
    .map((pin) => ({
      ...pin,
      description: `Pin ${pin.address} - ${pinTypes.find((type) => type.id === pin.type).name
        }`,
    }));

  return result;
};

const getBoards = (component, boardList, pinTypes) => {
  let boards = boardList
    .map((board) => {
      board.pins = getAnalogPins(component, board.pins, pinTypes);
      return board;
    })
    .filter((board) => board.pins.length > 0);
  if (boards.length === 0) {
    boards.push({ id: null, description: "- No hay placas disponibles -" });
  }
  return boards;
};

const mapStateToProps = (state, props) => {
  const { iocomponent } = props;
  const board = iocomponent.id ? iocomponent.board : undefined;  
  const boards = getBoards(
    iocomponent,
    state.boards.list,
    state.boards.pinTypes
  );
  
  const BoardList = state.boards.list
  const PinType = state.boards.pinTypes
  return {
    iocomponent,
    pinTypes: state.boards.pinTypes,
    boards: boards,
    board,
    BoardList,
    PinType,
    pins: board ? board.pins : [],
    units: [
      "Kg/cm²",
      "Bares",
      "Metros",
      "L/min",
      "M³/hora",
      "M³/min",
      "MCA",
      "Amp",
      "mA",
      "Volt",
      "PSI",
    ],
    loading: state.boards.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listBoards: () => dispatch(listBoards()),
  fetchBoard: (id) => dispatch(fetchBoard(id)),
  boardSucces: () => dispatch(fetchBoardsSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalogComponentEditor);