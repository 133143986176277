import React from 'react';
import { Grid } from "@material-ui/core";
import ComponentButton from "../components/ComponentButton";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SwitchModel from '../../models/SwitchModel';
import SwitchKeyModel from '../../models/SwitchKeyModel';

const PanelMenudigitalOutputs = (props) => {    
    const {handleChange, expanded} = props;

    const addDigitalSwitch = () => {
        const object = new SwitchModel({ panel: props.panel });
        props.addComponent(object.getConfig());
    };

    // componente salida digital tipo boton
    const addDigitalSwitchKey = () => {
        const object = new SwitchKeyModel({ panel: props.panel });
        props.addComponent(object.getConfig());
    };


    return (
        <ExpansionPanel className="expansion-item" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            className="expansion-sumary"
            >
                <Typography className="expansion-header">Salidas Digitales (comandos)</Typography>

            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography>

                </Typography>
                <Grid container spacing={1} style={{ width: "100%", margin: "auto" }}>
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalSwitch}
                            text={"Switch"}
                            image={"/images/switch-default.png"}
                        />
                    </Grid>
                
                
                    <Grid item md={6}>
                        <ComponentButton
                            handleClick={addDigitalSwitchKey}
                            text={"ON/OFF"}
                            image={"/images/button-off.png"}
                        />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>              
    );
};

export default PanelMenudigitalOutputs;
