import { getFormData } from '../../helpers/utils';
import { makeAuthorizedRequest } from '../../services/apiConnector';

export const EDIT_COMPANY_PENDING = 'EDIT_COMPANY_PENDING';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';

export const editCompany = (id, updates) => (dispatch) => {
    dispatch(editCompanyPending());

    const data = getFormData(updates);

    return makeAuthorizedRequest(`/companies/${id}`,{method: 'PATCH', data})
    .then(response => {
        dispatch(editCompanySuccess(id, updates));                    
        return response.data.result.id;
    })
    .catch(error => {
        const message = error.response ? error.response.data.message : 'Error desconocido';
        dispatch(editCompanyError(message));
    })
};

const editCompanyPending = () => ({
    type: EDIT_COMPANY_PENDING
});

const editCompanySuccess = (id, updates) => ({
    type: EDIT_COMPANY_SUCCESS,
    id, 
    updates
});

const editCompanyError = (error) => ({
    type: EDIT_COMPANY_ERROR,
    error
});