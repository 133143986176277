import { makeAuthorizedRequest } from "../../services/apiConnector";

export const LINK_BOARD_PENDING = "LINK_BOARD_PENDING";
export const LINK_BOARD_SUCCESS = "LINK_BOARD_SUCCESS";
export const LINK_BOARD_ERROR = "LINK_BOARD_ERROR";

export const linkBoard = (updates) => (dispatch) => {
  dispatch(linkBoardPending());

  const data = updates; 
  return makeAuthorizedRequest(`/link/linkBoard`, { method: "PATCH", data })
    .then((response) => {
      dispatch(linkBoardSuccess(updates));
      return response.data.result;
    })
    .catch((error) => {
      const message = error.response ? error.response.data.message : "Error desconocido";
      dispatch(linkBoardError(message));
    });
};

const linkBoardPending = () => ({
      type: LINK_BOARD_PENDING,
      action: 'boardlink'
});

const linkBoardSuccess = (updates) => ({
      type: LINK_BOARD_SUCCESS,
      updates
});

const linkBoardError = (error) => ({
      type: LINK_BOARD_ERROR,
      error
  
});