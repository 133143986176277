import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const DialogComponent = ({ isOpen, onClose, title, panel }) => {
  const { boards } = panel;

  const obtenerCredito = (board) => {
    if (board && board.simCredit) {
      const inicioCredito = "Tu credito es de ";
      const finCredito = ".";
      const inicioIndice = board.simCredit.indexOf(inicioCredito);
      const finIndice = board.simCredit.indexOf(finCredito, inicioIndice + 1);

      if (inicioIndice !== -1 && finIndice !== -1) {
        const creditoFragment = board.simCredit.substring(inicioIndice, finIndice + 1);
        const creditoConDolar = creditoFragment.replace('', '$');
        return creditoConDolar;
      }
    }

    return 'No disponible';
  };

  const renderBoardInfo = () => {
    if (boards && boards.length > 0) {
      return boards.map((board, index) => (
        <Typography key={index}>
          <hr />
          Placa: {board.description || ''}<br />
          Última conexión: {board.updatedAt ? new Date(board.updatedAt).toLocaleString() : ''} <br />
          Crédito: {obtenerCredito(board)}
          <hr /> {/* Separador entre cada board */}
        </Typography>
      ));
    }
    return "No hay placas";
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {renderBoardInfo()}
        </DialogContentText>
      </DialogContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px' }}>
        <Button onClick={onClose}>
          Aceptar
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogComponent;
