import { makeAuthorizedRequest } from "../../services/apiConnector";

export const REMOVE_BOARD_PENDING = 'REMOVE_BOARD_PENDING';
export const REMOVE_BOARD_SUCCESS = 'REMOVE_BOARD_SUCCESS';
export const REMOVE_BOARD_ERROR = 'REMOVE_BOARD_ERROR';

export const removeBoard = (id) => (dispatch) => {
    dispatch(removeBoardPending(id));
    return makeAuthorizedRequest(`/boards/${id}`,{method: 'DELETE'})
    .then(response => {
        dispatch(removeBoardSuccess(parseInt(response.data.result.id)));            
        return response.data.result.id;    
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(removeBoardError(error));
    });
};

const removeBoardPending = (id) => ({
    type: REMOVE_BOARD_PENDING,
    id
});

const removeBoardSuccess = (id) => ({
    type: REMOVE_BOARD_SUCCESS,
    id
});

const removeBoardError = (error) => ({
    type: REMOVE_BOARD_ERROR,
    error
});