import { getFormData } from '../../helpers/utils';
import { makeRequest } from '../../services/apiConnector';

export const FORGOT_PENDING = 'FORGOT_PENDING';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

export const forgotPassword = (email) => (dispatch) => {
    dispatch(forgotPasswordPending());
    
    const data = getFormData({email});

    return makeRequest('/password/forgot', { method: 'POST', data })
    .then(response => {
        const reset = response.data.result;
        dispatch(forgotPasswordSuccess());            
        return reset;
    })
    .catch(error => {
        const message = error.response.data.message ? error.response.data.message : 'Error desconocido'
        dispatch(forgotPasswordError(message));
    });
};

const forgotPasswordPending = () => ({
    type: FORGOT_PENDING,
    action: 'forgot',
});

const forgotPasswordSuccess = () => ({
    type: FORGOT_SUCCESS,
    message: 'Su solicitud se procesó con éxito. Por favor revise su correo electrónico y siga las instrucciones.',
});

const forgotPasswordError = (error) => ({
    type: FORGOT_ERROR,
    error
});