import { makeAuthorizedRequest } from "../../services/apiConnector";

export const LIST_ROLES_PENDING = 'LIST_ROLES_PENDING';
export const LIST_ROLES_SUCCESS = 'LIST_ROLES_SUCCESS';
export const LIST_ROLES_ERROR = 'LIST_ROLES_ERROR';

export const listRoles = (isAdmin) => (dispatch) => {
    dispatch(listRolesPending());
    return makeAuthorizedRequest('/roles?select=id,displayName')
    .then(response => {
        dispatch(listRolesSuccess(response.data));
        return response.data.result;
    })
    .catch(error => {
        const message = typeof error.response !== 'undefined' ? error.response.data.message : 'Error desconocido';
        dispatch(listRolesError(message));
    });    
};

const listRolesPending = () => ({
    type: LIST_ROLES_PENDING
});

const listRolesSuccess = (data) => ({
    type: LIST_ROLES_SUCCESS,
    data: data.result
});

const listRolesError = (error) => ({
    type: LIST_ROLES_ERROR,
    error
});
