import * as React from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { connect } from "react-redux";
import Validator from "../../services/validator";

import { CircularProgress } from "@material-ui/core";

import FlashMessage from "../FlashMessage";

import { listCompanies } from "../../actions/companies/fetchActions";
import { listRoles } from "../../actions/roles/fetchActions";

import { ACTION_EDIT, ACTION_REMOVE, ACTION_ADD } from "../../actions/types";
import { ERROR_STATUS, PENDING_STATUS, SUCCES_STATUS } from "../../actions/status";
import { hasRoles } from "../../helpers/auth";
import { hasErrors, errors, clean } from "../../helpers/utils";
import FormTextField from "../FormTextField";


class UserForm extends React.Component {
  user = null;
  editMode = false;

  constructor(props) {
    super(props);

    const { roles } = this.props;

    this.validator = new Validator({
      name: { required: true },
      email: { required: true, email: true },
      role: { required: true },
    });

    this.user = this.props.user || {
      name: "",
      email: "",
      password: "",
      password_confirm: "",
      role: undefined,
      company: undefined,
    };

    this.editMode = !!this.user.id;

    if (!this.editMode) {
      this.validator.addRule("password", { required: true, minLength: 8 });
    }
    this.validator.addRule("password_confirm", { sameAs: "password" });

    if (hasRoles(["admin"])) {
      this.validator.addRule("company", { required: true });
      this.editMode = true; 
    }

    this.state = {
      companies: [],
      user: this.user,
      errors: this.validator._errors,
    };
  }

  componentDidMount = () => {
    const { listCompanies, listRoles } = this.props;

    listRoles(hasRoles(["admin"]));

    if (hasRoles(["admin"])) {
      listCompanies();
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if(hasRoles(["admin"])){
      alert("error");
      return;
    }
    const {
      name,
      email,
      role: userRole,
      company: userCompany,
      password,
      password_confirm,
    } = this.state.user;

    const role = userRole ? userRole.id : null;
    let company = userCompany ? userCompany.id : null;

    let form = { name, email, role, password, password_confirm, company };
   //como el rol es de admin borra la compania.
    if (role && role == 1) {
      delete form["company"];
      company = null;
      this.validator.removeRule("company");
    }
    
    if (!this.validator.validate(form)) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }

    let submit = { name, email, password, role, company };
    submit = clean(submit);
    
    this.props.onSubmit(submit);
  };

  onTextChange = (e) => {
    this.user[e.target.id] = e.target.value;
    this.setState(() => ({ user: this.user }));
  };
  //selec roll
  onSelectChange = (e) => {
    const { roles } = this.props;
    const selected = roles.find((role) => role.id === e.target.value);
    this.user.role = selected;
    this.setState(() => ({ user: this.user }));
  };
  //selec company when roll==1(admin)
  onCompaniesSelectChange = (e) => {
    const { companies } = this.props;
    const selected = companies.find((company) => company.id === e.target.value);
    this.user.company = selected;
    this.setState(() => ({ user: this.user }));
  };

  render() {
    const { error, status, message, action, companies, roles } = this.props;
    return (
      <div>
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status == "error" && error &&(
          <FlashMessage message={error} type={status}/>
        )}
        {(action === ACTION_EDIT || action === ACTION_ADD) &&
          status == "success" && message &&(
            <FlashMessage message={message} type={status} />
        )}
        <form id="user-form" onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Email"
                id="email"
                disabled={this.editMode}
                value={this.user.email}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "email")}
              />
              {errors(this.state, "email")}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Nombre"
                id="name"
                disabled={this.editMode}
                value={this.user.name}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "name")}
              />
              {errors(this.state, "name")}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Clave"
                disabled={this.editMode}
                type="password"
                id="password"
                value={this.user.password}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "password")}
              />
              {errors(this.state, "password")}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
              disabled={this.editMode}
                label="Confirmación de clave"
                type="password"
                id="password_confirm"
                value={this.user.password_confirm}
                onChange={this.onTextChange}
                error={hasErrors(this.state, "password_confirm")}
              />
              {errors(this.state, "password_confirm")}
            </Grid>
            {roles.length > 0 && (
              <Grid item xs={12} md={6}>
                <FormTextField
                disabled={this.editMode}
                  label="Rol"
                  id="role"
                  select
                  value={this.user.role ? this.user.role.id : ""}
                  onChange={this.onSelectChange}
                  error={hasErrors(this.state, "role")}
                >
                  {this.props.roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.displayName}
                    </MenuItem>
                  ))}
                </FormTextField>
                {errors(this.state, "role")}
              </Grid>
            )}
            {hasRoles(["admin"]) && companies.length > 0 && (
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="Empresa"
                  disabled={this.editMode}
                  id="company"
                  select
                  value={this.user.company ? this.user.company.id : ""}
                  onChange={this.onCompaniesSelectChange}
                  error={hasErrors(this.state, "company")}
                >
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </FormTextField>
                {errors(this.state, "company")}
              </Grid>
            )}
          </Grid>
          <div style={{ marginTop: "3.1rem" }}>
            {status === PENDING_STATUS &&
              (action === ACTION_EDIT || action === ACTION_ADD) ? (
              <Button color="secondary" variant="contained">
                <CircularProgress
                  style={{ width: "15px", height: "15px", marginRight: "5px" }}
                />
                Guardando...
              </Button>
            ) : (
              <Button 
              color="secondary" 
              type="submit" 
              variant="contained"
              >
                Guardar
              </Button>
            )}
            <Button
              style={{ marginLeft: "0.8rem" }}
              variant="contained"
              onClick={this.props.onCancel}
            >
              Volver
            </Button>
            {this.props.onDelete &&
              (status === PENDING_STATUS && action === ACTION_REMOVE ? (
                <Button style={{ float: "right" }} variant="contained">
                  <CircularProgress
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                  Borrando...
                </Button>
              ) : (
                <Button
                  style={{ float: "right" }}
                  variant="contained"
                  onClick={() => this.props.onDelete(this.state.user.id)}
                >
                  Borrar
                </Button>
              ))}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  
  return {
    action: state.users.action,
    status: state.users.status,
    message: state.users.message,
    companies: state.companies.list,
    roles: state.roles.list,
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listCompanies: () => dispatch(listCompanies()),
  listRoles: (isAdmin) => dispatch(listRoles(isAdmin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
