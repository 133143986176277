import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
//import LoadingButton from "@material-ui/lab/LoadingButton";

import Grid from "@material-ui/core/Grid";
import FormTextField from "../FormTextField";
import { CircularProgress } from "@material-ui/core";
import Validator from "../../services/validator";
import FlashMessage from "../FlashMessage";
import { ACTION_EDIT } from "../../actions/types";
import { PENDING_STATUS, ERROR_STATUS, SUCCESS_STATUS } from "../../actions/status";
import { hasErrors, errors } from "../../helpers/utils";

class BoardUserForm extends React.Component {
  board = null;
  
  constructor(props) {
    super (props);

    this.validator = new Validator({
      imei: { required: true },
    });

    this.board = this.props.board || {
      imei: "",
      companies: "",
    };

    this.state = {
      board: this.board,
      errors: this.validator._errors,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { imei, companies} = this.state.board;
    let form = { imei,companies};

    if (!this.validator.validate(form)) {
      const errors = this.validator._errors;
      this.setState(() => ({ errors }));
      return;
    }
    this.props.onSubmit({ form });
  };

  onEmaiChange = (e) => {
    this.board[e.target.id] = e.target.value;
    this.setState(() => ({ board: this.board }));
  };

  render() {
    const { inProgress, status, error, action, message } = this.props;
    return (
      
      <div>
        <form id="board-link" onSubmit={this.onSubmit}>
        { !inProgress && action === "boardlink" && error && (
          <FlashMessage message={error} type={ERROR_STATUS}/>
        )} 
          { !inProgress && action === "boardlink" && message && (
          <FlashMessage message={message} type={SUCCESS_STATUS}/>
        )}    
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="IMEI"
                id="imei"
                value={this.board.imei}
                onChange={this.onEmaiChange}
                error={hasErrors(this.state, "imei")}
                />
                 {errors(this.state, "imei")}
            </Grid>
            </Grid>
            
            <div style={{ marginTop: '3.1rem' }}>
              {status === SUCCESS_STATUS && action === "boardlink" ? (
                    <Button 
                      color="secondary" 
                      variant="contained"
                      onClick={this.props.onCancel}
                      >           
                        <CircularProgress style={{width:'15px', height: '15px', marginRight: '5px'}}/>
                              Guardando...
                    </Button>) : (
                    <Button 
                      color= "primary" 
                      type="submit" 
                      variant="contained"
                      startIcon={<SaveIcon/>}
                      > 
                            Guardar
                    </Button>
              )}
                    <Button 
                      style={{ marginLeft: '0.8rem' }}
                      variant="contained" 
                      onClick={this.props.onCancel}>
                            Volver
                    </Button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { inProgress, error, action, message } = state.boards;
  return {
      inProgress,
      error,
      action,
      message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  linkboard: (updates) => dispatch(linkboard(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardUserForm);
