import DigitalComponentModel from "./DigitalComponentModel";
import shapes from "../helpers/shapes";
import { getAlertIcon } from "./AlertIconModel";

class SwitchModel extends DigitalComponentModel {
  constructor(props) {
    super(props);
    this.type = "switch";
    this.label = props.label ? props.label : "Switch";
    this.selectable = true;
  }
  getSwitchValue = () => {
    return new DigitalComponentModel({
      type: "switchValue",
      value: this.value,
      xPosition: 0,
      yPosition: 119,
    });
  };

  getAlert = (xPosition, yPosition) => {
    if (!this.alerts || this.alerts.length == 0) {
      this.alerts.push({
        severity: "default",
      });
    }
    return getAlertIcon(this.alerts[0], xPosition, yPosition);
  };
  

  getConfig = (parent = null) => {  
    const Switch = this.getSwitchValue();
    const label = this.getLabel(this.label, 0, 150);
        
    const alert = this.getAlert(120,140)
    
    this.children = [label, alert];

    const shape = { ...shapes[this.type] };
    shape.styles = this.getImage();

    return {
      parent,
      object: this,
      shape,
      children: this.children,
    };
  };
}

export default SwitchModel;
