import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { hasRoles } from "../../helpers/auth";
import VisibilityIcon from "@material-ui/icons/Visibility";
import onViewCredit from "./BoardCredit";
import Tooltip from '@material-ui/core/Tooltip';


const BoardListTable = (props) => {
  const { boards } = props;

  const obtenerCredito = (board) => {
    if (board && board.simCredit) {
      const inicioCredito = "Tu credito es de ";
      const finCredito = ".";
      const inicioIndice = board.simCredit.indexOf(inicioCredito);
      const finIndice = board.simCredit.indexOf(finCredito, inicioIndice + 1);

      if (inicioIndice !== -1 && finIndice !== -1) {
        const creditoFragment = board.simCredit.substring(inicioIndice, finIndice + 1);
        const creditoConDolar = creditoFragment.replace('', '$');
        return creditoConDolar;
      }
    }

    return 'No disponible';
  };

  return (
    <TableContainer className="boards" component={Paper}>
      <Table className="table" aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>IMEI</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell align="right">Teléfono</TableCell>
            <TableCell align="right">Crédito</TableCell>
            <TableCell align="right">Pines (libres/total)</TableCell>
            {hasRoles(["admin"]) && (
              <TableCell align="right">Empresa</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {boards.map((board) => (
            
            <TableRow key={board.id}>
              <TableCell component="th" scope="row">
                {board.imei}
              </TableCell>
              <TableCell component="th" scope="row">
                {board.model}
              </TableCell>
              <TableCell component="th" scope="row">
                {board.description || "N/D"}
              </TableCell>
              <TableCell align="right">{board.phone || "N/D"}</TableCell>
                
                <TableCell align="right">
                <Tooltip title={obtenerCredito(board)} placement="top">
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                </TableCell>
              <TableCell align="right">
                {/* {`${
                  board.pins.filter((pin) => {
                    return pin.component === null;
                  }).length
                }/${board.pins.length}`} */}
              </TableCell>
              {hasRoles(["admin"]) && (
                <TableCell align="right">
                  {board.company ? board.company.name : "N/D"}
                </TableCell>
              )}
              <TableCell align="right">
                <IconButton
                  onClick={() => props.onEditClicked(board.id)}
                  aria-label="Editar"
                  component="span"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BoardListTable;
