import shapes from "../helpers/shapes";

const defaultAlert = {
  condition: "",
  value: "",
  message: "",
  isActive: false,
  errors: [],
};

const texts = ["label", "pump", "selectortowposition","selectorthreeposition", "generic", "switch","textLabel", "switchKey"];

class DigitalComponentModel {
  constructor(props) {
    const {
      id = null,
      label = "",
      type = null,
      xPosition = 100,
      yPosition = 100,
      panel = null,
      pins = [],
      smsAlert = false,
      rawAlertValue = 0,
      showElement = false,
      showHistory = false,
      board = null,
      value = 0,
      isActive = false,
      pinRelate = [],
      conversionRules = {
        rules: [{ read: null ,write: null},{ read: null ,write: null }],
        type: null
      },

      alertRules = [
        { ...defaultAlert, priority: 1, severity: "failure", label: "Fallo" }
      ],
      cell = null,
      alerts = [],
      selectable = false,
      company = null,
      loader = 0,
      isLoading = false
    } = props;

    this.id = id;
    this.label = label;
    this.type = type;
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.panel = panel;
    this.pins = pins;
    this.smsAlert = smsAlert;
    this.rawAlertValue = rawAlertValue;
    this.showElement = showElement;
    this.showHistory = showHistory;
    this.board = board;
    this.value = value;
    this.isActive = isActive;
    this.pinRelate= pinRelate;
    this.conversionRules = conversionRules;
    this.alertRules = alertRules;
    this.cell = cell;
    this.alerts = alerts;
    this.selectable = selectable;
    this.company= company;
    this.loader = loader;
    this.isLoading = isLoading;
  }

  toString = () => {
    let value = this.value != null ? this.value : "";

    if (!isNaN(value) && value !== "") {
      value = parseInt(value).toFixed(2);
    }

    const type =
      this.conversionRules && this.conversionRules.type
        ? this.conversionRules.type
        : "";
    return `${value} ${type}`;
  };

  static capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getConfig = (parent = null) => {
    switch (this.type) {
      case "pump":
      case "selectortowposition":
      case "selectorthreeposition":
      case "generic":
      case "value":
      case "label":
      case "alertIcon":
      case "switch":
      case "switchKey":
        return this.getChildConfig(parent);
      default:
        return null;
    }
  };

  getLabel = (value, xPosition, yPosition) => {
    return new DigitalComponentModel({
      type: "label",
      value,
      xPosition,
      yPosition,
    });
  };

  getChildConfig = (parent) => {
    const shape = shapes[this.type];
    const width = parent.geometry.width;
    return {
      parent,
      object: this,
      shape: { ...shape, width },
      children: [],
    };
  };

  setValue = (value, model) => {
      value = parseFloat(value).toFixed(2);
      this.value = value;
      model.setValue(this.cell, this);
      this.children &&
        this.children.forEach((child) => {
          if (texts.indexOf(child.type) === -1) {
            child.value = value;
            model.setValue(child.cell, child);
          }
        });
      this.updateImage(value);
  };

  
  updateImage(value) {
    let style = shapes[this.type].styles; 
    if(this.type =='switch' || this.type == "switchKey"){
      
      if(this.isLoading == true ){
        
        style = shapes.loader.styles;
        this.cell.setStyle(style);
        
      }else{
        
        let style = shapes[this.type].styles; 
        value = parseInt(value)
        style = style.replace("default", value);
        this.cell.setStyle(style);
      }

    }
    if(this.type == 'selectorthreeposition' && this.pins.length > 1){
        if (value.valone == 0 && value.valtow == 0){
          style = style.replace("default", `${value.valone}`);
          this.cell.setStyle(style);
        }else if(value.valone == 1 && value.valtow == 0){
          style = style.replace("default", `left-${value.valone}`);
          this.cell.setStyle(style);
        }else if(value.valone == 0 && value.valtow == 1){
          style = style.replace("default", `right-${value.valtow}`);
          this.cell.setStyle(style);
        }   
    }else{
      value = parseInt(value)
      style = style.replace("default", value);
      this.cell.setStyle(style);
    }
  }
  quitLoader(value){
    console.log("value", value);
    this.loader = 0
    let style = shapes[this.type].styles; 
    value = parseInt(value)
    style = style.replace("default", value);
    this.cell.setStyle(style);
  }

  getImage = () => {  
    let style = shapes[this.type].styles; 
    if(this.pins.length > 1 && this.type == 'selectorthreeposition'){
        if (this.pins[0].value == 0 && this.pins[1].value == 0){
          return style = style.replace("default", `${this.value}`);
        }else if(this.pins[0].value == 1 && this.pins[1].value == 0){
          return style.replace("default",`left-${this.pins[0].value}`);
        }else if(this.pins[0].value == 0 && this.pins[1].value == 1){
          return style.replace("default",`right-${this.pins[1].value}`);
        }     
    }else{
      return style.replace("default", parseInt(this.value));
    }
  };

  // getIconEdit=()=>{
  //   if(this.type == "switch"){
  //     let style = shapes[this.type].styles; 
  //     console.log(style)
  //   }
  // }

  setAlerts = (alerts) => {
    this.alerts = alerts.length > 0 ? alerts : [{ severity: "default" }];
    const icon = this.children.find((child) => child.type === "alertIcon");
    if (!icon) return;

    const severity = this.alerts[0].severity;
    let style = shapes[icon.type].styles;
    style = style.replace("default", severity);
    icon.cell.setStyle(style);
  };
}

export default DigitalComponentModel;
