import React from "react";
import Typography from "@material-ui/core/Typography";
import PanelViewerConsole from "./PanelViewerConsole";
import PanelHistoryData from "./PanelHistoryData";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { fetchComponents } from "../../actions/components/fetchActions";

const PanelHistory = (props) => {
  const { history, panel } = props;

  const onBackClicked = () => {
    history.push("/panels");
  };

  if (!panel) {
    props.history.push("/panels");
    return <div></div>;
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="page-header">
        <Typography variant="h6" noWrap className="pull-left">
          {panel.name}
        </Typography>
        <div className="pull-right">
          <Button
            id="back"
            onClick={onBackClicked}
            color="default"
            variant="contained"
          >
            Cerrar
          </Button>
        </div>
        <div className="clearfix"></div>
      </div>
      {/* <PanelViewerConsole panel={props.panel} /> */}
      <PanelHistoryData panel={panel} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const panel = state.panels.data.find(
    (panel) => panel.id === parseInt(props.match.params.id)
  );
  return {
    panel,
  };
};

export default connect(mapStateToProps, null)(PanelHistory);
